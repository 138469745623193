import axios from 'axios'
export default {
  getConfig () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/config/kpi`
    )
  },
  updateConfig (description) {
    return axios.put(
      `${window.VUE_APP_BACKEND_HOST}/config/kpi?description=${description}`
    )
  },
  deleteConfig (description) {
    return axios.delete(
      `${window.VUE_APP_BACKEND_HOST}/config/kpi?description=${description}`
    )
  }
}
