<template>
  <div
    :class="['switch', disable ? 'disabled' : '']"
    @click.prevent="toggleEvent()"
  >
    <label
      class="common-review"
      :style="textStyle"
    >{{ labelText }}</label>
    <input
      v-model="isChecked"
      type="checkbox"
      :style="color"
    >
    <span
      class="slider round"
      :style="color"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isChecked: {
      type: Boolean,
      default: true
    },
    uncheckColor: {
      type: String,
      default: ''
    },
    checkColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    /*
    checkToggle () {
      return this.isChecked
    },
    */
    color () {
      const toggleColor = '--toggle-color'

      return this.checkToggle
        ? `${toggleColor}: ${this.checkColor};`
        : `${toggleColor}: ${this.uncheckColor};`
    },
    textStyle () {
      const textColor = '--text-color'
      return `${textColor} : ${this.textColor};`
    },
    labelText () {
      // return `$t('${this.title}')`
      return this.title
    }
  },
  methods: {
    toggleEvent () {
      // console.log()
      if (!this.disable) {
        this.$emit('toggleEvent', this.isChecked)
      }
    }
  }
}
</script>

<style lang="css" scoped>

.common-review {
  color: var(--primary-dark-color);
  font-size: 16px;
  text-align: right;
  padding-right: 10px;
  cursor: pointer;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: fit-content;
  padding-right: 33px;
  height: 25px;
  text-align: right;
}

.switch.disabled {
  cursor: not-allowed !important;
  opacity: 1 !important;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 4px;
  background-color: silver;
  -webkit-transition: .3s;
  transition: .3s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -1px;
  bottom: -3px;
  border-width: 1px;
  border-style: solid;
  border-color: grey;
  background-color: white;
  -webkit-transition: .3s;
  transition: .3s;
}
span {
  width: 29px;
  height: 14px;
}
input:checked + .slider {
  background-color: var(--primary-dark-color) !important;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  border-color: var(--toggle-color);
}
/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}
.slider.round:before {
  border-radius: 50%;
}

</style>
