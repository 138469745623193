<template>
    <div class="input__container" v-click-outside="clickOutside">
        <div class="input__container" @click="openOptions()">
          <input type="text" v-model="searchInput" class="input">
          <span class="select__button__placeholder" v-if="!itemSelected">{{getPlaceholder}}</span>
          <span class="input__select__icon icon icon-arrow-down" :class="true"></span>
        </div>
        <div class="select__options scroll scroll2" :class="{'select__options--visible' : optionsVisible}">
            <div class="select__option" :class="{'select__option--selected' : option.selected}"  v-for="(option, index) in getDataFiltered" :key="index" @click="selectOption(option)">
                <span class="select__option__label">{{option.name || option[itemLabel]}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'select',
  props: ['data', 'itemSelected', 'itemLabel', 'right', 'placeholder'],
  data () {
    return {
      optionsVisible: false,
      searchInput: ''
    }
  },
  computed: {
    getPlaceholder () {
      return this.placeholder || this.$t('global.select')
    },
    getDataFiltered () {
      return this.data.filter(element => {
        return element.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1
      })
    }
  },
  methods: {
    openOptions: function () {
      this.searchInput = ''
      this.optionsVisible = true
    },
    selectOption: function (option) {
      this.searchInput = option.name
      // console.log()
      this.$emit('onSelect', option)
      this.clickOutside()
    },
    clickOutside: function () {
      this.optionsVisible = false
    },
    cancelSearch: function () {
      this.searchInput = ''
    }
  },
  watch: {
    itemSelected: {
      handler (newVal, oldVal) {
        if (!oldVal.name && newVal.name) {
          this.searchInput = newVal.name
          // console.log()
        }
      },
      deep: true
    }
  }
}
</script>
