<template>
  <div class="stepper">
    <ul
      :class="['progressbar', positionClass, showNumericPosition]"
      :dataSteps="orderedSteps.length"
    >
      <li
        v-for="step of orderedSteps"
        :key="'stepper_' + step.id"
        :class="{'active': step.id === activeStepId,
                 'disabled-tab': step.disabledTab === true, }"
        class="report-form__subtitle"
        @click="goToTab(step.id)"
      >
        <span>{{ step.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import clone from 'lodash/clone'
export default {
  name: 'ViewTabs',
  props: {
    steps: {
      type: Array,
      required: true
      // validator (value) {
      //   return value.every((item) => {
      //     return item.hasOwnProperty('name') && item.hasOwnProperty('id')
      //     // podemos pasar la key 'disabled' para bloquear las pestañas.
      //     // cuyo valúe puede ser dinámico si en vez de data, hacemos este objeto en computed.
      //   })
      // }
    },
    activeStepId: {
      type: Number,
      default: 0
    },
    textPosition: {
      type: String,
      default: 'bottom',
      validator (value) {
        return ['bottom', 'top'].includes(value)
      }
    },
    showNumber: {
      type: Boolean,
      default: false
    },
    isViewStepper: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    activeStepName () {
      const step = this.steps.find((step) => step.id === this.activeStepId)
      return (step && step.name) || this.$t('unknown')
    },
    orderedSteps () {
      const clonedSteps = clone(this.steps)
      return clonedSteps.sort((stepA, stepB) => (stepA.id > stepB.id && 1) || (stepA.id < stepB.id && -1) || 0)
    },
    positionClass () {
      return this.textPosition === 'top' ? 'progressbarTopText' : 'progressbarBottomText'
    },
    showNumericPosition () {
      return this.showNumber ? 'progressbarNumerico' : 'progressbarEmpty'
    }
  },
  methods: {
    goToTab (a) {
      this.$emit('go-to-tab-id', a)
    }
  }
}
</script>

<style lang="scss" scoped>
.stepper {
  width: 100%;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 4px 0 15px 0;
  & ul.progressbar {
    // width: max-content;
    // border-bottom: 1px solid red;
    display: flex;
    justify-content: center;
    // align-items: self-end;
    padding-left: 0px;
    & li { // pestaña
        width: auto;
        padding: 0 20px 4px 0px;
        text-align: left;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 600;
        white-space: nowrap;
      &.active { // pestaña activa
        padding: 0 20px 4px 0px;
        // border-bottom: 2px solid #FF9900;
        text-align: left;
        color: #FF9900;
        cursor: default;
      }
      &.disabled-tab { // pestaña activa
        cursor: not-allowed;
        pointer-events: none;
        color: lightsteelblue;
        // border-bottom: 0;
      }
    }
  }
}

  /* Progressbar */
  .progressbar {
    width:100%;
    // height: 30px;
    // border-bottom: 1px solid red;
    display: flex;
    // align-items: self-end;
    @media (max-width: 700px) {
      // flex-direction: column;
      align-items: center;
      border: 0;
    }
  }
  /*  Con texto abajo */
  .progressbar li{
    height: 100%;
    padding: 0 12px 10px 12px;
    list-style-type: none;
    float: left;position:relative;
    text-align: center;
    color: silver;
    // border-bottom: 1px solid silver;
    font-size: calc(15px + 0.2vw);
    line-height: 1;
    cursor: pointer;
    &:hover{
      color:#FF9900;
      // border-bottom: 1px solid #FF9900;
    }
  }
    /* Pelota */
    // .progressbar li:before { z-index:1; content:''; height:20px; width:20px; line-height: 20px; border: 2px solid $gray-lighter; display:block; text-align: center; margin: 0 auto 10px auto; border-radius: 50%; background-color:$gray-lighter;  }
    /* Lineas */
    // .progressbar li:after { content:''; position: absolute; width:100%; height:2px; background-color: $gray-lighter; top: 11px; left: -50%; z-index: -1;}
    .progressbar li:first-child:after { content:none;}

  /* Estado Activo */
  .progressbar li.active  {
    color: #212241;
    // border-bottom: 0.2vw solid #212241;
    cursor: default;
    padding: 0 12px 7px 12px;
    @media (max-width: 700px) {
      border: 0;
      padding: 0 12px 10px 12px;
    }
  }
    // .progressbar li.active:before { border-color:#212241; background-color:#212241;}
    // /*.progressbar li.active + li:after*/ { background-color:#212241; }

  /* Numero Steps */
  .progressbar[dataSteps='2'] li {width: 50%;}
  .progressbar[dataSteps='3'] li {width: 33.33%;}
  .progressbar[dataSteps='4'] li {width: 25%;}
  .progressbar[dataSteps='5'] li {width: 20%;}
  .progressbar[dataSteps='6'] li {width: 16.6%;}

  .progressbar[dataSteps='2'] li, .progressbar[dataSteps='3'] li, .progressbar[dataSteps='4'] li, .progressbar[dataSteps='5'] li,  .progressbar[dataSteps='6'] li {
    @media (max-width: 700px) {
        width: 100%;
      }
  }

  /**** VERSIONES ****/
  /* Con número*/
  .progressbar.progressbarNumerico { counter-reset: step; }
  // .progressbar.progressbarNumerico li:before { content:counter(step); counter-increment: step; color:#FFF; }
  /*  Con texto arriba */
  // .progressbar.progressbarTopText li{ padding-bottom: 10px}
  // .progressbar.progressbarTopText li:before { position: absolute; left:calc(50% - 11px); top:20px; }
  // .progressbar.progressbarTopText li:after { top: 29px; }

</style>
