<template>
    <div class="modal-spinner__container">
        <div class="modal-spinner">
            <Spinner class="modal-spinner__icon" v-if="true"/>
            <span class="modal-spinner__title">{{data.title}}</span>
            <span class="modal-spinner__text">{{data.text}}</span>
        </div>
    </div>
</template>
<script>
import Spinner from '@/components/spinners/Spinner.vue'
export default {
  name: 'ModalSpinner',
  components: { Spinner },
  props: ['data'],
  data () {
    return {}
  }
}
</script>
