<template>
  <div class="form-group form-group--inline margin-top-20">
    <label
      v-if="!noLabel"
      :for="formElement"
      class="form-group__label"
    >{{ $tc( textLabel ) }}
      <span
        v-if="requiredInput"
        :title="requiredTitle"
        class="required-dot"
      >●</span>
    </label>
    <div class="form-group__data">
      <input
        v-if="inputType ==='text' || inputType ==='email'"
        :value="value"
        :name="formElement"
        :type="inputType"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="requiredInput"
        :maxlength="maxlength"
        class="input"
        :class="{
          'input--read-only': disabled
        }"
        @input="inputHandler"
      >
      <input
        v-if="inputType ==='date'"
        :value="value"
        :name="formElement"
        :type="inputType"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="requiredInput"
        class="input"
        :class="{
          'input--read-only': disabled
        }"
        @input="inputHandler"
      >
      <textarea
        v-if="inputType ==='textarea'"
        :value="value"
        :name="formElement"
        :type="inputType"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="requiredInput"
        :maxlength="maxlength"
        class="input"
        rows="4"
        :class="{
          'input--read-only': disabled
        }"
        @input="inputHandler"
      />
    </div>
  </div>
</template>

<script>
/* EJEMPLO:::::: FormOktCardInfo */

/*  En la inseción sí que se mete v-model="cardHolder"
    v-model es una computada con get/set
    set tiene un método updateValue(newValue) que actualiza
    el data o la store.
*/
/*  si hay que enviar datos al padre
    updateCardValue (newValues) {
      this.$emit('cardUpdated', {
        description: this.description, // actualiza v-model
        ...newValues
      })
    }
*/
/*  Método final de actualización del dato
    cardUpdated (newCardValue) {
      this.card = { ...this.card, ...newCardValue }
    }
*/
// import moment from 'moment'

export default {

  name: 'InputLabel',
  props: {
    value: {
      // eslint-disable-next-line
      type: String | Number,
      required: false,
      default: null
    },
    formElement: {
      type: String,
      required: false,
      default: ''
    },
    inputType: {
      type: String,
      required: false,
      default: 'text'
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    requiredInput: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    textLabel: {
      type: String,
      required: false,
      default: null
    },
    maxlength: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    colorClasses () {
      return this.disabled
        ? this.disabledColors[this.btnType]
        : this.colors[this.btnType]
    },
    iconPosition () {
      return this.iconRight ? 'button__icon-right' : 'button__icon-left'
    },
    requiredTitle () {
      return this.$t('form.validation.required')
    }
  },
  // mounted () {
  //   console.log('M InputLabel')
  // },
  methods: {
    inputHandler (event) {
      // console.log(event)
      // console.log('INPUT 1', event.target.value)
      // console.log('INPUT 2', event.target.valueAsDate)
      // console.log('INPUT 3', moment(event.target.valueAsDate).format('DD-MM-YYYY'))
      if (this.inputType === 'date') {
        if (event && event.target && event.target.valueAsDate) {
          // NO ES CORRECTO PASARLE UN VALUE CON FORMATO PORQUE LOS INPUT TYPE DATE FALLARÁN
          // this.$emit('input', moment(event.target.valueAsDate).format('L'))
          this.$emit('input', event.target.value)
        } else {
          this.$emit('input', null)
        }
      } else if (event && event.target && event.target.value) {
        this.$emit('input', event.target.valueAsNumber || event.target.valueAsDate || event.target.value)
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.form-group {
  & .form-group__label {
    width: 200px;
  }
  & .form-group__data {
    & textarea {
      padding: 6px 10px;
      min-height: 50px;
    }
    & .input.input--read-only {
      background-color: #F2F2F2;
    }
  }
}

.required-dot {
    color: #00425c;
    line-height: 17px;
    margin: 0 5px;
    cursor: help;
    }

@media (max-width: 400px) {
  .form-group--inline {
    flex-direction: column;
  }
}
</style>
