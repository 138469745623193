<template>
    <div class="">
        <div class="strong-password">
              <div class="strong-password__graph__container margin-top-15" v-if="password && password.length > 0">
                  <div class="strong-password__graph">
                      <div class="strong-password__graph__result" :class="{'strong-password__graph__result--very-weak': value === STRENGTH_TYPE.VERY_WEAK,
                              'strong-password__graph__result--weak': value === STRENGTH_TYPE.WEAK,
                              'strong-password__graph__result--regular': value === STRENGTH_TYPE.REGULAR,
                              'strong-password__graph__result--almost-valid': value === STRENGTH_TYPE.ALMOST_VALID,
                              'strong-password__graph__result--valid': value === STRENGTH_TYPE.VALID}">
                      </div>
                  </div>
                  <span v-if="value === STRENGTH_TYPE.VERY_WEAK" class="strong-password__graph__label">{{ $t('updatePassword.validation.veryWeak') }}</span>
                  <span v-if="value === STRENGTH_TYPE.WEAK || value === STRENGTH_TYPE.REGULAR || value === STRENGTH_TYPE.ALMOST_VALID"
                      class="strong-password__graph__label">{{ $t('updatePassword.validation.weak') }}</span>
                  <span v-if="value === STRENGTH_TYPE.VALID" class="strong-password__graph__label">{{ $t('updatePassword.validation.strong') }}</span>
              </div>
              <p class="strong-password__subtitle" v-else>{{ $t('updatePassword.strengthRequirements') }}</p>
              <div class="flex direction-column" :class="{ 'margin-top-5': password && password.length > 0 }">
                  <div class="strong-password__rule" :class="{'strong-password__rule--ok': properties.uppercase}">
                      <span class="strong-password__rule__circle"></span>
                      <span class="strong-password__rule__label">{{ $t('updatePassword.validation.uppercase') }}</span>
                  </div>
                  <div class="strong-password__rule" :class="{'strong-password__rule--ok': properties.lowercase}">
                      <span class="strong-password__rule__circle"></span>
                      <span class="strong-password__rule__label">{{ $t('updatePassword.validation.lowercase') }}</span>
                  </div>
                  <div class="strong-password__rule" :class="{'strong-password__rule--ok': properties.numbers}">
                      <span class="strong-password__rule__circle"></span>
                      <span class="strong-password__rule__label">{{ $t('updatePassword.validation.numbers') }}</span>
                  </div>
                  <div class="strong-password__rule" :class="{'strong-password__rule--ok': properties.specialChar}">
                      <span class="strong-password__rule__circle"></span>
                      <span class="strong-password__rule__label">{{ $t('updatePassword.validation.specialCharacters') }}</span>
                  </div>
                  <div class="strong-password__rule" :class="{'strong-password__rule--ok': properties.minLength}">
                      <span class="strong-password__rule__circle"></span>
                      <span class="strong-password__rule__label">{{ $t('updatePassword.validation.minimumLength', {numChars: numChars}) }}</span>
                  </div>
              </div>
          </div>
    </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'StrongPassword',
  props: ['properties', 'password'],
  data () {
    return {
      numChars: 0,
      STRENGTH_TYPE: Vue.CONSTANTS.STRENGTH_TYPE,
      value: 0
    }
  },
  methods: {
    validatePasswordStrength: function (password, passwordStrengthObj) {
      let strengthValue = 0
      password = password || ''
      passwordStrengthObj.uppercase = new RegExp('(?=.*[A-Z])').test(password)
      passwordStrengthObj.lowercase = new RegExp('(?=.*[a-z])').test(password)
      passwordStrengthObj.numbers = new RegExp('(?=.*[0-9])').test(password)
      passwordStrengthObj.specialChar = new RegExp(/[!@#$&*%.]+/).test(password)
      passwordStrengthObj.minLength = password && password.length >= 12
      // console.log()
      for (const propertyValue of Object.values(passwordStrengthObj)) {
        if (propertyValue) {
          strengthValue++
        }
      }
      // console.log()

      switch (strengthValue) {
        case 1:
          this.value = this.STRENGTH_TYPE.VERY_WEAK
          break
        case 2:
          this.value = this.STRENGTH_TYPE.WEAK
          break
        case 3:
          this.value = this.STRENGTH_TYPE.REGULAR
          break
        case 4:
          this.value = this.STRENGTH_TYPE.ALMOST_VALID
          break
        case 5:
          this.value = this.STRENGTH_TYPE.VALID
          break
        default:
          this.value = 0
          break
      }
      strengthValue === this.STRENGTH_TYPE.VALID ? this.$emit('isValidPassword', true) : this.$emit('isValidPassword', false)
    }
  },
  watch: {
    password: function (newVal, oldVal) {
      this.validatePasswordStrength(newVal, this.properties)
      this.numChars = this.password.length
      // console.log()
    }
  }
}
</script>
