<template>
    <div class="no-results">
        <div class="no-results__icon-container">
            <span class="no-results__icon icon" :class="getIcon()"></span>
        </div>
        <p class="no-results__title">{{ $t('noResults.title')}}</p>
        <p class="no-results__text">{{ $t('noResults.text')}}</p>
    </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'NoResults',
  props: ['type'],
  data () {
    return {
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE
    }
  },
  methods: {
    getIcon () {
      switch (this.type) {
        case this.CARD_TYPE.PHYSICAL_EXAMINATION:
          return 'icon-physical-examination'
        case this.CARD_TYPE.FITNESS_SUMMARY:
          return 'icon-fitness-summary'
        case this.CARD_TYPE.OTHER_DOCUMENTATION:
          return 'icon-other-documentation'
        case this.CARD_TYPE.ANALYTICS:
          return 'icon-analytics'
        case this.CARD_TYPE.PERFORMED_TESTS:
          return 'icon-tests'
        case this.CARD_TYPE.APPOINMENTS:
          return 'icon-appointment'
        case this.CARD_TYPE.TRAINING:
          return 'icon-training'
        case this.CARD_TYPE.WORKPLACE:
          return 'icon-chair'
        case this.CARD_TYPE.EMPLOYMENT_HISTORY:
          return 'icon-briefcase'
        case this.CARD_TYPE.RISKS_EVALUATION:
          return 'icon-helmet'
        default:
          return 'icon-other-documentation'
      }
    }
  }
}
</script>
