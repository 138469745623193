<template>
  <div class="chart-container">
    <BarChartSimple
      :barsData="filteredDataChart"
      :title="'Peso'"
      :subtitle="currentWeight"
      :xLabels="filteredColumnLabels"
      :barColors="dataColors"
      cssClasses="first-chart"
      style="position: relative;"
    />
    <BarChartDouble
      :barsData="filteredDataChart"
      :title="'Peso'"
      :subtitle="currentWeight"
      :xLabels="filteredColumnLabels"
      :barColors="dataColors"
      cssClasses="first-chart"
      style="position: relative;"
    />
    <BarChartDoubleStack
      :barsData="filteredDataChart"
      :title="'Peso'"
      :subtitle="currentWeight"
      :xLabels="filteredColumnLabels"
      :barColors="dataColors"
      cssClasses="first-chart"
      style="position: relative;"
    />
    <!-- <LineCharts
      :barsData="filteredDataChart"
      :title="'Peso'"
      :subtitle="currentWeight"
      :xLabels="filteredColumnLabels"
      :barColors="dataColors"
      cssClasses="first-chart"
      style="position: relative;"
    /> -->
    <!-- <LineChartDoubleStack
      :barsData="filteredDataChart"
      :title="'Peso'"
      :subtitle="currentWeight"
      :xLabels="filteredColumnLabels"
      :barColors="dataColors"
      cssClasses="first-chart"
      style="position: relative;"
    /> -->
  </div>
</template>

<script>
import BarChartSimple from '@/components/charts/BarChartSimple.vue'
import BarChartDouble from '@/components/charts/BarChartDouble.vue'
import BarChartDoubleStack from '@/components/charts/BarChartDoubleStack.vue'
// import LineCharts from '@/components/charts/LineCharts.vue'
// import LineChartDoubleStack from '@/components/charts/LineChartDoubleStack.vue'

export default {
  name: 'ChartTest',
  components: { BarChartSimple, BarChartDouble, BarChartDoubleStack },
  data () {
    return {
      numberOfDataToShow: 4,
      userData: {
        id: 14852,
        weight: {
          2015: 76.3,
          2016: 79.7,
          2017: 78.5,
          2018: 76.8,
          2019: 83.7,
          2020: null,
          2021: 84.1,
          2022: 81.5
        }
      },
      dataColors: [
        // se puede alternar el color de las barras
        // 'rgb(255 255 255 / 30%)',
        // 'rgb(255 255 255 / 40%)',
        // 'rgb(255 255 255 / 50%)',
        'rgb(255 255 255 / 60%)'
      ]
    }
  },
  computed: {
    currentWeight () {
      return this.userData.weight[Object.keys(this.userData.weight)[Object.keys(this.userData.weight).length - 1]]
    },
    allColumnLabels () { // lavels de las columnas
      return Object.keys(this.userData.weight)
    },
    filteredColumnLabels () { // obtengo los 'ultimos valores, no todos
      // const allKeys = Object.keys(this.userData.weight)
      return this.allColumnLabels.slice(this.allColumnLabels.length - this.numberOfDataToShow)
    },
    allDataChart () { // valores de la gráfica
      return Object.values(this.userData.weight)
    },
    filteredDataChart () { // valores de la gráfica
      return this.allDataChart.slice(this.allDataChart.length - this.numberOfDataToShow)
    }
  }
  // methods: {
  // }
}
</script>

<style lang="scss">

// GRAFICA DOBLE PARA PONER DE FONDO UNA CON LAS BARRAS AL 100%

.chart-container {
  // width: 300px;
  // height:100px;
  background-image: url(/img/welcome-image.993a40d8.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 15px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: 30px;
}

.chart-container::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(289.55deg, #00425C 18.12%, #0c5a78 90.04%);    mix-blend-mode: color;
    mix-blend-mode: unset;
    opacity: 0.65;
}

.first-chart {
  // tamaño de la tabla
  width: 240px;
  height: 240px;
  background-color: #ffffff40;
  border-radius: 10px;
}

</style>
