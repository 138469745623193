<template>
    <div class="my-profile-tps-advice">
      <div class="my-profile-tps-advice__lock">
        <span class="icon icon-activation-code"></span>
        <p style="margin-bottom: 0px; padding-top: 2px;">{{ $t("myProfile.tpsAdvice") }}</p>
      </div>
      <div class="my-profile-tps-advice__download">
        <span v-html="$t('myProfile.tpsDownloadText')"></span>
        <a class='tps-warning__link' @click='openTPSModal()'> {{ $t("myProfile.tpsDownloadLink") }}</a>
      </div>
    </div>
</template>

<script>
export default {
  components: {
  },
  name: 'MyProfileTPSAdvice',
  props: [],
  data () {
    return {
    }
  },
  methods: {
    openTPSModal () {
      this.$store.commit('setTPSModalVisible', true)
    }
  },
  mounted () {
  }
}
</script>
