<template>
  <svg
    class="spinner"
    width="20px"
    height="20px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="path"
      :style="styles"
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
</template>
<script>
export default {
  name: 'Spinner',
  components: {},
  props: ['classes', 'styles'],
  data () {
    return {
    }
  },
  computed: {},
  mounted () {},
  created () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>
