<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <UpDocModal :data="docModalData" :docTitle="docTitle" :isLoading="isLoading" @onClose="closeDocModal()" v-if="isDocModalVisible" />
    <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" :isShareLoading="isShareLoading" @onSendFile="sendEmailFile($event)" />
    <h1>{{ $t('menu.technicalPrevention.informationConsultation') }}</h1>
    <SkeletonCard v-if="skeletonIsVisible"/>
    <NoResults :type="CARD_TYPE.PHYSICAL_EXAMINATION" v-if="laborRelations.length === 0 && !skeletonIsVisible" />
    <div class="card-filter__title" v-if="!skeletonIsVisible && laborRelations.length !== 0">
      {{ $t('risksEvaluation.chooseClient') }}
    </div>
    <CardFilter :data="clients" v-if="!skeletonIsVisible"/>
    <CardList :data="dataFiltered" v-if="laborRelations.length > 0"
      @onClickPreview="openPreview($event)"
      @onClickDownload="openDownload($event)"
      @onClickShareLink="openShareModal($event)"
      />
   </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CardFilter from '@/components/commons/CardFilter.vue'
import CardList from '@/components/commons/CardList.vue'
import UpDocModal from '@/components/modals/UpDocModal.vue'
import Modal from '@/components/modals/Modal.vue'
import Toast from '@/components/toasts/Toast.vue'
import NoResults from '@/components/commons/NoResults.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
import preventionService from '@/services/preventionService.js'
import * as _files from '@/_helpers/files.helper.js'
export default {
  name: 'InformationConsultation',
  components: { CardFilter, UpDocModal, CardList, Modal, NoResults, SkeletonCard, Toast },
  data () {
    return {
      isShareLoading: false,
      clients: [],
      laborRelations: [],
      skeletonIsVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      recognitionData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      recentActivities: [],
      docModalData: null,
      shareModalParams: {
        type: 7,
        title: this.$i18n.t('global.sendByEmail'),
        file: {
          name: 'nombre-del-archivo.pdf'
        }
      },
      toastParams: null
    }
  },
  mounted () {
    this.getIcpt()
  },
  methods: {
    openDownload: function (item) {
      console.log(item)
      console.log(this.laborRelations)
      const x = this.laborRelations.indexOf(item)
      if (x > -1) {
        this.$set(this.laborRelations[x], 'isLoading', true)
        console.log(this.laborRelations[x])
      }
      preventionService.getIcptFile(item.id)
        .then((response) => {
          // console.log()
          _files.downloadPDF(response.data.file, response.data.fileName)
        })
        .catch(() => {
          this.$set(this.laborRelations[x], 'isLoading', false)
          // console.log()
          this.isToastVisible = true
          this.toastParams = {
            type: 'danger',
            text: this.$t('errorMessage.generic'),
            time: 5000
          }
          setTimeout(() => {
            this.isToastVisible = false
          }, 4000)
        })
        .finally(() => {
          this.laborRelations[x].isLoading = false
        })
    },
    openShareModal: function (item) {
      const data = {
        laborRelationId: item.id
      }
      this.shareModalParams.file = data
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    sendEmailFile: function (data) {
      this.isShareLoading = true
      preventionService.shareIcpt(data.laborRelationId, data.fileName, data.toEmail)
        .then((response) => {
          console.log(response)
        })
        .catch(() => {
        })
        .finally(() => {
          this.isToastVisible = true
          this.toastParams = {
            text: this.$t('documentsUpload.uploadDocumentFinished'),
            type: 'success',
            time: 5000
          }
          this.isShareLoading = false
          setTimeout(() => {
            this.isToastVisible = false
          }, 7000)
          this.isModalVisible = false
        })
    },
    openPreview2: function (item) {
      console.log(item)
    },
    openPreview: function (item) {
      this.docModalData = null
      this.docTitle = null
      this.isDocModalVisible = true
      this.isLoading = true
      preventionService.getIcptFile(item.id)
        .then((response) => {
          this.docModalData = response.data.file
          this.docTitle = response.data.fileName
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    getIcpt () {
      this.skeletonIsVisible = true
      preventionService.getIcpt()
        .then((response) => {
          const clients = []
          const arr = []
          // console.log()
          response.data.forEach(element => {
            element.type = 13
            arr.push(element)
            const x = clients.find(x => x.clientId === element.clientId)
            if (!x) {
              clients.push({
                workplaceId: element.workplaceId,
                workplaceName: element.workplaceName,
                clientId: element.clientId,
                clientName: element.clientName,
                selected: false
              })
            }
          })
          clients[0].selected = true
          this.clients = [...clients]
          this.laborRelations = [...arr]
        })
        .catch(() => {
        })
        .finally(() => {
          this.skeletonIsVisible = false
        })
    }
  },
  computed: {
    ...mapGetters(['getRecognitionData']),
    dataFiltered () {
      const selected = this.clients.find(x => x.selected === true)
      if (!selected) return
      return this.laborRelations.filter(x => x.clientId === selected.clientId)
    }
  }
}
</script>
