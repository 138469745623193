<template>
    <div class="login-dropdown" @click="toggleDropdown" :class="{'login-dropdown--opened': openedDropdown }">
        <div class="login-dropdown__dropdown">
            <p class="login-dropdown__placeholder">{{ $t("login.dropdownTitle") }}</p>
            <span class="icon icon-arrow-down icon-16 login-dropdown__rotated-icon"></span>
        </div>
        <div class="login-dropdown__content">
            <a href="javascript:void(0);" class="link" @click="navigateTo('reset-password')">{{ $t("login.forgotPassword") }}</a>
            <p style="margin-top: 10px; margin-bottom: 0;" v-html="$t('login.dropdownContent')"></p>
        </div>
    </div>
</template>

<script>
export default {
  components: {},
  name: 'LoginDropdown',
  props: [],
  data () {
    return {
      openedDropdown: false
    }
  },
  methods: {
    toggleDropdown () {
      this.openedDropdown = !this.openedDropdown
    },
    navigateTo (path) {
      this.$router.push({ path: path })
    }
  },
  mounted () {
  }
}
</script>
