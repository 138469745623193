<template>
  <div class="welcome-view">
    <div class="welcome-view__container">
      <div class="welcome-view__image-container">
        <WelcomeImage />
      </div>
      <div class="welcome-view__form-container" v-if="isModalVisible">
        <!-- MODAL LEGAL TEXTS -->
        <LegalModal :texts="text" :type="legalContent" :mode="false" @onClose="showPrevingTerms(false)" v-if="isModalVisible && isShowPrevingTermsVisible"/>
      </div>
      <div class="welcome-view__form-container" v-if="!isModalVisible">
       <WelcomeLanguageSelector />
        <div class="welcome-view__form-content">
          <Toast :params="toastParams" v-if="isToastVisible"/>
          <img
            :src="getLogo()"
            class="welcome-view__form__logo margin-bottom-40"
          />
          <div v-if="linkError" class="form-message margin-bottom-30">
            <span class="form-message__text">{{ this.defaultError }}</span>
          </div>
          <div v-if="!linkError && showForm" class="width-100-percent">
            <p
              class="
                welcome-image__title
                welcome-view__form__title
                mobile-visible
              "
              v-html="$t('welcome.title')"
            ></p>
            <div class="welcome-view__form" v-if="rememberUsername && !sendedEmail">
              <p class="welcome-view__form__subtitle">{{ $t("restorePassword.restoreNewPassword") }}</p>
              <div class="form-group">
                <div class="form-group__label">{{ $t("login.username") }}</div>
                <div class="form-group__data">
                  <input
                    type="text"
                    :placeholder="$t('login.usernamePlaceholder')"
                    class="input"
                    v-model="username"
                    readonly
                  />
                  <div class="form-group__message" v-if="requiredEmailErrorMessage">
                    <span class="form-group__message__icon icon icon-info-fill"></span>
                    <span class="form-group__message__text">{{ this.requiredEmailErrorMessage }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-group__label">{{ $t("restorePassword.newPassword") }}</div>
                <div class="form-group__data">
                  <div class="input__container">
                    <span
                      class="input__icon icon"
                      :title="passwordVisible ? $t('login.hidePass') : $t('login.showPass')"
                      @click="setPasswordType(password)"
                      :class="passwordVisible ? 'icon-hide' : 'icon-show'"
                    ></span>
                    <input
                      :type="passwordVisible ? 'text' : 'password'"
                      :placeholder="$t('login.passwordPlaceholder')"
                      class="input"
                      v-model="password"
                    />
                    <div class="form-group__message" v-if="requiredPassErrorMessage">
                      <span class="form-group__message__icon icon icon-info-fill"></span>
                      <span class="form-group__message__text">{{ this.requiredPassErrorMessage }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <StrongPassword :password="password" :properties="passwordStrength" @isValidPassword="isValidPassword($event)" />
              <div class="form-group__message" v-if="passwordErrorMessage">
                <span class="form-group__message__icon icon icon-info-fill"></span>
                <span class="form-group__message__text">{{ this.passwordErrorMessage }}</span>
              </div>
              <Button :label="getButtonLabel()"
              :classes="'button--cta button--full-width margin-top-40'"
              :isLoading="isLoading"
              @click.native="restorePassword()" />
            </div>
          </div>
          <LegalLinks @onClickLink="showPrevingTerms(true, $event)" v-if="!linkError && showForm" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logoContigo from '@/assets/images/logo-preving-contigo.svg'
import WelcomeImage from '@/components/welcome/WelcomeImage'
import StrongPassword from '@/components/welcome/StrongPassword.vue'
import userService from '@/services/userService.js'
import Button from '@/components/ui/Button.vue'
import LegalLinks from '@/components/welcome/LegalLinks.vue'
import LegalModal from '@/components/welcome/LegalModal.vue'
import { mapGetters } from 'vuex'
import WelcomeLanguageSelector from '@/components/welcome/WelcomeLanguageSelector'
import Toast from '@/components/toasts/Toast.vue'

export default {
  name: 'ResetPassword',
  components: { WelcomeImage, WelcomeLanguageSelector, StrongPassword, Button, LegalLinks, LegalModal, Toast },
  mounted () {
    this.init()
    if (this.getServiceTerms) {
      this.serviceTermsi18n = this.getServiceTerms
      this.setServiceTerms()
    }

    if (this.getPrivacyTerms) {
      this.privacyTermsi18n = this.getPrivacyTerms
      this.setPrivacyTerms()
    }

    if (this.getCookiesTerms) {
      this.cookiesTermsi18n = this.getCookiesTerms
      this.setCookiesTerms()
    }
  },
  data () {
    return {
      isToastVisible: false,
      toastParams: null,
      defaultError: null,
      legalContent: null,
      mode: null,
      serviceTerms: null,
      serviceTermsi18n: null,
      privacyTerms: null,
      privacyTermsi18n: null,
      cookiesTerms: null,
      cookiesTermsi18n: null,
      myAppointmentsData: null,
      username: '',
      password: '',
      type: null,
      requiredEmailErrorMessage: null,
      requiredPassErrorMessage: null,
      passwordErrorMessage: null,
      passwordValid: false,
      passwordVisible: false,
      rememberUsername: true,
      sendedEmail: false,
      isLoading: false,
      linkError: false,
      showForm: false,
      isModalVisible: false,
      legalModal: {},
      isShowPrevingTermsVisible: false,
      passwordStrength: {
        uppercase: false,
        lowercase: false,
        numbers: false,
        specialChar: false,
        minLength: false
      },
      passwordStrengthValue: 0,
      rules: {
        required: value => !!value,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value)
        }
      }
    }
  },
  methods: {
    init () {
      // console.log()
      // TODO: Replace Service
      userService.getRegisterData(this.$route.params.uri)
        .then((response) => {
          this.username = response.data.email
          this.type = response.data.type
          // console.log()
          this.showForm = true
        })
        .catch((error) => {
          this.linkError = true
          this.defaultError = this.$t('errorMessage.' + error.response.data.message)
          this.isToastVisible = true
          this.toastParams = {
            type: 'danger',
            text: this.defaultError,
            time: 5000
          }
          setTimeout(() => {
            this.isToastVisible = false
            this.$router.push('/login')
          }, 5000)
        })
        .finally(() => {
        })
    },
    setServiceTerms: function () {
      this.serviceTerms = decodeURIComponent(atob(this.serviceTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setPrivacyTerms: function () {
      this.privacyTerms = decodeURIComponent(atob(this.privacyTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    setCookiesTerms: function () {
      this.cookiesTerms = decodeURIComponent(atob(this.cookiesTermsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    },
    showPrevingTerms (visibility, legalContent, mode) {
      if (legalContent === 'COOKIES') {
        this.$store.dispatch('showCookiesBar')
        return
      }
      this.isModalVisible = visibility
      this.legalContent = legalContent
      this.isShowPrevingTermsVisible = visibility
      if (legalContent === 'TERMS') {
        this.text = this.serviceTerms
      } else if (legalContent === 'PRIVACY') {
        this.text = this.privacyTerms
      } else if (legalContent === 'COOKIES') {
        this.text = this.cookiesTerms
      }
      this.mode = mode
      window.scrollTo(0, 0)
      // this.$store.commit('setLegalContent', legalContent)
    },
    isValidPassword (valid) {
      this.passwordValid = valid
      if (this.passwordValid === false) {
        this.passwordErrorMessage = this.$i18n.t('updatePassword.validation.invalid')
      } else {
        this.passwordErrorMessage = null
      }
    },
    navigateTo (path) {
      this.$router.push({ path: path })
    },
    setRememberUsername: function (rememberUsername) {
      this.rememberUsername = rememberUsername
    },
    getLogo: function () {
      return logoContigo
    },
    getButtonLabel () {
      if (this.isLoading) {
        return this.$t('restorePassword.savingPassword')
      } else {
        return this.$t('restorePassword.saveAndAccess')
      }
    },
    setPasswordType: function (password) {
      this.passwordVisible = !this.passwordVisible
      this.password = password
    },
    validateForm: function () {
      if (true) {}
    },
    restorePassword () {
      if (this.formHasErrors() === true) {
        return
      }
      this.isLoading = true
      setTimeout(() => {
        // TODO: Replace Service
        if (this.type === 0) {
          userService.sendPassToCreate(this.username, this.password, this.$route.params.uri)
            .then((response) => {
              this.sendedEmail = true
              // this.$store.commit('setCurrentUser', response.data)
              this.$router.push({ path: '/login' })
            })
            .catch((error) => {
              if (error.response.data.message === 'ERR_REGISTRATION_004') {
                this.linkError = true
                this.defaultError = this.$t('errorMessage.ERR_REGISTRATION_008')
                this.isToastVisible = true
                this.toastParams = {
                  type: 'danger',
                  text: this.defaultError,
                  time: 5000
                }
                setTimeout(() => {
                  this.isToastVisible = false
                  this.$router.push('/login')
                }, 5000)
              }
              // console.log()
            })
            .finally(() => {
            })
        } else {
          userService.sendPassToReset(this.username, this.password, this.$route.params.uri)
            .then((response) => {
              this.sendedEmail = true
              // this.$store.commit('setCurrentUser', response.data)
              this.$router.push({ path: '/login' })
            })
            .catch(() => {
              // console.log()
            })
            .finally(() => {
            })
        }
      }, 2000)
    },
    formHasErrors: function () {
      this.requiredEmailErrorMessage = null
      this.requiredPassErrorMessage = null
      let hasErrors = false

      if (this.rules.required(this.username) !== true) {
        hasErrors = true
        this.requiredEmailErrorMessage = this.$i18n.t('form.validation.required')
      }

      if (this.rules.required(this.password) !== true) {
        hasErrors = true
        this.requiredPassErrorMessage = this.$i18n.t('form.validation.required')
      }

      if (this.passwordValid === false) {
        hasErrors = true
      }

      return hasErrors
    }
  },
  computed: {
    ...mapGetters(['getLanguages', 'getPrivacyTerms', 'getServiceTerms', 'getCookiesTerms', 'getLocale', 'getCookiesPreferencesOpened']),
    user () {
      return { id: 1, username: this.username, password: this.password }
    }
  },
  watch: {
    getLocale: {
      handler (newVal, oldVal) {
        // console.log()
        this.setPrivacyTerms()
        this.setServiceTerms()
      },
      deep: true
    },
    getPrivacyTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.privacyTermsi18n = this.getPrivacyTerms
          this.setPrivacyTerms()
        }
      },
      deep: true
    },
    getServiceTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.serviceTermsi18n = this.getServiceTerms
          this.setServiceTerms()
        }
      },
      deep: true
    },
    getCookiesTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.cookiesTermsi18n = this.getCookiesTerms
          this.setCookiesTerms()
        }
      },
      deep: true
    },
    getCookiesPreferencesOpened: {
      handler (newVal, oldVal) {
        if (newVal === true) {
          // this.showPrevingTerms(true, 'COOKIES', false)
          this.isModalVisible = newVal
          this.legalContent = 'COOKIES'
          this.isShowPrevingTermsVisible = newVal
          this.text = this.cookiesTerms
          this.mode = false
          window.scrollTo(0, 0)
        }
      },
      deep: true
    }
  }
}
</script>
