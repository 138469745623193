<template>
  <!-- TODO: EL TITLE DEBERÍA DE ESTAR DENTRO
DE LA CABECERA PARA GESTIONAR MEJOR LA VERSIÓN MÓVIL -->
  <div class="card__list">
    <div class="card__item card__item--info">
      <div
        class="card__icon-container"
        style="background-color: #00425c;"
      >
        <span class="card__icon icon icon-info-toast" />
      </div>
      <div class="card__content">
        <div class="card__header">
          <div class="card__title-container">
            <h4 class="card__title card__title-info">
              {{ $tc( title ) }}
            </h4>
            <span
              v-if="date"
              class="card__date"
            >{{ date }}</span>
          </div>
        </div>
        <div class="card__columns-container no-boder-bottom">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'InformationCard',
  props: {
    title: {
      type: String,
      required: true,
      default: null
    },
    date: {
      type: String,
      required: false,
      default: null
    }
  }
  // data () {
  //   return {
  //   }
  // },
  // computed: {
  // },
}
</script>

<style scoped>

.card__item--info {
  padding: 0 20px 20px 0px;
}
.card__title-info {
  margin-top: 12px;
}

.card__columns-container {
  display: grid;
  grid-template-columns: 96px auto;
  gap: 5px;
}

@media (max-width: 800px) {
  .card__columns-container {
    margin-top: 35px;
    grid-template-columns: auto;
  }
  .card__columns-container p:nth-child(odd) {
    font-weight: 600;
    margin-bottom: 0
  }
}

</style>
