import axios from 'axios'
export default {
  getWeight () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/weight`
    )
  },
  getImc () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/imc`
    )
  },
  getGlucose () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/glucose`
    )
  },
  getBloodDiastolic () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/blood-pressure/diastolic`
    )
  },
  getBloodSystolic () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/blood-pressure/systolic`
    )
  },
  getCholesterolTotal () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/cholesterol`
    )
  },
  getUricAcid () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/uric-acid`
    )
  },
  getVision () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/vision`
    )
  },
  getSpirometry () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/spirometry`
    )
  },
  getUrineGlucose () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/glucose`
    )
  },
  getHemoglobin () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/hemoglobin`
    )
  },
  getHematocrito () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/hematocrito`
    )
  },
  getHematies () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/hematies`
    )
  },
  getGgt () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/ggt`
    )
  },
  getGot () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/got`
    )
  },
  getGpt () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/gpt`
    )
  },
  getHdl () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/hdl`
    )
  },
  getLdl () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/ldl`
    )
  },
  getTriglycerides () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/triglycerides`
    )
  },
  getHearing () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/hearing`
    )
  },
  getHearingLeft () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/hearing-left`
    )
  },
  getHearingRight () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/hearing-right`
    )
  },
  getPsa () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/kpis/psa`
    )
  }
}
