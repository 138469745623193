import axios from 'axios'
export default {
  getUploadedDocuments () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/uploaded-documents/all`
    )
  },
  getUploadedDocument (docId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/uploaded-documents/${docId}`
    )
  },
  uploadDocument (data) {
    return axios.put(
      `${window.VUE_APP_BACKEND_HOST}/uploaded-documents`, data
    )
  },
  deleteDocument (docId) {
    return axios.delete(
      `${window.VUE_APP_BACKEND_HOST}/uploaded-documents/${docId}`
    )
  },
  shareUploadedDocument (documentId, name, email) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/documents/shared/documents?documentId=${documentId}&type=600`,
      { name: name, email: email }
    )
  }
}
