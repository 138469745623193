<template>
    <div class="aside-menu__container">
        <div class="aside-menu__items-container">
          <!-- TODO:  Deshabilitar menú Pedir cita cuando haya cita pendiente -->
            <div class="aside-menu__item" v-for="(item, index) in data" :key="index"
                :class="{'aside-menu__item--selected' : item.selected, 'aside-menu__item--disabled' : item.disabled}"
                @click="selectOption(item)" >
            <span class="aside-menu__item__label">{{item.label}}</span>
            <span class="aside-menu__item__icon-item-disabled icon icon-lock-fill-s" v-tooltip="$t('home.notAvailableSection')" v-if="item.disabled"></span>
            <!-- <span class="aside-menu__item__icon-item-disabled icon icon-lock-fill-s" v-if="item.disabled"></span> -->
        </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'AsideMenu',
  props: ['data'],
  methods: {
    selectOption (option) {
      if (!option.disabled) this.$emit('onChangeMenu', option)
    }
  }
}
</script>
