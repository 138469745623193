<template>
  <div class="">
    <div :class="{'welcome-view__form-container__language-selector language-selector__container' : !inAvatarMenu}" @click="showModal()">
      <span class="language-selector__icon icon icon-globe"></span>
      <span class="language-selector__label">{{ getCurrentLanguage().name }}</span>
      <!-- <span class="language-selector__label">{{ $t('welcomeLanguageSelector.language')}}</span> -->
      <span class="mobile-menu__item__arrow icon icon-arrow-down" v-if="!inAvatarMenu"></span>
    </div>
    <div class="language-selector__modal__container" v-if="isLanguageModalVisible">
        <div class="language-selector__modal">
            <span class="language-selector__modal__close icon icon-close" @click="showModal()"></span>
            <p class="language-selector__modal__title">{{ $t('welcomeLanguageSelector.selectLanguage')}}</p>
            <ul class="language-selector__modal__list">
                <li class="language-selector__modal__list__item" :class="{'language-selector__modal__list__item--selected' : language.id === getCurrentLanguage().id}"  v-for="(language, index) in languages" :key="index"  @click="setLocale(language)">{{language.name}}</li>
            </ul>
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WelcomeLanguageSelector',
  props: ['inAvatarMenu'],
  data () {
    return {
      isLanguageModalVisible: false,
      currentLocale: '',
      currentLanguage: sessionStorage.getItem('language'),
      languages: [
        {
          id: 1,
          name: this.$i18n.t('global.spanish'),
          code: 'es',
          order: 1,
          active: true,
          activeInExtranet: true,
          activeInIexpedient: true
        },
        {
          id: 2,
          name: this.$i18n.t('global.catalan'),
          code: 'ca',
          order: 2,
          active: false,
          activeInExtranet: true,
          activeInIexpedient: true
        },
        {
          id: 3,
          name: this.$i18n.t('global.basque'),
          code: 'eu',
          order: 3,
          active: false,
          activeInExtranet: false,
          activeInIexpedient: false
        },
        {
          id: 4,
          name: this.$i18n.t('global.english'),
          code: 'en',
          order: 4,
          active: false,
          activeInExtranet: false,
          activeInIexpedient: false
        }
      ]
    }
  },
  mounted () {
    // this.$i18n.locale = this.languages.find(x => x.id === this.getCurrentUser.language).code
    // this.currentLocale = this.$i18n.locale
    // // console.log()
    // this.languagesInUse = this.languages
    // this.$store.dispatch('addToFavorites', 'hola')
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu'])
  },
  methods: {
    setLocale: function (locale) {
      sessionStorage.setItem('language', locale.id)
      this.$i18n.locale = locale.code
      // console.log()
      this.languages.forEach(language => {
        language.active = false
      })
      this.languages.find(x => x.id === locale.id).active = true
      // const tempUser = this.getCurrentUser
      // tempUser.language = this.languages.find(x => x.id === locale.id).id
      // this.$store.commit('setCurrentUser', tempUser)
      // this.$store.commit('setMenu', this.getMenu)
      this.$store.commit('changeLocale')
      this.$store.commit('setLocale', locale.code)
      this.showModal()
      this.closeModal()
    },
    getCurrentLanguage: function () {
      // console.log()
      // console.log()
      // console.log()
      // this.$i18n.locale = this.languages.find(x => x.id === this.getCurrentUser.language).code
      if (this.$i18n.locale && this.languages.length > 0) {
        const currentLanguage = this.languages.find(language => language.code === this.$i18n.locale)
        // console.log()
        return currentLanguage
      } else {
        return ''
      }
    },
    showModal: function () {
      this.isLanguageModalVisible = !this.isLanguageModalVisible
    },
    closeModal: function () {
      this.$emit('onClose', true)
    }
  },
  watch: {
    languages: function (newVal, oldVal) {
      // console.log()
    }
  }
}
</script>
