<template>
    <div class="faqs__container">
        <div class="faqs__modal scroll scroll2">
          <div class="faqs__title">{{ $t('faqs.title') }}</div>
          <span class="faqs__icon-close icon icon-close" @click="hideModal()"></span>
        <div class="faqs__list">
          <div class="faqs__item" :class="{'faqs__item--selected' : faq.selected}" v-for="(faq, index) in faqs" :key="index" @click="setSelectedItem(faq)">
            <span class="faqs__icon icon" :class="faq.selected ? 'icon-remove' : 'icon-add'"></span>
            <p class="faqs__question">{{ faq.question }}</p>
            <div class="faqs__answer" v-html="faq.answer"></div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
  name: 'FAQS',
  data () {
    return {
      faqs: [
        { question: this.$t('faqs.faq1'), answer: this.$t('faqs.answer1'), selected: false },
        { question: this.$t('faqs.faq2'), answer: this.$t('faqs.answer2'), selected: false },
        { question: this.$t('faqs.faq3'), answer: this.$t('faqs.answer3'), selected: false },
        { question: this.$t('faqs.faq4'), answer: this.$t('faqs.answer4'), selected: false },
        { question: this.$t('faqs.faq5'), answer: this.$t('faqs.answer5'), selected: false },
        { question: this.$t('faqs.faq6'), answer: this.$t('faqs.answer6'), selected: false },
        { question: this.$t('faqs.faq7'), answer: this.$t('faqs.answer7'), selected: false },
        { question: this.$t('faqs.faq8'), answer: this.$t('faqs.answer8'), selected: true }
      ]
    }
  },
  methods: {
    hideModal () {
      this.$store.commit('setFAQSModalVisible', false)
    },
    setSelectedItem (faq) {
      if (faq.selected) {
        faq.selected = false
      } else {
        this.faqs.forEach((el) => {
          el.selected = false
        })
        faq.selected = true
      }
    }
  }
}
</script>
