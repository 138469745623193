<template>
  <div class="section__container section__container--three-columns">
      <div class="">
        <AsideMenu :data="getMenu[4].items" @onChangeMenu="loadSection($event)" v-if="getMenu.length > 0"/>
        <Banners class="margin-top-20" v-if="getCurrentDevice === DEVICES.TABLET" />
      </div>
        <div class="section__content" v-if="getParamSection">
            <component :is="getParamSection.component" />
        </div>
        <div class="">
          <Banners v-if="getCurrentDevice !== DEVICES.TABLET" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AsideMenu from '@/components/commons/AsideMenu.vue'
import RequestAppoinments from '@/components/my-appoinments/RequestAppoinments.vue'
import Appoinments from '@/components/my-appoinments/Appoinments.vue'
import Banners from '@/components/commons/Banners.vue'
import * as _modules from '../../_helpers/modules.helper.js'

export default {
  name: 'MyAppoinments',
  components: { AsideMenu, Banners, Appoinments, RequestAppoinments },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      windowWidth: window.innerWidth,
      menu: []
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu', 'getCurrentDevice', 'getParamSection', 'getAppointments'])
  },
  created () {
    window.addEventListener('resize', this.onResize)
    // console.log()
  },
  mounted () {
    this.menu = [...this.getMenu]
    if (!this.getParamSection) {
      this.$store.commit('setParamSection', this.getMenu[4].items[0])
    }
    this.loadSection(this.getParamSection)
  },
  methods: {
    loadSection: function (section) {
      this.$store.commit('setParamSection', section)
      this.menu = [...this.getMenu]
      if (!section.selected) {
        this.menu[4].items.forEach(element => {
          element.selected = false
        })
        this.menu[4].items[1].disabled = !this.getAppointmentsEnabled()
        section.selected = true
        this.menu[4].opened = true
      }
      this.$store.commit('setMenu', this.menu)
    },
    getAppointmentsEnabled () {
      const a = _modules.appointmentsDisabled()
      console.log(a)
      if (!a.hasVs) {
        return false
      }
      // has active appointment
      if (a.lastAppointment) {
        this.nextAppoinment = a.lastAppointment
        return false
      }
      if (a.checkExpiredDate) {
        // console.log(a.appointments)
        // console.log(a)
        return false
      }
      return true
    }
  },
  watch: {
    getAppointments: {
      deep: true,
      handler: function (newVal) {
        this.menu[4].items[1].disabled = !this.getAppointmentsEnabled()
      }
    }
  }
}
</script>
