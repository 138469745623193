<template>
  <div class=''>
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <h1 class="subsection__title">{{ $t('menu.healthMonitoring.healthyGuides') }}</h1>
    <div class="flex align-items-center justify-space-between margin-bottom-20" v-if="getCurrentDevice === DEVICES.MOBILE">
      <span v-if="!tagSelected"></span>
      <span class="health-guide__filter-mobile-tag" v-if="tagSelected">{{tagSelected.name}}</span>
      <div class="flex align-items-center">
        <span class="health-guide__filter-icon icon icon-filter" @click="showFilter()"></span>
        <span class="health-guide__filter-icon-label margin-left-5" @click="showFilter()">Temáticas...</span>
      </div>
    </div>
    <div class="health-guide__tag-list" v-if="(getCurrentDevice === DEVICES.MOBILE && isFilterMobileVisible) || getCurrentDevice >= DEVICES.TABLET">
      <span
        class="health-guide__tag-list__close icon icon-close"
        @click="closeFilter()"
      ></span>
      <span class="health-guide__filter-mobile-title">Temáticas</span>
      <span
        class="health-guide__filter-tag"
        :class="{ 'health-guide__filter-tag--selected': tag.selected }"
        v-for="(tag, index) in healthGuidesTags"
        :key="index"
        @click="setFilterTag(tag)"
        >{{ tag.name }}</span
      >
    </div>
    <div class="health-guide__container">
      <div
        class="health-guide__item"
        :class="{
          'health-guide__item--hidden': !isItemVisible(healthGuide.tags)
        }"
        v-for="(healthGuide, index) in healthyGuides"
        :key="index"
        :style="{ 'background-image': 'url(' + healthGuide.image + ')' }"
        @click="downloadHealthGuide(healthGuide)"
      >
      <img :src="downloadGuide" class="health-guide__download">
        <div class="health-guide__content">
          <p class="health-guide__title">{{ healthGuide.title }}</p>
          <!-- <div class="health-guide__tags-container">
            <span
              class="health-guide__tag"
              v-for="(tag, index) in healthGuide.tags"
              :key="index"
              >{{ tag }}</span
            >
          </div> -->
        </div>
      </div>
    </div>
    <div class="flex justify-center margin-top-20" v-if="tagSelected && tagSelected.name === this.$t('healthyGuides.tags.featured')">
      <button class="button button--small button--link" @click="removeFilter()">
        <span class="button__label">Ver todas las guías</span>
      </button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import healthGuideLoads from '@/assets/images/health-guide-loads.png'
import healthGuideFood from '@/assets/images/health-guide-food.png'
import healthGuideAffony from '@/assets/images/health-guide-affony.png'
import healthGuideBack from '@/assets/images/health-guide-back.png'
import healthGuideCancerMama from '@/assets/images/health-guide-cancer-mama.png'
import healthGuideDriving from '@/assets/images/health-guide-driving.png'
import healthGuideLegs from '@/assets/images/health-guide-legs.png'
import healthGuideLungs from '@/assets/images/health-guide-lungs.png'
import healthGuideHealthyHabits from '@/assets/images/health-guide-healthy-habits.png'
import healthGuideNoise from '@/assets/images/health-guide-noise.png'
import healthGuideRadiation from '@/assets/images/health-guide-radiation.png'
import healthGuideScreen from '@/assets/images/health-guide-screen.png'
import healthGuideVaccination from '@/assets/images/health-guide-vaccination.png'
import healthGuideFitosanitaries from '@/assets/images/health-guide-fitosanitaries.png'
import downloadGuide from '@/assets/images/health-guide-download.svg'
import Toast from '@/components/toasts/Toast.vue'
import axios from 'axios'
export default {
  name: 'HealthyGuides',
  components: { Toast },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      windowWidth: window.innerWidth,
      tagSelected: { name: this.$t('healthyGuides.tags.featured'), selected: true },
      isFilterMobileVisible: false,
      downloadGuide: downloadGuide,
      toastParams: null,
      isToastVisible: false,
      healthyGuides: [
        {
          title: this.$t('healthyGuides.recommendationsLoads'),
          image: healthGuideLoads,
          url: '1-recomendaciones-para-el-manejo-de-cargas-y-posturas-forzadas.pdf',
          tags: [
            this.$t('healthyGuides.tags.featured'),
            this.$t('healthyGuides.tags.positions'),
            this.$t('healthyGuides.tags.loads'),
            this.$t('healthyGuides.tags.back')
          ]
        },
        {
          title: this.$t('healthyGuides.healthyDietaryHabits'),
          image: healthGuideFood,
          url: '2-habitos-dieteticos-saludables.pdf',
          tags: [
            this.$t('healthyGuides.tags.featured'),
            this.$t('healthyGuides.tags.health'),
            this.$t('healthyGuides.tags.healthyHabits'),
            this.$t('healthyGuides.tags.diet'),
            this.$t('healthyGuides.tags.lifestyle')
          ]
        },
        {
          title: this.$t('healthyGuides.HealthyLifestyleHabits'),
          image: healthGuideHealthyHabits,
          url: '3-habitos-de-vida-saludables.pdf',
          tags: [
            this.$t('healthyGuides.tags.featured'),
            this.$t('healthyGuides.tags.health'),
            this.$t('healthyGuides.tags.healthyHabits'),
            this.$t('healthyGuides.tags.lifestyle')
          ]
        },
        {
          title: this.$t('healthyGuides.venousDisorders'),
          image: healthGuideLegs,
          url: '4-trastornos-venosos.pdf',
          tags: [
            this.$t('healthyGuides.tags.featured'),
            this.$t('healthyGuides.tags.varicose'),
            this.$t('healthyGuides.tags.health')
          ]
        },
        {
          title: this.$t('healthyGuides.biologicalRisks'),
          image: healthGuideVaccination,
          url: '5-riesgos-biologicos.pdf',
          tags: [
            this.$t('healthyGuides.tags.featured'),
            this.$t('healthyGuides.tags.vaccination'),
            this.$t('healthyGuides.tags.biologicalRisks')
          ]
        },
        {
          title: this.$t('healthyGuides.breastCancerPrevention'),
          image: healthGuideCancerMama,
          url: '6-prevencion-del-cancer-de-mama.pdf',
          tags: [
            this.$t('healthyGuides.tags.featured'),
            this.$t('healthyGuides.tags.prevention'),
            this.$t('healthyGuides.tags.cancer')
          ]
        },
        {
          title: this.$t('healthyGuides.skinCancerPrevention'),
          image: healthGuideBack,
          url: '7-prevencion-del-cancer-de-piel.pdf',
          tags: [
            this.$t('healthyGuides.tags.prevention'),
            this.$t('healthyGuides.tags.cancer')
          ]
        },
        {
          title: this.$t('healthyGuides.roadSafety'),
          image: healthGuideDriving,
          url: '8-seguridad-vial-patologia-medica-y-conduccion-de-vehiculos.pdf',
          tags: [
            this.$t('healthyGuides.tags.driving'),
            this.$t('healthyGuides.tags.cars')
          ]
        },
        {
          title: this.$t('healthyGuides.preventionMuscleFatigue'),
          image: healthGuideScreen,
          url: '9-prevencion-de-la-fatiga-muscular-y-visual-en-trabajos-con-pvd.pdf',
          tags: [this.$t('healthyGuides.tags.screens')]
        },
        {
          title: this.$t('healthyGuides.recommendationsPathologyNoise'),
          image: healthGuideNoise,
          url: '10-prevencion-de-las-patologias-derivadas-del-trabajo-con-ruido.pdf',
          tags: [this.$t('healthyGuides.tags.noise')]
        },
        {
          title: this.$t('healthyGuides.recommendationsProtectionIonizingRadiation'),
          image: healthGuideRadiation,
          url: '11-prevencion-sobre-trabajos-expuestos-a-radiaciones-ionizantes.pdf',
          tags: [this.$t('healthyGuides.tags.ionizingRadiation')]
        },
        {
          title: this.$t('healthyGuides.preventionTuberculosis'),
          image: healthGuideLungs,
          url: '12-prevencion-de-la-tuberculosis-en-el-medio-laboral.pdf',
          tags: [this.$t('healthyGuides.tags.tuberculosis')]
        },
        {
          title: this.$t('healthyGuides.preventionDysphonia'),
          image: healthGuideAffony,
          url: '13-prevencion-de-las-disfonias.pdf',
          tags: [this.$t('healthyGuides.tags.dysphonia')]
        },
        {
          title: this.$t('healthyGuides.recommendationsPhytosanitaryProducts'),
          image: healthGuideFitosanitaries,
          url: '14-manipulacion-de-productos-fitosanitarios.pdf',
          tags: [this.$t('healthyGuides.tags.phytosanitaries')]
        }
      ],
      healthGuidesTags: [
        { name: this.$t('healthyGuides.tags.featured'), selected: true },
        { name: this.$t('healthyGuides.tags.back'), selected: false },
        { name: this.$t('healthyGuides.tags.diet'), selected: false },
        { name: this.$t('healthyGuides.tags.lifestyle'), selected: false },
        { name: this.$t('healthyGuides.tags.health'), selected: false },
        { name: this.$t('healthyGuides.tags.vaccination'), selected: false },
        { name: this.$t('healthyGuides.tags.cancer'), selected: false },
        { name: this.$t('healthyGuides.tags.driving'), selected: false },
        { name: this.$t('healthyGuides.tags.screens'), selected: false },
        { name: this.$t('healthyGuides.tags.noise'), selected: false },
        { name: this.$t('healthyGuides.tags.ionizingRadiation'), selected: false },
        { name: this.$t('healthyGuides.tags.tuberculosis'), selected: false },
        { name: this.$t('healthyGuides.tags.dysphonia'), selected: false },
        { name: this.$t('healthyGuides.tags.phytosanitaries'), selected: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['getCurrentDevice'])
  },
  methods: {
    async downloadHealthGuide ({ url, title }) {
      // console.log()
      const response = await axios.get(`${window.VUE_APP_BACKEND_HOST}/guides/${url}`, { responseType: 'blob' })
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = url
      link.click()
      URL.revokeObjectURL(link.href)
      // .then(response => {
      //   // console.log()
      //   this.isToastVisible = true
      //   this.toastParams = {
      //     text: `La guía ${title} ha sido descargada correctamente`,
      //     type: 'success'
      //   }
      //   setTimeout(() => {
      //     this.isToastVisible = false
      //   }, this.toastParams.time || 5000)
      // }).catch(console.error)
    },
    showFilter: function () {
      this.isFilterMobileVisible = true
    },
    closeFilter: function () {
      this.isFilterMobileVisible = false
    },
    removeFilter: function () {
      this.tagSelected = null
      this.healthGuidesTags.forEach((tag) => {
        tag.selected = false
      })
    },
    setFilterTag: function (tag) {
      if (tag.selected) {
        tag.selected = false
        this.tagSelected = null
      } else {
        this.healthGuidesTags.forEach((tag) => {
          tag.selected = false
        })
        tag.selected = true
        this.tagSelected = tag
      }
      this.isFilterMobileVisible = false
    },
    isItemVisible: function (tags) {
      if (this.tagSelected) {
        return tags.indexOf(this.tagSelected.name) !== -1
      } else {
        return true
      }
    },
    openModal: function (file) {
      this.shareModalParams.file = file
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    sendFile: function (recipient) {
      // console.log()
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        text: `El archivo se ha enviado correctamente a ${recipient.name}`,
        time: 5000
      }
    },
    openPreview: function (item) {
      this.docModalData = item
      if (!item.uuid || item.uuid === 'xxxx') {
        this.isToastVisible = true
        this.toastParams = {
          text: this.$t('documentModal.error'),
          type: 'danger',
          time: 5000
        }
      } else {
        this.isDocModalVisible = true
      }
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    }
  },
  watch: {
    getLocale: {
      handler (newVal, oldVal) {
        // console.log()
        // console.log()
      },
      deep: true
    },
    getHealthGuidesTags: {
      handler (newVal, oldVal) {
        // console.log()
        this.healthGuidesTags = newVal
      },
      deep: true
    },
    getHealthyGuides: {
      handler (newVal, oldVal) {
        // console.log()
        this.healthyGuides = newVal
      },
      deep: true
    },
    getTagSelected: {
      handler (newVal, oldVal) {
        // console.log()
        this.tagSelected = newVal
      },
      deep: true
    }
  }
}
</script>
