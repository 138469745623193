<template>
  <button
    class="button"
    :class="classes"
    :disabled="isLoading || disabledButton"
  >
    <Spinner
      v-if="isLoading"
      class="button__spinner"
    />
    <span
      v-if="!iconRight && !isLoading && icon"
      class="button__icon icon"
      :class="icon"
    />
    <span class="button__label">{{ label }}</span>
    <span
      v-if="iconRight && !isLoading && icon"
      class="button__icon icon margin-left-10"
      :class="icon"
    />
  </button>
</template>
<script>
import Spinner from '@/components/spinners/Spinner.vue'
export default {
  name: 'Button',
  components: { Spinner },
  props: ['label', 'icon', 'classes', 'isLoading', 'disabledButton', 'iconRight'],
  methods: {}
}
</script>
