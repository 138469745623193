<template>
  <div>
    <slot />
  </div>
</template>
<script>

export default {
  name: 'Tabs',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    date: {
      type: String,
      required: false,
      default: null
    }
  }
  // data () {
  //   return {
  //   }
  // },
  // computed: {
  // },
}
</script>

<style scoped>

</style>
