<script>
import { Line } from 'vue-chartjs/legacy'

import { Chart as ChartJS, Title, SubTitle, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
ChartJS.register(Title, SubTitle, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  extends: Line,
  props: ['data', 'options', 'chartId', 'datasetIdKey'],
  mounted () {
    this.renderChart(this.data, this.options, this.chartId, this.datasetIdKey)
  }
}

</script>
