import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    hemoglobin: [],
    isLoading: false
  }
}

export default {
  state: {
    fakeHemoglobin:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 15.8
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 15.3
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 15.1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-09T10:54:30.393190',
          year: '2023',
          value: 14.8
        }
      ],
    // data: [79.4, 90, 69.7, 82.3],
    // xLabel: [2019, 2020, 2021, 2222],
    // fakeData: 33,
    hemoglobin: [],
    isLoading: false
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    // loadData: (state, payload) => (state.hemoglobin = payload)
    loadDataHemoglobin (state, payload) {
      // console.log('loadData', payload)
      state.hemoglobin = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateHemoglobin (state) { // fecha del último dato con valor
      const itemsWithValue = state.hemoglobin.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataHemoglobin (state) { // valor del último dato recogido
      const itemsWithValue = state.hemoglobin.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return parseFloat(el.value)
      })
      return valuesArray.pop()
    },
    filteredDataHemoglobin (state) { // solo datos
      const objectValues = state.hemoglobin.map(function (el) {
        return el.value
      })
      return objectValues
    },
    filteredXlabelsHemoglobin (state) { // solo label del eje Y
      const objectKeys = state.hemoglobin.map(function (el) {
        return el.year
      })
      return objectKeys
    }
  },
  actions: {
    async fetchHemoglobin ({ commit, state }) {
      if (state.hemoglobin.length === 0) {
        state.isLoading = true
        kpiService
          .getHemoglobin()
          .then((response) => {
            commit('loadDataHemoglobin', response.data)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            state.isLoading = false
          })
      }
    }
  }
}
