import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import CONSTANTS from './plugins/constants.js'
import * as Auth from './_helpers/jwt.interceptor.js'
import VueSlider from 'vue-slider-component'
import Tooltip from 'vue-directive-tooltip'
import VueGtag from 'vue-gtag'
import 'vue-slider-component/theme/default.css'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'

Vue.component('VueSlider', VueSlider)
Vue.use(Tooltip)

// name is optional
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(CONSTANTS)

Vue.use(VueGtag, {
  bootstrap: false
})

Auth.jwtInterceptor()

require('./assets/scss/main.scss')
require('./assets/css/icons.css')

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
