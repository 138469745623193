import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vuetify from 'vuetify/lib/framework'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#004451', // #'E53935
        secondary: 'rgba(0,0,0,0.54)', // #FFCDD2
        accent: '#EE6A01' // #3F51B5
      }
    }
  }
})
