<template>
  <div>
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="chartOptions.plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChartDouble',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Object,
    //   default: () => {}
    // },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      // eslint-disable-next-line
      type: String | Number,
      default: ''
    },
    xLabels: {
      type: Array,
      default: null
    },
    barsData: {
      type: Array,
      default: null
    },
    barColors: {
      type: Array,
      default: null
    },
    barsBackground: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      chartData: {
        // labels: ['2019', '2020', '2021'],
        labels: this.xLabels, // etiquetas inferiores
        datasets: [
          {
            label: null, // leyenda superior
            backgroundColor: this.barColors, // color barras
            data: this.barsData, // datos de las gráficas
            // borderColor: 'rgb(255 99 132)',
            // borderWidth: 1,
            // borderSkipped: 'bottom', // anula alguno de los border
            borderSkipped: false, // mete borderRadius inferior
            borderRadius: 100
            // inflateAmount: 5, // infla el border ?
            // hoverBorderWidth: 4
          },
          {
            label: null,
            data: [100, 100, 100, 100],
            borderSkipped: false,
            borderRadius: 100
          }
        ]
        // hoverOffset: 4
      },
      chartOptions: {
        // todo lo que vaya en options va aquí.
        barThickness: 12, //  grosor de las barras
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: true,
        layout: { // marco del canvas
          padding: 10
          // padding: {
          //   left: 50,
          //   right: 10,
          // }
        },
        plugins: {
          title: {
            display: true,
            text: this.title,
            padding: {
              top: 10,
              bottom: 5
            },
            color: 'white',
            font: {
              family: "'Calibri', sans-serif",
              size: 18,
              weight: 'lighter',
              style: 'normal'
            }
          },
          subtitle: {
            display: true,
            // text: 'Custom Chart Subtitle',
            text: `${this.subtitle} Kg`,
            color: 'white',
            font: {
              family: "'Calibri', sans-serif",
              size: 14,
              weight: 'lighter',
              style: 'normal'
            }
          },
          legend: {
            display: false,
            labels: {
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 12,
                style: 'normal',
                weight: undefined,
                lineHeight: 1.2
              }
            }
          }
          // tooltip: {
          //   callbacks: {
          //     labelColor: function (context) {
          //       return {
          //         borderColor: 'rgb(0, 0, 255)',
          //         backgroundColor: 'rgb(255, 0, 0)',
          //         borderWidth: 2,
          //         borderDash: [2, 2],
          //         borderRadius: 2
          //       }
          //     },
          //     labelTextColor: function (context) {
          //       return 'white'
          //     }
          //     // labelPointStyle: function (context) {
          //     //   return {
          //     //     pointStyle: 'triangle',
          //     //     rotation: 0
          //     //   }
          //     // }
          //   }
          // }
        },
        scales: {
          // ticks: {
          //   // fontSize: 18,
          //   // stepSize: 5,
          //   // beginAtZero: true
          // },
          // stacked: true, // no me funciona
          x: {
            // type: 'linear',
            stacked: false,
            min: 0,
            max: 100,
            color: '#fffff',
            grid: {
              display: false,
              drawBorder: false
              // lineWidth: 0
              // color: '#F28C00'
            },
            ticks: {
              color: '#ffffff',
              font: {
                family: "'Calibri', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              }
            }
          },
          y: {
            // type: 'linear',
            stacked: false,
            min: -1,
            max: 100,
            grid: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false,
              color: '#ffffff',
              font: {
                family: "'Calibri', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              }
            }
          }
        }
      }
    }
  }
}
</script>
