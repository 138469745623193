import axios from 'axios'
export default {
  getMyAppointments () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/my-appointments`
    )
  },
  getCompanies () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/my-appointments/companies`
    )
  },
  getCompany (id) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/my-appointments/company/${id}`
    )
  },
  getMedicalCenters (province) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/my-appointments/medical-centers?province=${province}`
    )
  },
  // api make appointment
  getAppointmentAvailability (initDate, finDate, companyId, centerId) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/appointment-api/check-availability`,
      { fechaIni: initDate, fechaFin: finDate, empresa: companyId, idCentro: centerId }
    )
  },
  bookAppointment (initDate, workerId, reasonId, companyId, centerId) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/appointment-api/book-appointment`,
      { horaInicio: initDate, trabajadorId: workerId, motivoId: reasonId, empresa: companyId, idCentro: centerId }
    )
  },
  cancelAppointment (initDate, reasonId, companyId, centerId) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/appointment-api/cancel-appointment`,
      { horaInicio: initDate, motivoId: reasonId, empresa: companyId, idCentro: centerId }
    )
  },
  getReasons (typeId, reasonId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/my-appointments/reasons/${typeId}/${reasonId}`
    )
  },
  getAvailableCenter (clientId, workcenterId, provinceId, reasonId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/appointment-api/centers?clientId=${clientId}&workcenterId=${workcenterId}&provinceId=${provinceId}&reasonId=${reasonId}`
    )
  },
  getAllowAppointment (data) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/appointment-api/allow/center`, data
    )
  }
}
