import axios from 'axios'
export default {
  getTrainingReceived () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/technical-prevention/training`
    )
  },
  getRisksEvaluation () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/risks-evaluation`
    )
  },
  getDocument (uuid, type) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/risks-evaluation/${uuid}?type=${type}`
    )
  },
  // shareRiskEvaluation (laborRelationId, name, email) {
  //   return axios.post(
  //     `${window.VUE_APP_BACKEND_HOST}/documents/shared/icpt?uuid=${uuid}&type=800`,
  //     { name: name, email: email }
  //   )
  // },
  getIcpt () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/icpt`
    )
  },
  getIcptFile (laborRelationId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/icpt/${laborRelationId}`
    )
  },
  shareIcpt (laborRelationId, name, email) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/documents/shared/icpt?laborRelationId=${laborRelationId}&type=700`,
      { name: name, email: email }
    )
  }
}
