import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    spirometry: [],
    isLoading: false,
    units: false
  }
}

export default {
  state: {
    fakeSpirometry:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 0
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-09T10:54:30.393190',
          year: '2023',
          value: 1
        }
      ],
    spirometry: [],
    isLoading: false,
    units: false
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    // loadData: (state, payload) => (state.spirometry = payload)
    loadDataSpirometry (state, payload) {
      // console.log('loadData', payload)
      state.spirometry = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateSpirometry (state) { // fecha del último dato con valor
      const itemsWithValue = state.spirometry.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataSpirometry (state) { // valor del último dato recogido
      const itemsWithValue = state.spirometry.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    filteredDataSpirometry (state) { // solo datos
      const objectValues = state.spirometry.map(function (el) {
        return el.value
      })
      // console.log('filteredDataSpirometry', objectValues)
      return objectValues
    },
    filteredXlabelsSpirometry (state) { // solo label del eje Y
      const objectKeys = state.spirometry.map(function (el) {
        return el.year
      })
      return objectKeys
    },
    getUnitsSpirometry (state) {
      return state.units
    }
  },
  actions: {
    fetchSpirometry ({ commit, state }) {
      if (state.spirometry.length === 0) {
        state.isLoading = true
        kpiService.getSpirometry()
          .then((response) => {
            // console.log('DATA spirometry', response.data)
            commit('loadDataSpirometry', response.data)
            // commit('loadDataSpirometry', state.fakeSpirometry) // dato de prueba
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally spirometry')
            state.isLoading = false
          })
      }
    }
  }
}
