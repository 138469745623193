<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <DocModal :data="docModalData" @onClose="closeDocModal" v-if="isDocModalVisible" />
    <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" @onSendFile="sendFile($event)" />
    <h1>{{ $t('menu.technicalPrevention.trainingReceived') }}</h1>
    <SkeletonCard v-if="skeletonIsVisible"/>
    <NoResults :type="CARD_TYPE.PHYSICAL_EXAMINATION" v-if="trainingReceived.length === 0 && !skeletonIsVisible" />
    <CardList :data="trainingReceived" @onClickPreview="openPreview($event)" @onClickShareLink="openModal($event)"   v-if="trainingReceived.length > 0"/>
   </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CardList from '@/components/commons/CardList.vue'
import DocModal from '@/components/modals/DocModal.vue'
import Modal from '@/components/modals/Modal.vue'
import Toast from '@/components/toasts/Toast.vue'
import NoResults from '@/components/commons/NoResults.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
export default {
  name: 'trainingReceivedData',
  components: { DocModal, CardList, Modal, NoResults, SkeletonCard, Toast },
  data () {
    return {
      err: null,
      skeletonIsVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      trainingReceivedData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      trainingReceived: [],
      docModalData: null,
      shareModalParams: {
        type: 2,
        title: this.$i18n.t('global.sendByEmail'),
        file: {
          name: 'nombre-del-archivo.pdf'
        }
      },
      toastParams: null
    }
  },
  mounted () {
    if (this.getTrainingReceived !== null) {
      this.trainingReceivedData = this.getTrainingReceived
      this.printCards()
    }
    if (this.getTrainingReceivedStatus === 'stopped') this.$store.dispatch('fetchApi')
    if (this.getTrainingReceivedStatus === 'pending') this.skeletonIsVisible = true
  },
  methods: {
    openModal: function (file) {
      this.shareModalParams.file = file
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    sendFile: function (recipient) {
      // console.log()
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        text: `El archivo se ha enviado correctamente a ${recipient.name}`,
        time: 5000
      }
    },
    openPreview: function (item) {
      this.docModalData = item
      this.isToastVisible = false
      if (!item.uuid || item.uuid === 'xxxx') {
        this.isToastVisible = true
        this.toastParams = {
          text: this.$t('documentModal.error'),
          type: 'danger',
          time: 5000
        }
      } else {
        this.isDocModalVisible = true
      }
    },
    closeDocModal: function (error) {
      console.log(error)
      if (error) {
        this.isToastVisible = true
        this.toastParams = {
          text: this.$t('documentModal.error'),
          type: 'danger',
          time: 5000
        }
      }
      this.isDocModalVisible = false
    },
    printCards () {
      // console.log()
      this.trainingReceivedData.trainingOnlineList.forEach(element => {
        if (element.uuid === 'xxxx') element.uuid = null
        element.label = 'On-line'
        element.type = 7
        this.trainingReceived.push(element)
      })
      this.trainingReceivedData.trainingPresentialList.forEach(element => {
        if (element.uuid === 'xxxx') element.uuid = null
        element.label = 'Presencial'
        element.type = 7
        this.trainingReceived.push(element)
      })
      this.trainingReceived = this.sortedMedicalExaminations()
    },
    sortedMedicalExaminations: function () {
      this.trainingReceived.sort((a, b) => {
        return new Date(b.id.date) - new Date(a.id.date)
      })
      return this.trainingReceived
    }
  },
  computed: {
    ...mapGetters(['getTrainingReceived', 'getTrainingReceivedStatus'])
  },
  watch: {
    getTrainingReceivedStatus: {
      handler (newVal, oldVal) {
        if (newVal === 'success') {
          this.skeletonIsVisible = false
          this.trainingReceivedData = this.getTrainingReceived
          this.printCards()
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.skeletonIsVisible = true
        }
      },
      deep: true
    }
  }
}
</script>
