import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    psa: [],
    isLoading: false
  }
}

export default {
  state: {
    // data: [79.4, 90, 69.7, 82.3],
    // xLabel: [2019, 2020, 2021, 2222],
    // fakeData: 33,
    psa: [],
    isLoading: false
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    // loadData: (state, payload) => (state.psa = payload)
    loadDataPsa (state, payload) {
      // console.log('loadData', payload)
      state.psa = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDatePsa (state) { // fecha del último dato con valor
      const itemsWithValue = state.psa.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataPsa (state) { // valor del último dato recogido
      const itemsWithValue = state.psa.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    filteredDataPsa (state) { // solo datos
      const objectValues = state.psa.map(function (el) {
        return el.value
      })
      return objectValues
    },
    filteredXlabelsPsa (state) { // solo label del eje Y
      const objectKeys = state.psa.map(function (el) {
        return el.year
      })
      return objectKeys
    }
  },
  actions: {
    async fetchPsa ({ commit, state }) { // (commit, state, kpi)
      // console.log('FETCH DESDE STORE Psa')
      // commit('loadDataPsa', state.fakePsa) // datos de pruebas
      if (state.psa.length === 0) {
        state.isLoading = true
        kpiService
          .getPsa()
          .then((response) => {
            // console.log('DATA', response.data)
            commit('loadDataPsa', response.data)
            // commit('loadDataPsa', state.fakePsa) // datos de pruebas
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 1')
            state.isLoading = false
          })
      }
    }
  }
}
