<template>
    <div class="skeleton-chart">
      <div class="skeleton-chart__top"></div>
      <div class="skeleton-chart__body"></div>
    </div>
</template>
<script>
export default {
  name: 'SkeletonChart'
}
</script>

<style lang="scss" scoped>

.skeleton-chart {
  display: flex;
    flex-direction: column;
    width: 100%;
    // margin-top: 20px;
    &__top, &__body {
      margin: 16px 20px; // 16px 32px;
      position: relative;
      background-color: var(--black-10-color);
      border-radius: 4px;
      margin-bottom: 15px;
      &:before {
          position: absolute;
          content: "";
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          background: linear-gradient(-60deg, transparent 0%, transparent 40%, rgba(255, 255, 255, 0.4) 50%, transparent 60%, transparent 100%);
          background-size: 250% 100%;
          animation: gradientCard 2s ease-in-out infinite;
      }
      // &:last-child {
      //     height: 50px;
      //     margin-top: 20px;
      //     max-width: 40%;
      // }
    }
    &__top {
      height: 162px;
    }
    &__body {
      height: 224px;
    }
}

@keyframes gradientCard {
    0% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

</style>
