// A constants file will would be loaded and referened in every component
// whereas a plugin is loaded only once and available through the whole app

const CONSTANTS = {
  install (Vue, options) {
    // Alphabetically ordered
    Vue.CONSTANTS = {
      MEDIA_QUERIES: {
        MOBILE: 640,
        TABLET: 769,
        DESKTOP: 1200
      },
      CONSENT_FORM: {
        QUESTION_TYPE: {
          CHECKBOX: 'CHECKBOX',
          CHECKBOX_BOOLEAN: 'CHECKBOX_BOOLEAN',
          CHECKBOX_MULTIPLE: 'CHECKBOX_MULTIPLE',
          RADIO: 'RADIO',
          RADIO_BOOLEAN: 'RADIO_BOOLEAN',
          RADIO_BOOLEAN_QUESTION: 'RADIO_BOOLEAN_QUESTION',
          RADIO_MULTIPLE: 'RADIO_MULTIPLE',
          TEXT: 'TEXT'
        }
      },
      DEVICES: {
        MOBILE: 1,
        TABLET: 2,
        DESKTOP: 3
      },
      GLOBAL_STATES: {
        SUCCESS: 1,
        WARNING: 2,
        DANGER: 3
      },
      STRENGTH_TYPE: {
        VERY_WEAK: 1,
        WEAK: 2,
        REGULAR: 3,
        ALMOST_VALID: 4,
        VALID: 5
      },
      RECEIVE_CODE_BY: {
        EMAIL: 1,
        SMS: 2
      },
      REGISTRATION_PROCESS: {
        IDENTIFICATION: 1,
        CONFIRM: 2,
        FINISHED: 3
      },
      ACTIVITY_STATE: {
        CURRENT: 1,
        EXPIRE_SOON: 2,
        EXPIRED: 3
      },
      CARD_TYPE: {
        PHYSICAL_EXAMINATION: 1,
        FITNESS_SUMMARY: 2,
        OTHER_DOCUMENTATION: 3,
        ANALYTICS: 4,
        PERFORMED_TESTS: 5,
        APPOINMENTS: 6,
        TRAINING: 7,
        WORKPLACE: 8,
        EMPLOYMENT_HISTORY: 9,
        RISKS_EVALUATION: 10,
        UPLOADED_DOCUMENTATION: 11,
        MEDICAL_RESIGNATION: 12,
        ICPT: 13
      },
      MODAL_TYPE: {
        CONFIRMATION: 1,
        SHARE: 2,
        UPLOAD_DOC: 3,
        MEDICAL_RESIGNATION: 4,
        SHARE_RESIGNATIONS: 5,
        SHARE_DOC_UPLOAD: 6,
        SHARE_ICPT: 7,
        DOWNLOAD: 8
      },
      DOC_TYPE: {
        PHYSICAL_EXAMINATION: 3,
        FITNESS_SUMMARY: 3,
        OTHER_DOCUMENTATION: 3,
        ANALYTICS: 3,
        PERFORMED_TESTS: 3,
        APPOINMENTS: 3,
        TRAINING: 3,
        WORKPLACE: 3,
        EMPLOYMENT_HISTORY: 3,
        RISKS_EVALUATION: 3
      },
      NEW_APPOINMENT_GROUP: {
        COMPANY: 1,
        WORKCENTER: 2,
        MOTIVE: 3,
        MEDICAL_CENTER: 4,
        DATE: 5,
        CONFIRM: 6
      },
      PHYSICAL_EXAMINATION_TYPE: {
        HEALTH_SURVEILLANCE: 1,
        VIP: 2
      }
    }
  }
}

export default CONSTANTS
