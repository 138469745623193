<template>
  <!-- <Transition name="slide-fade"> -->
    <div
      v-show="visible"
     :class="['coockies-bar', deviceStyle]"> <!-- transitionSlide -->
      <span class="icon-cookie" />
      <div class="cookies-text">
        <h4>{{ $t('legal.cookiesBarTitle') }}</h4>
        <p>
          {{ $t('legal.cookiesBarText') }}
          <span
            class="clicked"
            @click="navigateTo('/cookies-policy', $t('footer.cookiesPolicy'))">
            {{ $t('legal.cookiesBarLink') }}
          </span>
        </p>
      </div>
      <Toggle
        v-if="visiblePreferences"
        :isChecked="toggleOption"
        :title="$t('footer.statistics')"
        @toggleEvent="toggleEvent"/>
      <Button :label="$t('legal.cookiesConfimation')"
              classes="button button--cta-secondary"
              @click.native="acceptCookies" />
      <span
        v-if="!visiblePreferences"
        class="clicked"
        @click="visibleToogle">
        {{ $t('global.preferences') }}
      </span>

    </div>
  <!-- </Transition> -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Button from '@/components/ui/Button.vue'
import Toggle from '@/components/ui/Toggle.vue'

export default {
  name: 'CookiesBar',
  components: { Button, Toggle },
  props: ['visible'],
  // props: {
  //   visible: {
  //     type: boolean,
  //     required: true,
  //     default: false
  //   }
  // },
  data () {
    return {
      // visible: true, // visible puede pasarse por prop desde app o que lea directamente la store
      DEVICES: Vue.CONSTANTS.DEVICES,
      visiblePreferences: false,
      toggleOption: true
    }
  },
  computed: {
    ...mapGetters(['getCurrentDevice']),
    deviceStyle () {
      return this.getCurrentDevice === this.DEVICES.MOBILE ? 'mobile-coockieBar' : 'desktop-coockieBar'
    }
    // transitionSlide () {
    //   return this.visible ? 'cookies-up' : 'cookies-down'
    // }
  },
  // mounted () {
  //   this.menu = [...this.getMenu]
  //   this.menu.shift()
  // },
  methods: {
    visibleToogle () {
      this.visiblePreferences = !this.visiblePreferences
    },
    toggleEvent () {
      this.toggleOption = !this.toggleOption
      this.visiblePreferences = true
    },
    acceptCookies () {
      const cookiesResult = {
        accepted: this.toggleOption
      }
      this.$store.commit('setCookiesAccepted', cookiesResult)
      this.$store.dispatch('showCookiesBar')
      this.$emit('onAcceptCookies', this.toggleOption)
    },
    navigateTo: function (path, label) {
      // console.log()
      if (this.$route.path === '/login' || this.$route.path === '/register' || this.$route.path === '/reset-password' || this.$route.path.startsWith('/restore-password')) {
        // this.$emit('openCookiesText')
        this.$store.commit('setCookiesPreferencesOpened', true)
      } else {
        this.$store.commit('setParamSection', { label: label })
        this.$router.push(path)
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<style lang="scss">
  .coockies-bar {
    position: fixed;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 35px 0;
    background-color: var(--black-10-color);
    bottom: 0;
    box-shadow: 0px -5px 8px 0px rgb(44 44 44 / 24%);
    transition: all 0.5s ease;
    & .cookies-text {
      width: 500px;
      & h4 {
        color: var(--secondary-color);
      }
      & span {
        color: var(--link-color);
      }
    }
    & .icon-cookie {
      align-self: flex-start;
      &::before {
        content: '';
        background-image:url('../../assets/images/icon-cookies.svg');
        position:relative;
        height: 50px;
        width: 50px;
        display: block;
      }
    }
    & .clicked {
      cursor: pointer;
    }
    &.mobile-coockieBar{
      margin-bottom: 45px;
      & .cookies-text {
        padding: 0 10px;
      }
    }
  }

// .slide-fade-enter-active {
//   transition: all 0.5s linear;
// }

// .slide-fade-leave-active {
//   transition: all 0.5s linear;
// }

// .slide-fade-enter-from,
// .slide-fade-leave-to {
//   transform: translateY(200px);
//   // opacity: 0;
// }

// .cookies-up {
//   animation: sliderup .3s normal linear;
// }

// .cookies-down {
//   animation: sliderup .3s reverse linear;
// }

// @keyframes sliderup {
//   0% {transform: translateY(100%);}
//   100% {transform: translateY(0%);}
// }
// @keyframes sliderdown {
//   0% {transform: translateY(100%);}
//   100% {transform: translateY(0%);}
// }

</style>
