<template>
  <div class="section__container section__container--one-column" v-if="getParamSection">
    <div class="section__content" v-if="getParamSection">
        <DigitalHealth />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
// import userService from '@/services/userService.js'
import DigitalHealth from '@/components/health-monitoring/DigitalHealth.vue'

export default {
  name: 'MyDigitalHealth',
  components: { DigitalHealth },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      windowWidth: window.innerWidth,
      menu: []
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu', 'getCurrentDevice', 'getParamSection', 'getRecognitionData']),
    twoColumnsView () {
      return this.getParamSection.component === 'DigitalHealth' || this.getParamSection.component === 'HealthyGuides'
    }
  },
  created () {
    window.addEventListener('resize', this.onResize)
    // console.log()
  },
  mounted () {
    // console.log()
    // console.log()
    // console.log()
    this.menu = [...this.getMenu]
    this.menu[1].selected = true
    this.$store.commit('setParamSection', this.menu[1])
    this.$store.commit('setMenu', this.menu)
  }
}
</script>
