<template>
    <div class="tps-warning">
      <div class="tps-warning__container">
        <p class="tps-warning__title">{{ $t("login.warningTitle") }}</p>
        <p v-html="$t('login.warningContent')"></p>
        <a class="tps-warning__link" @click="openTPSModal()">{{ $t("login.warningLink") }}</a>
      </div>
    </div>
</template>

<script>
export default {
  components: {},
  name: 'TPSWarning',
  props: [],
  data () {
    return {
    }
  },
  methods: {
    openTPSModal () {
      this.$store.commit('setTPSModalVisible', true)
    }
  },
  mounted () {
  }
}
</script>
