import axios from 'axios'
export default {
  getRecentActivity () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/activities/recent`
    )
  },
  findWorkPlaceById () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/job/active`
    )
  },
  findEmploymentHistoryById () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/job/all`
    )
  }
}
