<template>
    <div class="">
      <DocModal :data="docModalData" @onClose="closeDocModal()" v-if="isDocModalVisible" />
      <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" />
      <h1 class="subsection__title">{{ $t('menu.generalInfo.myWorkPlace') }}</h1>
      <SkeletonCard v-if="skeletonIsVisible"/>
      <CardList :data="myWorkPlace" @onClickPreview="openPreview($event)" @onClickShareLink="openModal($event)" />
    </div>
</template>
<script>
import CardList from '@/components/commons/CardList.vue'
import DocModal from '@/components/modals/DocModal.vue'
import Modal from '@/components/modals/Modal.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'MyWorkPlace',
  components: { DocModal, CardList, Modal, SkeletonCard },
  mounted () {
    if (this.getEmploymentHistory !== null) {
      this.employmentHistoryData = this.getEmploymentHistory
      this.myWorkPlace = this.filterData()
    }
    if (this.getEmploymentHistoryStatus === 'stopped') this.$store.dispatch('fetchApi')
    if (this.getEmploymentHistoryStatus === 'pending') this.skeletonIsVisible = true
  },
  data () {
    return {
      employmentHistoryData: [],
      skeletonIsVisible: false,
      workPlaceData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      myWorkPlace: [],
      docModalData: null,
      shareModalParams: {
        type: 2,
        title: this.$i18n.t('global.sendByEmail'),
        file: null
      }
    }
  },
  methods: {
    openModal: function (file) {
      this.shareModalParams.file = file
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    openPreview: function (item) {
      this.docModalData = item
      if (!item.uuid || item.uuid === 'xxxx') {
        this.isToastVisible = true
        this.toastParams = {
          text: this.$t('documentModal.error'),
          type: 'danger',
          time: 5000
        }
      } else {
        this.isDocModalVisible = true
      }
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    printCards () {
      this.workPlaceData.forEach(element => {
        if (element.type === 8) {
          this.myWorkPlace.push(element)
        }
      })
      // console.log()
    },
    filterData () {
      let arr = []
      if (this.employmentHistoryData.length > 0) {
        arr = this.employmentHistoryData.filter(workPlace => {
          if (workPlace.laborRelation.endDate === null) {
            workPlace.type = 8
            return workPlace
          }
        })
      }
      return arr
    }
  },
  computed: {
    ...mapGetters(['getEmploymentHistoryStatus', 'getEmploymentHistory'])
  },
  watch: {
    getEmploymentHistoryStatus: {
      handler (newVal, oldVal) {
        if (newVal === 'success') {
          this.skeletonIsVisible = false
          this.employmentHistoryData = this.getEmploymentHistory
          this.myWorkPlace = this.filterData()
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.skeletonIsVisible = true
        }
      },
      deep: true
    }
  }
}
</script>
