<template>
  <div class="section__container" v-if="getParamSection" :class="twoColumnsView ? 'section__container--two-columns' : 'section__container--three-columns'">
    <div class="">
      <AsideMenu :data="getMenu[2].items" @onChangeMenu="loadSection($event)" />
      <Banners class="margin-top-20" v-if="getCurrentDevice === DEVICES.TABLET && !twoColumnsView" />
    </div>
    <div class="section__content" v-if="getParamSection">
        <component :is="getParamSection.component" />
    </div>
    <div v-if="!twoColumnsView">
      <Banners v-if="getCurrentDevice !== DEVICES.TABLET" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AsideMenu from '@/components/commons/AsideMenu.vue'
import DigitalHealth from '@/components/health-monitoring/DigitalHealth.vue'
import MedicalExaminations from '@/components/health-monitoring/MedicalExaminations.vue'
import MedicalResignations from '@/components/health-monitoring/MedicalResignations.vue'
import ProficiencyReports from '@/components/health-monitoring/ProficiencyReports.vue'
import Analytical from '@/components/health-monitoring/Analytical.vue'
import OtherTests from '@/components/health-monitoring/OtherTests.vue'
import OtherDocumentation from '@/components/health-monitoring/OtherDocumentation.vue'
import HealthyGuides from '@/components/health-monitoring/HealthyGuides.vue'
import DocumentsUpload from '@/components/health-monitoring/DocumentsUpload.vue'
import Banners from '@/components/commons/Banners.vue'
export default {
  name: 'HealthMonitoring',
  components: { AsideMenu, Banners, DigitalHealth, MedicalExaminations, MedicalResignations, ProficiencyReports, Analytical, OtherTests, OtherDocumentation, HealthyGuides, DocumentsUpload },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      windowWidth: window.innerWidth,
      menu: []
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu', 'getCurrentDevice', 'getParamSection']),
    twoColumnsView () {
      return this.getParamSection.component === 'DigitalHealth' || this.getParamSection.component === 'HealthyGuides'
    }
  },
  created () {
    window.addEventListener('resize', this.onResize)
  },
  mounted () {
    this.menu = [...this.getMenu]
    if (!this.getParamSection) {
      this.$store.commit('setParamSection', this.getMenu[2].items[0])
      this.menu[2].items[0].selected = true
      this.menu[2].opened = true
      this.$store.commit('setMenu', this.menu)
    }
    this.loadSection(this.getParamSection)
  },
  methods: {
    loadSection: function (section) {
      this.$store.commit('setParamSection', section)
      // console.log('loadSection: section', section)
      // console.log()
      this.menu = [...this.getMenu]
      if (!section.selected) {
        this.menu[2].items.forEach(element => {
          element.selected = false
        })
        section.selected = true
        this.menu[2].opened = true
      }
      this.$store.commit('setMenu', this.menu)
    }
  }
}
</script>
