<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <UpDocModal :data="docModalData" :docTitle="docTitle" :isLoading="isLoading" @onClose="closeDocModal()" v-if="isDocModalVisible" />
    <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" @onSendFile="sendFile($event)" />
    <h1>{{ $t('menu.technicalPrevention.riskEvaluation') }}</h1>
    <SkeletonCard v-if="skeletonIsVisible"/>
    <NoResults :type="CARD_TYPE.PHYSICAL_EXAMINATION" v-if="dataRisks.length === 0 && !skeletonIsVisible" />
    <div class="card-filter__title" v-if="!skeletonIsVisible && dataRisks.length !== 0">
      {{ $t('risksEvaluation.chooseClient') }}
    </div>
    <CardFilter :data="laborRelations" v-if="!skeletonIsVisible"/>
    <CardList :data="dataFiltered" v-if="dataRisks.length > 0"
      @onClickPreview="openPreview($event)"
      @onClickDownload="openDownload($event)"
      @onClickShareLink="openShareModal($event)"
      />
   </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CardList from '@/components/commons/CardList.vue'
import CardFilter from '@/components/commons/CardFilter.vue'
import UpDocModal from '@/components/modals/UpDocModal.vue'
import Modal from '@/components/modals/Modal.vue'
import Toast from '@/components/toasts/Toast.vue'
import NoResults from '@/components/commons/NoResults.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
import preventionService from '@/services/preventionService.js'
import * as _files from '@/_helpers/files.helper.js'
// import documentService from '@/services/documentService.js'
export default {
  name: 'RiskEvaluation',
  components: { CardFilter, UpDocModal, CardList, Modal, NoResults, SkeletonCard, Toast },
  data () {
    return {
      laborRelations: [],
      dataRisks: [],
      skeletonIsVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      risksEvaluation: null,
      isDocModalVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      recentActivities: [],
      docModalData: null,
      shareModalParams: {
        type: 2,
        title: this.$i18n.t('global.sendByEmail'),
        file: {
          name: 'nombre-del-archivo.pdf'
        }
      },
      toastParams: null
    }
  },
  mounted () {
    this.getRisksEvaluation()
  },
  methods: {
    openDownload: function (item) {
      const x = this.dataRisks.indexOf(item)
      if (x > -1) {
        this.$set(this.dataRisks[x], 'isLoading', true)
        // this.dataRisks[x].isLoading = true
        console.log(this.dataRisks[x])
      }
      preventionService.getDocument(item.uuid, '800')
        .then((response) => {
          // console.log()
          _files.downloadPDF(response.data.fichero, response.data.nombreFichero)
        })
        .catch(() => {
          // console.log()
          this.isToastVisible = true
          this.toastParams = {
            type: 'danger',
            text: this.$t('errorMessage.generic'),
            time: 5000
          }
          setTimeout(() => {
            this.isToastVisible = false
          }, 4000)
        })
        .finally(() => {
          this.dataRisks[x].isLoading = false
          console.log(this.dataRisks[x])
        })
    },
    openShareModal: function (item) {
      const data = {
        uuid: item.uuid,
        documentType: '800'
      }
      this.shareModalParams.file = data
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    sendFile: function (recipient) {
      // console.log()
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        text: `El archivo se ha enviado correctamente a ${recipient.name}`,
        time: 5000
      }
    },
    openPreview: function (item) {
      this.docModalData = null
      this.docTitle = null
      this.isDocModalVisible = true
      this.isLoading = true
      preventionService.getDocument(item.uuid, '800')
        .then((response) => {
          this.docModalData = response.data.fichero
          this.docTitle = response.data.nombreFichero
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    getRisksEvaluation () {
      this.skeletonIsVisible = true
      preventionService.getRisksEvaluation()
        .then((response) => {
          // console.log()
          const arr = []
          const laborRelations = []
          response.data.forEach(element => {
            if (element.risksEvaluationList.length !== 0) {
              element.risksEvaluationList.forEach(riskEvaluation => {
                riskEvaluation.type = 10
                riskEvaluation.title = riskEvaluation.titleType
                riskEvaluation.workplaceName = element.laborRelation.workplaceName
                riskEvaluation.workplaceId = element.laborRelation.workplaceId
                riskEvaluation.clientName = element.laborRelation.clientName
                riskEvaluation.clientId = element.laborRelation.clientId
                riskEvaluation.jobName = element.laborRelation.jobName
                riskEvaluation.isLoading = false
                arr.push(riskEvaluation)
              })

              const x = laborRelations.find(x => x.clientId === element.laborRelation.clientId)
              console.log(x, element.laborRelation.clientId)
              if (!x) {
                laborRelations.push({
                  workplaceId: element.laborRelation.workplaceId,
                  workplaceName: element.laborRelation.workplaceName,
                  clientId: element.laborRelation.clientId,
                  clientName: element.laborRelation.clientName,
                  selected: false
                })
              }
            }
          })
          console.log(laborRelations)
          // laborRelations[0].selected = true
          this.laborRelations = [...laborRelations]
          this.dataRisks = [...arr]
          this.laborRelations[0].selected = true
        })
        .catch(() => {
        })
        .finally(() => {
          this.skeletonIsVisible = false
        })
    }
  },
  computed: {
    ...mapGetters(['getRecognitionData']),
    dataFiltered () {
      const selected = this.laborRelations.find(x => x.selected === true)
      if (!selected) return
      return this.dataRisks.filter(x => x.clientId === selected.clientId)
    }
  }
}
</script>
