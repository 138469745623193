export default {
  state: {
    token: 1,
    appointments: null,
    clientModules: null,
    modules: {
      appointments: 0
    },
    city: null
  },
  mutations: {
    setUserToken (state, token) {
      state.token = token
    },
    setAppointments (state, appointments) {
      state.appointments = appointments
    },
    setModuleAppointment (state, appointmentValue) {
      state.modules.appointments = appointmentValue
    },
    setClientModules (state, clientModules) {
      state.clientModules = clientModules
    }
  },
  // Must start with 'get'
  getters: {
    getUserToken (state) {
      return state.token
    },
    getAppointments (state) {
      return state.appointments
    },
    getModules (state) {
      return state.modules
    },
    getClientModules (state) {
      return state.clientModules
    }
  },
  actions: {
  }
}
