/* eslint-disable */

// TODO Crar el action para mostrar/ocultar la cookieBar.
import userService from '@/services/userService.js'
import * as _crypto from '../_helpers/crypto.helper'

export default {
  state: {
    serviceTerms: null,
    privacyTerms: null,
    cookiesTerms: null,
    cookiesBarVisible: null,
    cookiesPreferencesOpened: false,
  },
  mutations: {
    setServiceTerms(state, serviceTerms) {
      state.serviceTerms = serviceTerms
    },
    setPrivacyTerms(state, privacyTerms) {
      state.privacyTerms = privacyTerms
    },
    setCookiesTerms(state, cookiesTerms) {
      state.cookiesTerms = cookiesTerms
    },
    // cookies accepted will be a boolean
    setCookiesAccepted(state, cookiesAccepted) {
      // console.log()
      localStorage.setItem('cookiesAccepted', _crypto.encrypt(JSON.stringify(cookiesAccepted)))
      state.cookiesAccepted = cookiesAccepted
    },
    setCookiesBarVisible(state, cookiesBarVisible) {
      // console.log('antes', state.cookiesBarVisible)
      state.cookiesBarVisible = !state.cookiesBarVisible
      // console.log('después', state.cookiesBarVisible)
    },
    setCookiesPreferencesOpened(state, cookiesPreferencesOpened) {
      state.cookiesPreferencesOpened = cookiesPreferencesOpened
    }
  },
  getters: {
    getServiceTerms(state) {
      return state.serviceTerms
    },
    getPrivacyTerms(state) {
      return state.privacyTerms
    },
    getCookiesTerms(state) {
      return state.cookiesTerms
    },
    getCookiesAccepted(state) {
      return state.cookiesAccepted
    },
    getCookiesBarVisible(state) {
      return state.cookiesBarVisible
    },
    getCookiesPreferencesOpened(state) {
      return state.cookiesPreferencesOpened
    }
  },
  actions: {
    showCookiesBar({ commit }) {
      // console.log()
      commit('setCookiesBarVisible')
    },
    async fetchTerms({ commit }) {
      // Terms of service
      userService.getTerms('TERMS')
        .then((response) => {
          commit('setServiceTerms', response.data.translations)
        })
        .catch(() => {
          commit('setServiceTerms', null)
        })
        .finally(() => {
        })
      // Policy of privacy
      userService.getTerms('PRIVACY')
        .then((response) => {
          commit('setPrivacyTerms', response.data.translations)
        })
        .catch(() => {
          commit('setPrivacyTerms', null)
        })
        .finally(() => {
        })
      // Policy of cookies
      userService.getTerms('COOKIES')
        .then((response) => {
          commit('setCookiesTerms', response.data.translations)
        })
        .catch(() => {
          commit('setCookiesTerms', null)
        })
        .finally(() => {
        })
    }
  }
}
