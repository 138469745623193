import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    glucose: [],
    isLoading: false,
    state: 'stopped'
  }
}

export default {
  state: {
    fakeGlucose:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 0
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-09T10:54:30.393190',
          year: '2023',
          value: 0
        }
      ],
    glucose: [],
    isLoading: false,
    state: 'stopped'
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    // loadData: (state, payload) => (state.glucose = payload)
    loadDataGlucose (state, payload) {
      // console.log('loadData', payload)
      state.glucose = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateGlucose (state) { // fecha del último dato con valor
      const itemsWithValue = state.glucose.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataGlucose (state) { // valor del último dato recogido
      const itemsWithValue = state.glucose.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    filteredDataGlucose (state) { // solo datos
      const objectValues = state.glucose.map(function (el) {
        return el.value
      })
      // console.log('filteredDataGlucose', objectValues)
      return objectValues
    },
    filteredXlabelsGlucose (state) { // solo label del eje Y
      const objectKeys = state.glucose.map(function (el) {
        return el.year
      })
      return objectKeys
    },
    getGlucoseState (state) {
      return state.state
    }
  },
  actions: {
    fetchGlucose ({ commit, state }) { // (commit, state, kpi)
      // console.log('FETCH DESDE STORE glucose')
      // commit('loadDataGlucose', state.fakeGlucose) // dato de prueba
      if (state.glucose.length === 0) {
        state.state = 'pending'
        state.isLoading = true
        kpiService.getGlucose()
          .then((response) => {
            // console.log('DATA glucose', response.data)
            commit('loadDataGlucose', response.data)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 2')
            state.isLoading = false
            state.state = 'finished'
          })
      }
    }
  }
}
