import axios from 'axios'
export default {
  getDocument (uuid, type) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/documents/${uuid}?type=${type}`
    )
  },
  shareDocument (uuid, type, data) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/documents/shared?uuid=${uuid}&type=${type}`,
      { filename: data.filename, toEmail: data.toEmail, toName: data.toName }
    )
  }
}
