<template>
    <div class="select__container" v-click-outside="clickOutside">
        <div v-if="disabled" class="select__disabled" >
            <span class="select__button__placeholder" v-if="!itemSelected">-</span>
            <span class="select__button__label" v-if="itemSelected">{{itemSelected.name || itemSelected[itemLabel]}}</span>
        </div>
        <div v-if="!disabled" class="select__button" @click="openOptions()">
            <span class="select__button__placeholder" v-if="!itemSelected">{{getPlaceholder}}</span>
            <span class="select__button__label" v-if="itemSelected">{{itemSelected.name || itemSelected[itemLabel]}}</span>
            <span class="select__button__arrow icon icon-arrow-down" :class="true"></span>
        </div>
        <div class="select__options scroll scroll2" :class="{'select__options--visible' : optionsVisible}">
            <div class="select__option" :class="{'select__option--selected' : option.selected}"  v-for="(option, index) in data" :key="index" @click="selectOption(option)">
                <span class="select__option__label">{{option.name || option[itemLabel]}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'select',
  props: ['data', 'itemSelected', 'itemLabel', 'right', 'placeholder', 'disabled'],
  data () {
    return {
      optionsVisible: false
    }
  },
  computed: {
    getPlaceholder () {
      return this.placeholder || this.$t('global.select')
    }
  },
  methods: {
    openOptions: function () {
      this.optionsVisible = true
    },
    selectOption: function (option) {
      // console.log()
      this.$emit('onSelect', option)
      this.clickOutside()
    },
    clickOutside: function () {
      this.optionsVisible = false
    }
  }
}
</script>
