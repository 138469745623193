<template>
  <!--
    TODO El grupo de etiqueta + radiobuttons debe de estar contenido en un <fieldset>
    y la etiqueta en sí debe de ser un <legend>
     -->
  <div :class="[numberLabel ? 'form-radio-group boxing-style' : 'form-radio-group']">
    <!-- :class="[numberLabel ? 'form-radio-group boxing-style' : 'form-radio-group']" -->
    <div class="form-radio-group__header">
      <div
        v-if="numberLabel"
        class="card__icon-container"
      >
        <span class="card__icon">{{ numberLabel }}</span>
        <!-- <span class="card__icon icon icon-info-toast" /> -->
      </div>
      <p class="form-radio">
        {{ $tc( textLabel ) }}
        <span
          v-if="requiredInput"
          :title="requiredTitle"
          class="required-dot"
        >●</span>
      </p>
    </div>
    <div class="radios-container">
      <div
        v-for="(radio, index) in radioOptions"
        :key="index+textLabel"
      >
        <input
          :id="textLabel+radio.label"
          :key="index+textLabel"
          :value="radio.value"
          v-model="inputVal"
          type="radio"
          :name="groupName"
          @input="inputHandler"
        ><!-- @input.stop="inputHandler" -->
        <label
          :key="index+textLabel+1"
          :for="textLabel+radio.label"
          class="form-radio__label"
        >{{ $tc( radio.label ) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RadioButtonsLabel',
  props: {
    numberLabel: {
      type: Number,
      required: false,
      default: null
    },
    textLabel: {
      type: String,
      required: false,
      default: null
    },
    value: {
      // eslint-disable-next-line
      type: String | Number,
      required: false,
      default: null
    },
    groupName: {
      type: String,
      required: false,
      default: ''
    },
    formElement: {
      type: String,
      required: false,
      default: ''
    },
    radioOptions: {
      type: Array,
      required: false,
      default: () => [
        {
          label: 'form.no',
          forId: 'no',
          value: false
        },
        {
          label: 'form.yes',
          forId: 'yes',
          value: true
        }
      ]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    requiredInput: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    requiredTitle () {
      return this.$t('form.validation.required')
    },
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        console.log(this.inputVal)
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    console.log('M InputLabel')
  },
  methods: {
    inputHandler (event) {
      // console.log('event', event)
      // console.log('event.target', event.target)
      // console.log('event.target.value', event.target.value)
      console.log('entra aqui', event.target.value)
      // this.$emit('input', event.target.value)
    }
    // inputHandler (event) {
    //   console.log(event)
    //   if (event && event.target && event.target.value) {
    //     this.$emit('input', event.target.valueAsNumber || event.target.value)
    //   } else {
    //     this.$emit('input', null)
    //   }
    // }
  }
}
</script>

<style lang="scss">

 .form-radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 10px 0;
  & .form-radio-group__header {
    // width: 200px;
    display: flex;
    align-items: center;
    line-height: 1.5;
    color: var(--black-color);
    min-height: 40px;
    display: flex;
    align-items: center;
    & .form-radio {
      font-style: normal;
      margin: 0;
    }
  }
  & .radios-container {
    display: grid;
    min-width: max-content;
    align-items: baseline;
    column-gap: 50px;
    row-gap: 20px;
    grid-template-columns: auto auto;
    & .form-radio__label {
      margin-right: 15px;
      font-style: normal;
      font-weight: 400;
      padding: 5px 15px;
      cursor: pointer;
    }
    input[type="radio"] {
      transform: scale(1.5);
      top: 2px;
      position: relative;
      filter: saturate(30%);
    }
  }
  &.boxing-style {
    justify-content: space-between;
    gap: 15px;
    margin-top: 26px;
    padding: 24px 24px 24px 0px;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    & .form-radio-group__header {
      & .card__icon-container {
        width: 26px;
        height: 26px;
        min-height: 26px;
        min-width: 26px;
        background-color: #00425c;
        & .card__icon {
          color: #fff;
          font-size: 14px;
          font-weight: 800;
          margin-top: 3px;
        }
      }
      & .form-radio {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
    & .radios-container {
      grid-auto-flow: column;
      & .form-radio__label {
        font-size: 16px;
        color: #666666;
        padding: 5px 15px;
      }
    }
  }
}

.required-dot {
    color: var(--primary-dark-color);
    line-height: 17px;
    margin: 0 5px;
    cursor: help;
}

@media (max-width: 800px) {
  .form-radio-group {
        align-items: flex-start !important;
  }
  .form-radio-group__header {
    align-items: flex-start !important;
    margin-top: 22px;
  }
  .radios-container {
    grid-template-columns: auto !important;
    row-gap: 10px !important;
  }
  .form-radio__label {
    display: inline-block !important;
  }
  .boxing-style {
    flex-direction: column;
    & .form-radio-group__header {
      width: 100%;
      & .card__icon-container {
        position: relative;
      }
      p {
        margin-top: 5px;
      }
    }
    & .radios-container {
          margin-left: 46px;
    }
  }

}
</style>
