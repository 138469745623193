import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import MyDigitalHealth from '@/views/my-digital-health/MyDigitalHealth.vue'
import HealthMonitoring from '@/views/health-monitoring/HealthMonitoring.vue'
import TechnicalPrevention from '@/views/technical-prevention/TechnicalPrevention.vue'
import MyProfile from '@/views/my-profile/MyProfile.vue'
import MyAppoinments from '@/views/my-appoinments/MyAppoinments.vue'
import Login from '@/views/welcome/Login'
import ResetPassword from '@/views/welcome/ResetPassword'
import RestorePassword from '@/views/welcome/RestorePassword'
import LegalAdvice from '@/views/legal/LegalAdvice'
import PrivacyPolicy from '@/views/legal/PrivacyPolicy'
import CookiesPolicy from '@/views/legal/CookiesPolicy'
import ConsentForm from '@/components/consent-form/ConsentForm'
import ChartTest from '@/views/chart-test/ChartTest'
import MaintenanceMode from '@/views/error/MaintenanceMode'
import * as store from '../store/index.js'
import * as userStore from '../store/userStore.js'
import * as _modules from '../_helpers/modules.helper.js'

Vue.use(VueRouter)

// URLs not found redirect to the root view
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: true, title: 'Home' }
  },
  {
    path: '/my-digital-health',
    name: 'MyDigitalHealth',
    component: MyDigitalHealth,
    meta: { auth: false, title: 'Información general' }
  },
  {
    path: '/health-monitoring',
    name: 'HealthMonitoring',
    component: HealthMonitoring,
    meta: { auth: false, title: 'Vigilancia de la salud' }
  },
  {
    path: '/technical-prevention',
    name: 'TechnicalPrevention',
    component: TechnicalPrevention,
    meta: { auth: false, title: 'Prevención técnica' }
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
    meta: { auth: false, title: 'Mi perfil' }
  },
  {
    path: '/my-appoinments',
    name: 'MyAppoinments',
    component: MyAppoinments,
    meta: { auth: false, title: 'Mis citas' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false, title: 'Inicia sesión' }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { auth: false, title: 'Resetea tu contraseña' }
  },
  {
    path: '/restore-password/:uri',
    name: 'RestorePassword',
    component: RestorePassword,
    meta: { auth: false, title: 'Resetea tu contraseña' }
  },
  {
    path: '/legal-advice',
    name: 'LegalAdvice',
    component: LegalAdvice,
    meta: { auth: false, title: 'Aviso legal' }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { auth: false, title: 'Política de privacidad' }
  },
  {
    path: '/cookies-policy',
    name: 'CookiesPolicy',
    component: CookiesPolicy,
    meta: { auth: false, title: 'Política de cookies' }
  },
  {
    path: '/consent-form',
    name: 'ConsentForm',
    component: ConsentForm,
    meta: { auth: false, title: 'Formulario de consentimiento' }
  },
  {
    path: '/chart-test',
    name: 'ChartTest',
    component: ChartTest,
    meta: { auth: false, title: 'Revisión de gráficas' }
  },
  {
    path: '/maintenance-mode',
    name: 'MaintenanceMode',
    component: MaintenanceMode,
    meta: { auth: false, title: 'Mantenimiento' }
  },
  {
    path: '/*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  devtool: 'source-map',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Preving'
  }
})
router.beforeEach((to, from, next) => {
  checkAppointments()
  checkClientModules()
  _modules.checkModules()

  const token = store.default.getters.getToken
  if (to.name !== 'Login' && to.name !== 'RestorePassword' && to.name !== 'ResetPassword' && to.name !== 'MaintenanceMode' && !sessionStorage.getItem('_appointments')) next({ name: 'Login' })
  if (to.name !== 'Login' && to.name !== 'RestorePassword' && to.name !== 'ResetPassword' && to.name !== 'MaintenanceMode' && !sessionStorage.getItem('_modules')) next({ name: 'Login' })
  if (to.name === 'Login') deleteSessionStorage()
  if (to.name !== 'Login' && to.name !== 'RestorePassword' && to.name !== 'ResetPassword' && to.name !== 'MaintenanceMode' && !token) next({ name: 'Login' })
  if (from.name === 'MaintenanceMode') next(false)
  if (!token && to.meta.auth) next({ name: 'Login' })
  else next()
})

const deleteSessionStorage = function () {
  sessionStorage.removeItem('currentUser')
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('_modules')
  sessionStorage.removeItem('_appointments')
}

const checkClientModules = function () {
  if (userStore.default.getters.getClientModules(userStore.default.state) === null) userStore.default.mutations.setClientModules(userStore.default.state, sessionStorage.getItem('_modules'))
}

const checkAppointments = function () {
  if (userStore.default.getters.getAppointments(userStore.default.state) === null) userStore.default.mutations.setAppointments(userStore.default.state, sessionStorage.getItem('_appointments'))
}

export default router
