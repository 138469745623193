import axios from 'axios'
export default {
  login (username, password) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/login`,
      { headers: { 'Access-Control-Allow-Origin': '*' }, username: username, password: password }
    )
  },
  loginTerms (username, password, type) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/login/terms?type=${type}`,
      { username: username, password: password }
    )
  },
  sendNifAndCode (nif, code) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/register/validate-code`,
      { headers: { 'Access-Control-Allow-Origin': '*' }, nif: nif, code: code }
    )
  },
  requestCode (userId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/users/${userId}`
    )
  },
  register (userId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/users/${userId}`
    )
  },
  sendEmailToResetPassword (username) {
    // console.log()
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/reset-password`,
      { headers: { 'Access-Control-Allow-Origin': '*' }, username: username }
    )
  },
  requestCodeByEmail (nif, type) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/register/generate-code/${type}`,
      { headers: { 'Access-Control-Allow-Origin': '*' }, nif: nif }
    )
  },
  verifyRegisterData (nif, code, email, phone, termsAccepted, privacityAccepted, comsAccepted) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/register/verify-data`,
      { headers: { 'Access-Control-Allow-Origin': '*' }, nif: nif, code: code, email: email, phone: phone, termsAccepted: termsAccepted, privacityAccepted: privacityAccepted, comsAccepted: comsAccepted }
    )
  },
  sendPassToReset (username, password, uri) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/restore-password/${uri}`,
      { headers: { 'Access-Control-Allow-Origin': '*' }, email: username, password: password }
    )
  },
  getRegisterData (uri) {
    // // console.log()
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/restore-password/${uri}`,
      { headers: { 'Access-Control-Allow-Origin': '*' } }
    )
  },
  // MY PROFILE SECTION
  updateWorkerData (data) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/profile-api/update`,
      { language: data.language, phone: data.phone, address: data.address, email: data.email, cityId: data.cityId, zipCode: data.zipCode, bornDate: data.bornDate }
    )
  },
  getProvinces () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/provinces`
    )
  },
  getCities (provinceId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/cities/${provinceId}`
    )
  },
  // COMPANIES REQUEST
  getCompanies () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/companies`
    )
  },
  getTerms (type) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/terms/active?type=${type}`
    )
  }
}
