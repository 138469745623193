<template>
    <div class="">
      <DocModal :data="docModalData" @onClose="closeDocModal()" v-if="isDocModalVisible" />
      <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" />
      <h1 class="subsection__title">{{ $t('menu.generalInfo.employmentHistory') }}</h1>
      <SkeletonCard v-if="skeletonIsVisible"/>
      <NoResults :type="CARD_TYPE.EMPLOYMENT_HISTORY" v-if="myEmploymentHistory.length === 0 && !skeletonIsVisible" />
      <CardList :data="myEmploymentHistory" @onClickPreview="openPreview($event)" @onClickShareLink="openModal($event)" />
    </div>
</template>
<script>
import Vue from 'vue'
import CardList from '@/components/commons/CardList.vue'
import NoResults from '@/components/commons/NoResults.vue'
import DocModal from '@/components/modals/DocModal.vue'
import Modal from '@/components/modals/Modal.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
import activitiesService from '@/services/activitiesService.js'
import { mapGetters } from 'vuex'
export default {
  name: 'EmploymentHistory',
  components: { DocModal, CardList, Modal, SkeletonCard, NoResults },
  mounted () {
    // this.getEmploymentHistory()
    if (this.getEmploymentHistory !== null) {
      this.employmentHistoryData = this.getEmploymentHistory
      this.printCards()
    }
    if (this.getEmploymentHistoryStatus === 'stopped') this.$store.dispatch('fetchApi')
    if (this.getEmploymentHistoryStatus === 'pending') this.skeletonIsVisible = true
  },
  data () {
    return {
      skeletonIsVisible: false,
      employmentHistoryData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      myEmploymentHistory: [],
      docModalData: null,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      shareModalParams: {
        type: 2,
        title: this.$i18n.t('global.sendByEmail'),
        file: null
      }
    }
  },
  methods: {
    openModal: function (file) {
      this.shareModalParams.file = file
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    openPreview: function (item) {
      this.docModalData = item
      if (!item.uuid || item.uuid === 'xxxx') {
        this.isToastVisible = true
        this.toastParams = {
          text: this.$t('documentModal.error'),
          type: 'danger',
          time: 5000
        }
      } else {
        this.isDocModalVisible = true
      }
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    getEmploymentHistoryTest () {
      this.skeletonIsVisible = true
      activitiesService.findEmploymentHistoryById()
        .then((response) => {
          // console.log()
          // this.$store.commit('setEmploymentHistoryData', response.data)
          this.employmentHistoryData = response.data
          this.printCards()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
          this.skeletonIsVisible = false
        })
    },
    printCards () {
      this.employmentHistoryData.forEach(element => {
        if (element.type === 9) {
          // console.log()
          if (element.laborRelation.endDate) {
            this.myEmploymentHistory.push(element)
          }
        }
      })
      // console.log()
    }
  },
  computed: {
    ...mapGetters(['getEmploymentHistoryStatus', 'getEmploymentHistory'])
  },
  watch: {
    getEmploymentHistoryStatus: {
      handler (newVal, oldVal) {
        if (newVal === 'success') {
          this.skeletonIsVisible = false
          this.employmentHistoryData = this.getEmploymentHistory
          this.printCards()
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.skeletonIsVisible = true
        }
      },
      deep: true
    }
  }
}
</script>
