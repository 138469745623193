import axios from 'axios'
export default {
  getResignation (resignationId) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/resignation?resignationId=${resignationId}`
    )
  },
  getResignations () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/resignation/all`
    )
  },
  uploadResignation (data, locale) {
    return axios.put(
      `${window.VUE_APP_BACKEND_HOST}/resignation`,
      { clientId: data.clientId, laborRelationId: data.laborRelationId, locale: locale }
    )
  },
  shareResignation (resignationId, name, email) {
    return axios.post(
      `${window.VUE_APP_BACKEND_HOST}/documents/shared/resignation?resignationId=${resignationId}&type=500`,
      { name: name, email: email }
    )
  }
}
