<template>
    <div class="qr-banner">
        <div v-if="isMobile" class="qr-banner__mobile">
            <p class="qr-banner__mobile__text">{{ $t('TPSModal.download') }}</p>
            <div class="qr-banner__mobile__container">
              <a href="https://iosapp.vitaly.es"><img src="@/assets/images/appstore.png"></a>
              <a href="https://androidapp.vitaly.es"><img src="@/assets/images/googleplay.png"></a>
            </div>
        </div>
        <div v-else>
            <div class="qr-banner__scan">
                <div class="qr-banner__scan__text-container">
                    <p class="qr-banner__scan__text-container__text">{{ $t('TPSModal.qrText') }}</p>
                </div>
            </div>
            <div class="qr-banner__qrs">
                <div class="qr-banner__qrs__content">
                    <span class="qr-banner__qrs__content__text">{{ $t('TPSModal.ios') }}</span>
                    <img class="qr-banner__qrs__content__image" src="@/assets/images/ios-qr.png">
                </div>
                <div class="qr-banner__qrs__content">
                    <span class="qr-banner__qrs__content__text">{{ $t('TPSModal.android') }}</span>
                    <img class="qr-banner__qrs__content__image" src="@/assets/images/android-qr.png">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'QRBanner',
  props: [],
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      isMobile: 'ontouchstart' in window
    }
  },
  computed: {
    ...mapGetters(['getCurrentDevice'])
  },
  mounted () {
    console.log('isMobile', this.isMobile)
    this.$emit('isMobile', this.isMobile)
  },
  methods: {
    // isMobile () {
    //   const toMatch = [
    //     /Android/i,
    //     /webOS/i,
    //     /iPhone/i,
    //     /iPad/i,
    //     /iPod/i,
    //     /BlackBerry/i,
    //     /Windows Phone/i
    //   ]
    //   toMatch.some((toMatchItem) => {
    //     return navigator.userAgent.match(toMatchItem)
    //   })
    // }
  }
}
</script>
