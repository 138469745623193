import * as CryptoJS from 'crypto-js'

export function encrypt (word) {
  const key = CryptoJS.enc.Utf8.parse(`${process.env.VUE_APP_CRYPTO_PASS}`)
  const src = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(src, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })

  return encrypted.toString()
}

export function decrypt (word) {
  const key = CryptoJS.enc.Utf8.parse(`${process.env.VUE_APP_CRYPTO_PASS}`)
  const decrypted = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })

  return CryptoJS.enc.Utf8.stringify(decrypted).toString()
}
