<template>
  <div class="card__list">
    <div class="card__item card__item--info">
      <div
          class="card__icon-container"
          style="background-color: #00425c;"
      >
        <span class="card__icon icon icon-info-toast" />
      </div>
      <div class="card__content">
        <div class="card__header">
          <div class="card__title-container">
            <h4 class="card__title card__title-info">
              {{ $tc( title ) }}
            </h4>
            <span
                v-if="date"
                class="card__date"
            >{{ date }}</span>
          </div>
        </div>
        <div class="card__text-container">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationCardOneParagraph',
  props: {
    title: {
      type: String,
      required: true,
      default: null
    },
    date: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>
.card__item--info {
  padding: 0 20px 20px 0px;
}
.card__title-info {
  margin-top: 12px;
}

.card__text-container {
  padding: 10px 0;
}

@media (max-width: 800px) {
  .card__text-container {
    margin-top: 35px;
  }
}
@media (max-width: 800px) {
  .card__item--info {
    padding-left: 20px;
  }
}
</style>
