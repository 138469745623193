<template>
    <div class="welcome-view__form__legal-links">
            <span class="welcome-view__form__legal-link margin-10" @click="openLegalModal('TERMS')"
              >{{ $t('footer.legalAdvice') }}</span>
            <span class="welcome-view__form__legal-link margin-10" @click="openLegalModal('PRIVACY')"
              >{{ $t('footer.privacyPolicy') }}</span>
            <span class="welcome-view__form__legal-link margin-10" @click="openLegalModal('COOKIES')"
              >{{ $t('footer.cookiesPolicy') }}</span>
          </div>
</template>
<script>
export default {
  name: 'LegalLinks',
  methods: {
    openLegalModal: function (content) {
      this.$emit('onClickLink', content)
    }
  }
}
</script>
