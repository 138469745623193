import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const numberFormats = {
  // One entry per ISO country code
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  }
}
const languages = [
  {
    id: 1,
    code: 'es'
  },
  {
    id: 2,
    code: 'ca'
  },
  {
    id: 3,
    code: 'eu'
  },
  {
    id: 4,
    code: 'en'
  }
]

function localeInUse () {
  const a = languages.find(x => x.id === parseInt(sessionStorage.getItem('language')))
  if (a) {
    return a.code
  }
  return null
}

export default new VueI18n({
  numberFormats,
  locale: process.env.VUE_APP_I18N_LOCALE || localeInUse() || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
