<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <DocModal :data="docModalData" @onClose="closeDocModal()" v-if="isDocModalVisible" />
    <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" @onSendFile="sendFile($event)" />
    <h1 class="subsection__title">{{ $t('menu.healthMonitoring.otherDocumentation') }}</h1>
    <SkeletonCard v-if="skeletonIsVisible"/>
    <NoResults :type="CARD_TYPE.OTHER_DOCUMENTATION" v-if="otherDocuments.length === 0 && !skeletonIsVisible" />
    <CardList :data="otherDocuments" @onClickPreview="openPreview($event)" @onClickShareLink="openModal($event)"   v-if="otherDocuments.length > 0"/>
   </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CardList from '@/components/commons/CardList.vue'
import DocModal from '@/components/modals/DocModal.vue'
import Modal from '@/components/modals/Modal.vue'
import Toast from '@/components/toasts/Toast.vue'
import NoResults from '@/components/commons/NoResults.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
import healthService from '@/services/healthService.js'
export default {
  name: 'OtherDocumentation',
  components: { DocModal, CardList, Modal, NoResults, SkeletonCard, Toast },
  data () {
    return {
      skeletonIsVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      recognitionData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      otherDocuments: [],
      otherDocumentsData: [],
      docModalData: null,
      shareModalParams: {
        type: 2,
        title: this.$i18n.t('global.sendByEmail'),
        file: {
          name: 'nombre-del-archivo.pdf'
        }
      },
      toastParams: null
    }
  },
  mounted () {
    // this.getOtherDocuments()
    if (this.getOtherDocuments !== null) {
      this.otherDocumentsData = this.getOtherDocuments
      this.filterData()
    }
    if (this.getOtherDocumentsStatus === 'stopped') this.$store.dispatch('fetchApi')
    if (this.getOtherDocumentsStatus === 'pending') this.skeletonIsVisible = true
  },
  methods: {
    openModal: function (file) {
      this.shareModalParams.file = file
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    sendFile: function (recipient) {
      // console.log()
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        text: `El archivo se ha enviado correctamente a ${recipient.name}`,
        time: 5000
      }
    },
    openPreview: function (item) {
      this.docModalData = item
      if (!item.uuid || item.uuid === 'xxxx') {
        this.isToastVisible = true
        this.toastParams = {
          text: this.$t('documentModal.error'),
          type: 'danger',
          time: 5000
        }
      } else {
        this.isDocModalVisible = true
      }
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    getOtherDocumentstest () {
      this.skeletonIsVisible = true
      healthService.getOtherDocuments()
        .then((response) => {
          // console.log()
          response.data.forEach(element => {
            // console.log()
            element.docs.forEach(doc => {
              doc.type = element.type
              this.otherDocuments.push(doc)
            })
          })
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
          this.skeletonIsVisible = false
        })
    },
    filterData () {
      if (this.otherDocumentsData.length > 0) {
        this.otherDocumentsData.forEach(element => {
          element.docs.forEach(doc => {
            doc.type = element.type
            this.otherDocuments.push(doc)
          })
        })
      }
    }

  },
  computed: {
    ...mapGetters(['getOtherDocumentsStatus', 'getOtherDocuments'])
  },
  watch: {
    getOtherDocumentsStatus: {
      handler (newVal, oldVal) {
        if (newVal === 'success') {
          this.skeletonIsVisible = false
          this.otherDocumentsData = this.getOtherDocuments
          this.filterData()
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.skeletonIsVisible = true
        }
      },
      deep: true
    }
  }
}
</script>
