import axios from 'axios'
export default {
  getProvinces () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/open-classroom-calendar/provinces`
    )
  },
  getCourses (provinceId, code) {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/open-classroom-calendar?provinceId=${provinceId}&code=${code}`
    )
  }
}
