import appointmentService from '@/services/appointmentService'
import userService from '@/services/userService'
import store from '@/store'

const getMyAppointments = () => {
  return appointmentService.getMyAppointments()
    .then((response) => {
      store.commit('setAppointments', response.data)
      sessionStorage.setItem('_appointments', response.data)
    })
    .catch(() => {
      console.error('My appointments failed')
    })
}
const getCompanies = () => {
  return userService.getCompanies()
    .then((response) => {
      store.commit('setClientModules', response.data)
      sessionStorage.setItem('_modules', response.data)
    })
    .catch(() => {
      console.error('Companies failed')
    })
}
const initFunctions = async () => {
  await Promise.all([getMyAppointments(), getCompanies()])
}

export { initFunctions }
