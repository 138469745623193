import axios from 'axios'
export default {
  getMedicalExamination () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/health/examination`
    )
  },
  getHealthAnalytics () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/health/analytics`
    )
  },
  getOtherActivities () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/health/activities`
    )
  },
  getOtherDocuments () {
    return axios.get(
      `${window.VUE_APP_BACKEND_HOST}/health/docs`
    )
  }
}
