<template>
  <div class="maintenance-container">
    <img src="@/assets/logo-vitaly-nuevo.png" alt="Preving Contigo">
    <h2>Web en mantenimiento</h2>
    <h3>Disculpe las molestias</h3>
  </div>
</template>
<script>
export default {
  name: 'MaintenanceMode'
  // created () {
  //   console.log('created MAINTENANCE VIEW', window.VUE_APP_MAINTENANCE);
  // },
  // beforeMount () {
  //   console.log('beforeMount MAINTENANCE VIEW', window.VUE_APP_MAINTENANCE);
  // },
  // mounted () {
  //   console.log('MOUNTED MAINTENANCE VIEW', window.VUE_APP_MAINTENANCE);
  //   if (window.VUE_APP_MAINTENANCE === false) {
  //     this.$router.push('/')
  //   }
  // }
}
</script>

<style>

.maintenance-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    color: #00425C;
    font-family: Helvetica,Arial,sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    justify-content: center;
}

.maintenance-container img {
  width: 90%;
  max-width: 700px;
  margin-bottom: 50px;
}

</style>
