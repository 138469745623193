import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    hematies: [],
    isLoading: false,
    units: 'x10⁶ / mm³'
  }
}

export default {
  state: {
    fakeHematies:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 5.1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 5.22
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 5.66
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-09T10:54:30.393190',
          year: '2023',
          value: 5.26
        }
      ],
    // data: [79.4, 90, 69.7, 82.3],
    // xLabel: [2019, 2020, 2021, 2222],
    // fakeData: 33,
    hematies: [],
    isLoading: false,
    units: 'Kg'
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    // loadData: (state, payload) => (state.hematies = payload)
    loadDataHematies (state, payload) {
      // console.log('loadData', payload)
      state.hematies = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateHematies (state) { // fecha del último dato con valor
      const itemsWithValue = state.hematies.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataHematies (state) { // valor del último dato recogido
      const itemsWithValue = state.hematies.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return parseFloat(el.value)
      })
      return valuesArray.pop()
    },
    filteredDataHematies (state) { // solo datos
      const objectValues = state.hematies.map(function (el) {
        return el.value
      })
      return objectValues
    },
    filteredXlabelsHematies (state) { // solo label del eje Y
      const objectKeys = state.hematies.map(function (el) {
        return el.year
      })
      return objectKeys
    },
    getUnitsHematies (state) {
      return state.units
    }
  },
  actions: {
    async fetchHematies ({ commit, state }) {
      if (state.hematies.length === 0) {
        state.isLoading = true
        kpiService
          .getHematies()
          .then((response) => {
            commit('loadDataHematies', response.data)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            state.isLoading = false
          })
      }
    }
  }
}
