<template>
    <div class="avatar__container" v-if="getCurrentUser" v-click-outside="onClickOutside">
        <div class="avatar__button" @click="showOptions()">
          <div class="avatar">
          <span class="avatar__initials">{{ getInitials() }}</span>
        </div>
        <span class="avatar__name">{{ getCompleteName() }}</span>
        <span class="avatar__icon icon icon-arrow-down"></span>
        </div>
        <div class="avatar__options" v-if="optionsOpened">
          <div class="avatar__option">
            <span class="avatar__option__label">{{ $t('welcomeLanguageSelector.language')}}</span>
            <WelcomeLanguageSelector :inAvatarMenu="true" class="avatar__option__label avatar__option__label--soft" @onClose="optionsOpened = !optionsOpened"/>
          </div>
          <div class="avatar__option" @click="logOut()">
            <span class="avatar__option__label">{{ $t('welcomeLanguageSelector.closeSession')}}</span>
          </div>
        </div>
      </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import WelcomeLanguageSelector from '@/components/welcome/WelcomeLanguageSelector'
Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
  name: 'Avatar',
  components: { WelcomeLanguageSelector },
  data () {
    return {
      isLanguageSelectorVisible: false,
      optionsOpened: false
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser'])
  },
  methods: {
    logOut: function () {
      this.$store.commit('setCurrentUser', null)
      this.$router.push('/login')
    },
    showOptions: function () {
      this.optionsOpened = !this.optionsOpened
    },
    onClickOutside: function () {
      this.optionsOpened = false
    },
    getInitials () {
      return this.getCurrentUser.name.charAt(0) + this.getCurrentUser.surname.charAt(0)
    },
    getCompleteName () {
      return this.getCurrentUser.name + ' ' + this.getCurrentUser.surname
    },
    showSelectorLanguages () {
      this.isLanguageSelectorVisible = !this.isLanguageSelectorVisible
    }
  }
}
</script>
