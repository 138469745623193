import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    diastolic: [],
    systolic: [],
    isLoading: false,
    units: 'mmHg'
  }
}

export default {
  state: {
    fakediastolic: [
      {
        rmId: 123,
        workerId: 456,
        date: '2020-03-09T10:54:30.393190',
        year: '2020',
        value: 137
      },
      {
        rmId: 123,
        workerId: 456,
        date: '2021-05-09T10:54:30.393190',
        year: '2021',
        value: 131
      },
      {
        rmId: 123,
        workerId: 456,
        date: '2022-03-09T10:54:30.393190',
        year: '2022',
        value: 142
      },
      {
        rmId: 123,
        workerId: 456,
        date: '2023-05-09T10:54:30.393190',
        year: '2023',
        value: 139
      }
    ],
    fakesystolic: [
      {
        rmId: 123,
        workerId: 456,
        date: '2020-03-09T10:54:30.393190',
        year: '2020',
        value: 82
      },
      {
        rmId: 123,
        workerId: 456,
        date: '2021-05-09T10:54:30.393190',
        year: '2021',
        value: 80
      },
      {
        rmId: 123,
        workerId: 456,
        date: '2022-03-09T10:54:30.393190',
        year: '2022',
        value: 83
      },
      {
        rmId: 123,
        workerId: 456,
        date: '2023-05-09T10:54:30.393190',
        year: '2023',
        value: 86
      }
    ],
    diastolic: [],
    systolic: [],
    isLoading: false,
    units: 'mmHg'
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    loadDataDiastolic (state, payload) {
      // console.log('loadDataDiastolic', payload)
      state.diastolic = payload
    },
    loadDataSystolic (state, payload) {
      // console.log('loadDataSystolic', payload)
      state.systolic = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateBloodPress (state) { // fecha del último dato con valor
      const itemsWithValue = state.diastolic.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataBloodPressDia (state) { // valor del último dato recogido
      const itemsWithValue = state.diastolic.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    outstandingDataBloodPressSys (state) { // valor del último dato recogido
      const itemsWithValue = state.systolic.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    filteredDataDiastolic (state) { // solo datos
      const objectValues = state.diastolic.map(function (el) {
        return el.value
      })
      // console.log('filteredDataDiastolic', objectValues)
      return objectValues
    },
    filteredDataSystolic (state) { // solo datos
      const objectValues = state.systolic.map(function (el) {
        return el.value
      })
      // console.log('filteredDataSystolic', objectValues)
      return objectValues
    },
    filteredXlabelsBloodPress (state) { // solo label del eje Y
      const objectKeys = state.diastolic.map(function (el) {
        return el.year
      })
      return objectKeys
    },
    getUnitsBloodPress (state) {
      return state.units
    }
  },
  actions: {
    fetchBloodPress ({ commit, state }) { // (commit, state, kpi)
      // commit('loadDataDiastolic', state.fakediastolic) // datos de pruebas
      // commit('loadDataSystolic', state.fakesystolic) // datos de pruebas
      if (state.diastolic.length === 0 || state.systolic.length === 0) {
        state.isLoading = true
        kpiService.getBloodDiastolic()
          .then((response) => {
            // console.log('DATA Diastolic', response.data)
            commit('loadDataDiastolic', response.data)
            // commit('loadDataDiastolic', state.fakediastolic) // datos de pruebas
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
          })
        kpiService.getBloodSystolic()
          .then((response) => {
            // console.log('DATA Systolic', response.data)
            // commit('loadDataSystolic', response.data)
            commit('loadDataSystolic', response.data) // datos de pruebas
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            state.isLoading = false
            // console.log('finally 4')
          })
      }
    }
  }
}
