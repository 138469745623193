<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <Modal :params="cancelAppointmentParams" v-if="isModalCancelVisible" @onClose="closeModalCancel()" @onCancelAppointment="cancelAppointment($event)"/>
    <h1 class="subsection__title">{{ $t('menu.myAppointments.title') }}</h1>
    <SkeletonCard v-if="skeletonIsVisible"/>
    <NoResults :type="CARD_TYPE.APPOINMENTS" v-if="myAppointments.length === 0 && !skeletonIsVisible" />
    <CardList :data="myAppointments" @onClickDownloadConsentForm="openDownloadConsentForm($event)" @onClickOpenConsentForm="openConsentForm($event)" @onClickCancelAppointment="openCancelAppointment($event)"  @onClickShareLink="openModal($event)"   v-if="myAppointments.length > 0"/>
   </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CardList from '@/components/commons/CardList.vue'
import Modal from '@/components/modals/Modal.vue'
import Toast from '@/components/toasts/Toast.vue'
import NoResults from '@/components/commons/NoResults.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
import appointmentService from '@/services/appointmentService.js'
import consentService from '@/services/consentService.js'
import * as _modules from '../../_helpers/modules.helper.js'
import moment from 'moment'
export default {
  name: 'Appoinments',
  components: { CardList, Modal, NoResults, SkeletonCard, Toast },
  data () {
    return {
      skeletonIsVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      myAppointmentsData: null,
      isDocModalVisible: false,
      isModalCancelVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      myAppointments: [],
      docModalData: null,
      appointmentMedicalCenters: null,
      appointmentReasons: null,
      appointmentTypes: null,
      lastAppointment: null,
      cancelAppointmentParams: {
        type: 1,
        appointment: null,
        title: this.$i18n.t('myAppoinments.cancelAppoinment'),
        text: this.$i18n.t('myAppoinments.textCancelAppointment'),
        date: null,
        size: 'sm'
      },
      shareModalParams: {
        type: 2,
        title: this.$i18n.t('global.sendByEmail'),
        file: {
          name: 'nombre-del-archivo.pdf'
        }
      },
      toastParams: null
    }
  },
  mounted () {
    this.refreshMyAppointments()
  },
  methods: {
    cancelAppointmentToast: function (message, type) {
      this.isToastVisible = true
      this.toastParams = {
        type: type,
        text: `${message}`,
        time: 5000
      }
      setTimeout(() => {
        this.isToastVisible = false
      }, 4000)
    },
    openConsentForm: function (appointment) {
      console.log(appointment)
      // this.$router.push('/consent-form-test/' + appointment.appointmentId)
      this.$router.push({
        name: 'ConsentForm',
        params: {
          appointment: appointment
        }
      })
    },
    openDownloadConsentForm: function (appointment) {
      console.log(appointment)
      consentService.downloadAcceptedConsent(appointment.id)
        .then((response) => {
          console.log(response)
        })
        .catch(() => {
          const type = 'danger'
          const message = this.$t('errorMessage.generic')
          this.cancelAppointmentToast(message, type)
        })
        .finally(() => {
        })
    },
    openCancelAppointment: function (appointment) {
      // this.cancelAppointmentParams.file = file
      const date = moment(appointment.date).format('LL')
      const hour = moment(appointment.date).format('LT')
      this.cancelAppointmentParams.appointment = appointment
      this.cancelAppointmentParams.date = appointment.date
      this.cancelAppointmentParams.text = this.cancelAppointmentParams.text + ' <strong>' + date + '</strong> ' + this.$i18n.t('myAppoinments.time') + ' <strong>' + hour + '</strong>'
      this.isModalCancelVisible = true
    },
    openModal: function (file) {
      this.shareModalParams.file = file
      this.isModalVisible = true
    },
    closeModalCancel: function () {
      this.cancelAppointmentParams.text = this.$i18n.t('myAppoinments.textCancelAppointment')
      this.isModalCancelVisible = false
    },
    cancelAppointment (event) {
      this.cancelAppointmentParams.text = this.$i18n.t('myAppoinments.textCancelAppointment')
      // console.log()
      const initDate = moment(event.date).format('DD-MM-YYYY HH:mm')
      // console.log()

      appointmentService.cancelAppointment(initDate, event.appointment.reasonId, event.appointment.clientId, event.appointment.medicalCenterId)
        .then(() => {
          // console.log()
          const type = 'success'
          this.cancelAppointmentToast(this.$t('requestAppoinment.cancelSuccess'), type)
          window.location.reload()
          this.getMyAppointments()
        })
        .catch((error) => {
          // console.log()
          const type = 'danger'
          let a = ''
          try {
            a = 'requestAppoinment.apiErrors.' + error.response.data.message
          } catch (err) {
            // console.log()
            a = 'error'
          }
          const message = this.$t(a)
          this.cancelAppointmentToast(message, type)
        })
        .finally(() => {
          this.isModalCancelVisible = false
        })
    },
    refreshMyAppointments () {
      this.skeletonIsVisible = true
      return appointmentService.getMyAppointments()
        .then((response) => {
          // console.log()
          // const a = Crypto.decrypt(response.data)
          // console.log()
          this.$store.commit('setAppointments', response.data)
          sessionStorage.setItem('_appointments', response.data)
          this.getMyAppointments()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          this.skeletonIsVisible = false
        })
    },
    getMyAppointments () {
      this.skeletonIsVisible = true
      this.myAppointmentsData = _modules.getAppointments()
      this.appointmentMedicalCenters = this.myAppointmentsData.medicalCenters
      this.appointmentReasons = this.myAppointmentsData.reasons
      this.appointmentTypes = this.myAppointmentsData.types
      this.appointmentConsents = this.myAppointmentsData.informedConsents
      this.myAppointmentsData.appointments.forEach(element => {
        element.date = moment(element.date, 'yyyy-MM-DD HH:mm')
        const now = moment().format('YYYYMMDD')
        if (now > moment(element.date).format('YYYYMMDD')) element.expired = true
        // if (element.anulada === 0) element.expired = true
        element.type = 6
        element.informedConsents = this.appointmentConsents.filter(x => x.appointmentId === element.id)
        element.informedConsents.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        element.informedConsent = element.informedConsents[0]
        const autocompleteConsent = this.appointmentConsents.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        element.autocompleteConsent = autocompleteConsent.length !== 0 ? autocompleteConsent[0] : null
        this.appointmentReasons.forEach(reason => {
          if (reason.id.languageCode === 'es') {
            element.reason = reason.name
            element.reasonId = reason.id.itemId
          }
        })
        this.appointmentTypes.forEach(typeTitle => {
          if (typeTitle.id.languageCode === 'es') {
            element.typeTitle = typeTitle.name
          }
        })
        element.medicalCenterInfo = this.appointmentMedicalCenters.find(x => x.id === element.medicalCenterId)
        if (element.anulada === 0) this.myAppointments.push(element)
      })
      this.appointmentConsents.forEach(element => {
        console.log('asd')
      })
      this.myAppointments = this.sortedAppointments()
      this.skeletonIsVisible = false
    },
    sortedAppointments: function () {
      const array1 = this.myAppointments.filter(x => x.expired).sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      const array2 = this.myAppointments.filter(x => !x.expired).sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      const arrayfin = [...array2, ...array1]
      arrayfin.forEach(element => {
        console.log(element.date, element.expired, element.anulada)
      })
      this.myAppointments = arrayfin
      return this.myAppointments
    }
  },
  computed: {
    ...mapGetters(['getmyAppointmentsData', 'getAppointments'])
  }
}
</script>
