<template>
  <Bar
    ref="bar"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, SubTitle, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChartDoubleStack',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Object,
    //   default: () => {}
    // },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      // eslint-disable-next-line
      type: String | Number,
      default: ''
    },
    unitsX: {
      type: String,
      default: ''
    },
    unitsY: {
      type: String,
      default: ''
    },
    xLabels: {
      type: Array,
      default: null
    },
    barsData: {
      type: Array,
      default: null
    },
    barColors: {
      type: Array,
      default: null
    },
    barsBackground: {
      type: Array,
      default: null
    },
    maxYlabels: {
      type: Number,
      default: null
    }
  },
  computed: {
    chartData () {
      return {
        // labels: ['2019', '2020', '2021'],
        labels: this.xLabels, // etiquetas inferiores
        datasets: [
          {
            label: null, // leyenda superior
            backgroundColor: this.barColors, // color barras
            data: this.barsData, // datos de las gráficas
            // borderColor: 'rgb(255 99 132)',
            // borderWidth: 1,
            // borderSkipped: 'bottom', // anula alguno de los border
            borderSkipped: false, // mete borderRadius inferior
            borderRadius: 100,
            animation: true
            // inflateAmount: 5, // infla el border ?
            // hoverBorderWidth: 4
          },
          {
            // datos para simular el carril por
            // el que crecen las otras barras
            label: null,
            data: [this.maxYlabels, this.maxYlabels, this.maxYlabels, this.maxYlabels],
            borderSkipped: false,
            borderRadius: 100,
            animation: true
          }
        ]
        // hoverOffset: 4
      }
    },
    chartOptions () {
      return {
        // todo lo que vaya en options va aquí.
        barThickness: 12, //  grosor de las barras
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: false,
        layout: { // marco del canvas
          padding: 10
          // padding: {
          //   left: 50,
          //   right: 10,
          // }
        },
        plugins: {
          title: {
            display: true,
            text: this.title,
            padding: {
              top: 5,
              bottom: 3
            },
            color: '#ffffffbf',
            font: {
              family: "'Arial', sans-serif",
              size: 14,
              weight: 'lighter',
              style: 'normal'
            }
          },
          subtitle: {
            display: true,
            // text: 'Custom Chart Subtitle',
            text: `${this.subtitle} ${this.unitsY}`,
            color: '#ffffff',
            padding: {
              bottom: 5
            },
            font: {
              family: "'Arial', sans-serif",
              size: 16,
              weight: 'lighter',
              style: 'normal'
            }
          },
          legend: {
            display: false,
            labels: {
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 12,
                style: 'normal',
                weight: undefined,
                lineHeight: 1.2
              }
            }
          },
          tooltip: {
            // deshabilitar tooltips
            // enabled: false
            filter: tooltipItem => tooltipItem.datasetIndex === 0, // tooltip solo para la primera gráfica
            // backgroundColor: '#FFF',
            titleFontSize: 26,
            titleFontColor: '#0066ff',
            bodyFontColor: '#000',
            bodyFontSize: 26,
            displayColors: false
          }
        },
        scales: {
          // ticks: {
          //   // fontSize: 18,
          //   // stepSize: 5,
          //   // beginAtZero: true
          // },
          // stacked: true, // no me funciona
          x: {
            // type: 'linear',
            stacked: true,
            min: 0,
            max: 100,
            color: '#fffff',
            grid: {
              display: false,
              drawBorder: false
              // lineWidth: 1,
              // color: '#F28C00'
            },
            ticks: {
              color: '#ffffff99',
              font: {
                family: "'Arial', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              }
            }
          },
          y: {
            // type: 'linear',
            // stacked: true,
            min: -1,
            max: this.maxYlabels,
            grid: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false,
              color: '#ffffff',
              font: {
                family: "'Calibri', sans-serif",
                size: 12,
                weight: 'lighter',
                style: 'normal'
              }
            }
          }
        }
      }
    }
  },
  methods: {
    maxYlabels2: function () {
      let maxValue = Math.max(...this.barsData.filter(function (o) {
        if (o !== null) return o
      }))
      maxValue = Math.ceil((Math.ceil(Math.round(maxValue) / 10) * 10) + (Math.ceil(Math.round(maxValue) / 10) * 10) * 20 / 100)
      return maxValue
    }
  },
  mounted () {
    this.$refs.bar.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    chartData: {
      handler (newVal) {
        this.$refs.bar.renderChart(this.chartData, this.chartOptions)
      }
    },
    chartOptions: {
      handler (newVal) {
        this.$refs.bar.renderChart(this.chartData, this.chartOptions)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
