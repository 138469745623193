import moment from 'moment'
import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    weight: [],
    isLoading: false,
    state: 'stopped'
  }
}

export default {
  state: {
    fakeWeight:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 75.02
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 70.56
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 76.54
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-09T10:54:30.393190',
          year: '2023',
          value: 98.56
        }
      ],
    // data: [79.4, 90, 69.7, 82.3],
    // xLabel: [2019, 2020, 2021, 2222],
    // fakeData: 33,
    weight: [],
    isLoading: false,
    state: 'stopped'
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    // loadData: (state, payload) => (state.weight = payload)
    loadDataWeight (state, payload) {
      // console.log('loadData', payload)
      state.weight = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateWeight (state) { // fecha del último dato con valor
      const itemsWithValue = state.weight.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataWeight (state) { // valor del último dato recogido
      const itemsWithValue = state.weight.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    filteredDataWeight (state) { // solo datos
      const objectValues = state.weight.map(function (el) {
        return el.value
      })
      return objectValues
    },
    filteredXlabelsWeight (state) { // solo label del eje Y
      const objectKeys = state.weight.map(function (el) {
        return el.year
      })
      return objectKeys
    },
    getWeightState (state) {
      return state.state
    }
  },
  actions: {
    async fetchWeight ({ commit, state }) { // (commit, state, kpi)
      // console.log('FETCH DESDE STORE Weight')
      // commit('loadDataWeight', state.fakeWeight) // datos de pruebas
      if (state.weight.length === 0) {
        state.state = 'pending'
        state.isLoading = true
        kpiService
          .getWeight()
          .then((response) => {
            // console.log('DATA', response.data)
            commit('loadDataWeight', response.data)
            // commit('loadDataWeight', state.fakeWeight) // datos de pruebas
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 1')
            state.state = 'finished'
            state.isLoading = false
          })
      }
    }
  }
}
