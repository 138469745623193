import i18n from '@/i18n.js'

export default {
  state: {
    menu: [
      { label: i18n.t('menu.home.title'), path: '/', icon: 'icon-home', items: [], selected: false },
      {
        label: i18n.t('menu.healthMonitoring.digitalHealth'),
        path: '/my-digital-health',
        icon: 'icon-health-viewer',
        selected: false,
        opened: false,
        items: []
      },
      {
        label: i18n.t('menu.healthMonitoring.title'),
        path: '/health-monitoring',
        icon: 'icon-health',
        selected: false,
        opened: false,
        items: [
          { label: i18n.t('menu.healthMonitoring.medicalExaminations'), component: 'MedicalExaminations', selected: false },
          { label: i18n.t('menu.healthMonitoring.proficiencyReports'), component: 'ProficiencyReports', selected: false },
          { label: i18n.t('menu.healthMonitoring.analytical'), component: 'Analytical', selected: false },
          { label: i18n.t('menu.healthMonitoring.otherTests'), component: 'OtherTests', selected: false },
          { label: i18n.t('menu.healthMonitoring.otherDocumentation'), component: 'OtherDocumentation', selected: false },
          { label: i18n.t('menu.healthMonitoring.healthyGuides'), component: 'HealthyGuides', selected: false },
          { label: i18n.t('menu.healthMonitoring.documentsUpload'), component: 'DocumentsUpload', selected: false },
          { label: i18n.t('menu.healthMonitoring.medicalResignation'), component: 'MedicalResignations', selected: false }
        ]
      },
      {
        label: i18n.t('menu.technicalPrevention.title'),
        path: '/technical-prevention',
        icon: 'icon-helmet',
        selected: false,
        opened: false,
        items: [
          { label: i18n.t('menu.technicalPrevention.riskEvaluation'), component: 'RiskEvaluation', selected: false },
          { label: i18n.t('menu.technicalPrevention.informationConsultation'), component: 'InformationConsultation', selected: false },
          { label: i18n.t('menu.technicalPrevention.trainingReceived'), component: 'TrainingReceived', selected: false },
          { label: i18n.t('menu.technicalPrevention.openClassroomCalendar'), component: 'OpenClassroomCalendar', selected: false }
        ]
      },
      {
        label: i18n.t('menu.myAppointments.title'),
        path: '/my-appoinments',
        icon: 'icon-calendar',
        selected: false,
        opened: false,
        items: [
          { label: i18n.t('menu.myAppointments.title'), path: '/my-appoinments', component: 'Appoinments', icon: 'icon-calendar', selected: false },
          { label: i18n.t('menu.myAppointments.requestAppointments'), path: '/request-appoinments', component: 'RequestAppoinments', selected: false, disabled: false }
        ]
      },
      {
        label: i18n.t('menu.myProfile.title'),
        path: '/my-profile',
        icon: 'icon-user',
        selected: false,
        opened: false,
        items: [
          { label: i18n.t('menu.myProfile.title'), path: '/my-profile', component: 'ProfileSection', selected: false },
          { label: i18n.t('menu.generalInfo.myWorkPlace'), component: 'MyWorkPlace', selected: false },
          { label: i18n.t('menu.generalInfo.employmentHistory'), component: 'EmploymentHistory', selected: false }
        ]
      },
      {
        label: i18n.t('menu.faq.title'),
        path: '/faq',
        items: [],
        selected: false
      },
      {
        label: i18n.t('menu.contact.title'),
        labelMobile: i18n.t('menu.contact.needHelp'),
        buttonMobile: i18n.t('menu.contact.callUs'),
        phoneNumber: '911 594 000',
        path: '/contact',
        items: [],
        selected: false
      },
      {
        label: i18n.t('menu.blog.title'),
        path: '/blog',
        iconMobile: 'icon-external-link',
        items: [],
        selected: false
      }
    ],
    isMobileMenuVisible: false,
    paramSection: null
  },
  mutations: {
    setMenu (state, menu) {
      // console.log()
      state.menu = menu
    },
    setIsMobileMenuVisible (state, visibility) {
      state.isMobileMenuVisible = visibility
    },
    setParamSection (state, section) {
      state.paramSection = section
    },
    changeLocale (state) {
      state.menu[0].label = i18n.t('menu.home.title')
      state.menu[1].label = i18n.t('menu.myDigitalHealth.title')
      state.menu[1].items[0].label = i18n.t('menu.healthMonitoring.digitalHealth')
      state.menu[1].items[1].label = i18n.t('home.yourHealthProgram')
      state.menu[1].items[2].label = i18n.t('home.telemedicina')

      state.menu[2].label = i18n.t('menu.healthMonitoring.title')

      state.menu[2].items[0].label = i18n.t('menu.healthMonitoring.medicalExaminations')
      state.menu[2].items[1].label = i18n.t('menu.healthMonitoring.proficiencyReports')
      state.menu[2].items[2].label = i18n.t('menu.healthMonitoring.analytical')
      state.menu[2].items[3].label = i18n.t('menu.healthMonitoring.otherTests')
      state.menu[2].items[4].label = i18n.t('menu.healthMonitoring.otherDocumentation')
      state.menu[2].items[5].label = i18n.t('menu.healthMonitoring.healthyGuides')
      state.menu[2].items[6].label = i18n.t('menu.healthMonitoring.documentsUpload')
      state.menu[2].items[7].label = i18n.t('menu.healthMonitoring.medicalResignation')

      // state.menu[2].items[0].label = i18n.t('menu.healthMonitoring.medicalExaminations')
      // state.menu[2].items[1].label = i18n.t('menu.healthMonitoring.proficiencyReports')
      // state.menu[2].items[2].label = i18n.t('menu.healthMonitoring.analytical')
      // state.menu[2].items[3].label = i18n.t('menu.healthMonitoring.otherTests')
      // state.menu[2].items[4].label = i18n.t('menu.healthMonitoring.otherDocumentation')
      // state.menu[2].items[5].label = i18n.t('menu.healthMonitoring.healthyGuides')

      state.menu[3].label = i18n.t('menu.technicalPrevention.title')
      state.menu[3].items[0].label = i18n.t('menu.technicalPrevention.riskEvaluation')
      state.menu[3].items[1].label = i18n.t('menu.technicalPrevention.informationConsultation')
      state.menu[3].items[2].label = i18n.t('menu.technicalPrevention.trainingReceived')
      state.menu[3].items[3].label = i18n.t('menu.technicalPrevention.openClassroomCalendar')

      state.menu[4].label = i18n.t('menu.myAppointments.title')
      state.menu[4].items[0].label = i18n.t('menu.myAppointments.title')
      state.menu[4].items[1].label = i18n.t('menu.myAppointments.requestAppointments')

      state.menu[5].label = i18n.t('menu.myProfile.title')
      state.menu[5].items[0].label = i18n.t('menu.myProfile.title')
      state.menu[5].items[1].label = i18n.t('menu.myProfile.resetPassword')
      state.menu[5].items[2].label = i18n.t('menu.generalInfo.myWorkPlace')
      state.menu[5].items[3].label = i18n.t('menu.generalInfo.employmentHistory')

      state.menu[6].label = i18n.t('menu.faq.title')
      state.menu[7].label = i18n.t('menu.contact.title')
      state.menu[8].label = i18n.t('menu.blog.title')
    }
  },
  // Must start with 'get'
  getters: {
    getMenu (state) {
      return state.menu
    },
    getIsMobileMenuVisible (state) {
      return state.isMobileMenuVisible
    },
    getParamSection (state) {
      return state.paramSection
    }
  },
  actions: {
  }
}
