<template>
    <div class="section__container">
      <div class="section__content section__content--sm">
        <!-- <h1 class="subsection__title margin-top-40">{{ $t('footer.cookiesPolicy') }}</h1> -->
        <div class="" v-html="this.text"></div>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CookiesPolicy',
  data () {
    return {
      text: null,
      termsi18n: null
    }
  },
  mounted () {
    this.termsi18n = this.getCookiesTerms
    this.setCookiesTerms()
  },
  computed: {
    ...mapGetters(['getCookiesTerms', 'getLocale'])
  },
  methods: {
    setCookiesTerms: function () {
      this.text = decodeURIComponent(atob(this.termsi18n.find(x => x.languageCode === this.getLocale).contents).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    }
  },
  watch: {
    getLocale: {
      handler (newVal, oldVal) {
        // console.log()
        this.setCookiesTerms()
      },
      deep: true
    },
    getCookiesTerms: {
      handler (newVal, oldVal) {
        if (oldVal === null) {
          this.termsi18n = this.getCookiesTerms
        }
      },
      deep: true
    }
  }
}
</script>

<style>

  h2 {
    font-family: Helvetica,Arial,sans-serif !important;
    margin-bottom: 10px;
  }

</style>
