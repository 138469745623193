<template>
  <div class="the-footer">
    <div class="the-footer__content">
      <div class="the-footer__links-container">
        <span class="the-footer__copyright">{{ $t('footer.copyright', {year: year}) }}</span>
        <span class="the-footer__link" @click="navigateTo('/legal-advice', $t('footer.legalAdvice'))">{{ $t('footer.legalAdvice') }}</span>
        <span class="the-footer__link" @click="navigateTo('/privacy-policy', $t('footer.privacyPolicy'))">{{ $t('footer.privacyPolicy') }}</span>
        <span class="the-footer__link" @click="showCookiesBar">{{ $t('footer.cookiesPolicy') }}</span>
        <a href="https://vitaly.es/" target="_blank" class="the-footer__link" >{{ $t('footer.previngcom') }}</a>
      </div>
      <div class="social-links the-footer__social-links">
        <span class="social-links__label">{{ $t('global.followUs')}}</span>
        <a href="https://www.facebook.com/VitalyPRL/" target="_blank" class="social-links__icon icon icon-facebook"></a>
        <a href="https://twitter.com/vitaly_prl" target="_blank" class="social-links__icon icon icon-twitter"></a>
        <a href="https://es.linkedin.com/company/vitaly-prevencionriesgoslaborales" target="_blank" class="social-links__icon icon icon-linkedin"></a>
        <a href="https://www.youtube.com/@VitalyPRL" target="_blank" class="social-links__icon icon icon-youtube"></a>
        <a href="https://www.instagram.com/vitaly_prl/" target="_blank" class="social-links__icon icon icon-instagram"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TheFooter',
  data () {
    return {
      year: null
    }
  },
  computed: {
    ...mapGetters(['getCookiesBarVisible'])
  },
  mounted () {
    const date = new Date()
    this.year = date.getFullYear()
  },
  methods: {
    ...mapActions(['showCookiesBar']),
    navigateTo: function (path, label) {
      this.$store.commit('setParamSection', { label: label })
      this.$router.push(path)
      window.scrollTo(0, 0)
    }
    // handlerCookiesBar () {
    //   // console.log()
    //   // this.$store.commit('setCookiesBarVisible', true)
    //   showCookiesBar(true)
    // }
  }
}
</script>
