<template>
  <div class="card">
    <Toast :params="toastParams" v-if="isToastVisible"/>
    <DocModal :data="docModalData" @onClose="closeDocModal()" v-if="isDocModalVisible" />
    <Modal :params="shareModalParams" v-if="isModalVisible" @onClose="closeModal()" @onSendFile="sendFile($event)" />
    <h1 class="subsection__title">{{ $t('menu.healthMonitoring.analytical') }}</h1>
    <SkeletonCard v-if="skeletonIsVisible"/>
    <NoResults :type="CARD_TYPE.ANALYTICS" v-if="healthAnalytics.length === 0 && !skeletonIsVisible" />
    <CardList :data="healthAnalytics" @onClickPreview="openPreview($event)" @onClickShareLink="openModal($event)"   v-if="healthAnalytics.length > 0"/>
   </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CardList from '@/components/commons/CardList.vue'
import DocModal from '@/components/modals/DocModal.vue'
import Modal from '@/components/modals/Modal.vue'
import Toast from '@/components/toasts/Toast.vue'
import NoResults from '@/components/commons/NoResults.vue'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
export default {
  name: 'Analytical',
  components: { DocModal, CardList, Modal, NoResults, SkeletonCard, Toast },
  data () {
    return {
      skeletonIsVisible: false,
      CARD_TYPE: Vue.CONSTANTS.CARD_TYPE,
      recognitionData: null,
      isDocModalVisible: false,
      isModalVisible: false,
      isToastVisible: false,
      healthAnalytics: [],
      docModalData: null,
      shareModalParams: {
        type: 2,
        title: this.$i18n.t('global.sendByEmail'),
        file: {
          name: 'nombre-del-archivo.pdf'
        }
      },
      toastParams: null
    }
  },
  mounted () {
    if (this.getAnalytics !== null) {
      this.healthAnalytics = this.getAnalytics
      this.healthAnalytics = this.sortedAnalytics()
    }
    if (this.getAnalyticsStatus === 'stopped') this.$store.dispatch('fetchApi')
    if (this.getAnalyticsStatus === 'pending') this.skeletonIsVisible = true
  },
  methods: {
    openModal: function (file) {
      this.shareModalParams.file = file
      this.isModalVisible = true
    },
    closeModal: function () {
      this.isModalVisible = false
    },
    sendFile: function (recipient) {
      // console.log()
      this.isModalVisible = false
      this.isToastVisible = true
      this.toastParams = {
        text: `El archivo se ha enviado correctamente a ${recipient.name}`,
        time: 5000
      }
    },
    openPreview: function (item) {
      this.docModalData = item
      if (!item.uuid || item.uuid === 'xxxx') {
        this.isToastVisible = true
        this.toastParams = {
          text: this.$t('documentModal.error'),
          type: 'danger',
          time: 5000
        }
      } else {
        this.isDocModalVisible = true
      }
    },
    closeDocModal: function () {
      this.isDocModalVisible = false
    },
    sortedAnalytics: function () {
      this.healthAnalytics.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      return this.healthAnalytics
    }
  },
  computed: {
    ...mapGetters(['getAnalyticsStatus', 'getAnalytics'])
  },
  watch: {
    getAnalyticsStatus: {
      handler (newVal, oldVal) {
        if (newVal === 'success') {
          this.skeletonIsVisible = false
          this.healthAnalytics = this.getAnalytics
          this.healthAnalytics = this.sortedAnalytics()
        }
        if (oldVal === 'stopped' && newVal === 'pending') {
          this.skeletonIsVisible = true
        }
      },
      deep: true
    }
  }
}
</script>
