<template>
  <div class="section__container section__container--three-columns">
      <div class="">
        <AsideMenu :data="getMenu[3].items" @onChangeMenu="loadSection($event)" />
        <Banners class="margin-top-20" v-if="getCurrentDevice === DEVICES.TABLET" />
      </div>
        <div class="section__content" v-if="getParamSection">
            <component :is="getParamSection.component" />
        </div>
        <div class="">
          <Banners v-if="getCurrentDevice !== DEVICES.TABLET" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
// import userService from '@/services/userService.js'
import AsideMenu from '@/components/commons/AsideMenu.vue'
import RiskEvaluation from '@/components/technical-prevention/RiskEvaluation.vue'
import InformationConsultation from '@/components/technical-prevention/InformationConsultation.vue'
import TrainingReceived from '@/components/technical-prevention/TrainingReceived.vue'
import OpenClassroomCalendar from '@/components/technical-prevention/OpenClassroomCalendar.vue'
import Banners from '@/components/commons/Banners.vue'
// import * as _modules from '../../_helpers/modules.helper.js'
import * as _modules from '@/_helpers/modules.helper.js'
export default {
  name: 'TechnicalPrevention',
  components: { AsideMenu, Banners, RiskEvaluation, InformationConsultation, TrainingReceived, OpenClassroomCalendar },
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      windowWidth: window.innerWidth,
      menu: []
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getMenu', 'getCurrentDevice', 'getParamSection'])
  },
  created () {
    window.addEventListener('resize', this.onResize)
  },
  mounted () {
    this.menu = [...this.getMenu]
    // console.log()
    if (!this.getParamSection) {
      if (!_modules.hasPt()) {
        this.$store.commit('setParamSection', this.getMenu[3].items[2])
        this.menu[3].items[2].selected = true
      } else {
        this.$store.commit('setParamSection', this.getMenu[3].items[0])
        this.menu[3].items[0].selected = true
      }
      // console.log()
      this.menu[3].opened = true
      this.$store.commit('setMenu', this.menu)
    }
    this.menu[3].items[0].disabled = !_modules.hasPt()
    this.menu[3].items[1].disabled = !_modules.hasPt()
    this.menu[3].items[3].disabled = !_modules.hasPt()
    this.loadSection(this.getParamSection)
  },
  methods: {
    loadSection: function (section) {
      this.$store.commit('setParamSection', section)
      this.menu = [...this.getMenu]
      // this.menu[3].items[0].disabled = true
      // this.menu[3].items[1].disabled = true
      if (!section.selected) {
        this.menu[3].items.forEach(element => {
          element.selected = false
        })
        section.selected = true
        this.menu[3].opened = true
      }
    }
  }
}
</script>
