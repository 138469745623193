<template>
  <div class="modal__container">
    <div class="modal__backdrop">
      <div
        class="modal"
        :class="modalSize"
      >
        <div class="modal__header">
          <span class="modal__header__title">{{ params.title }}</span>
          <span class="modal__header__close icon icon-close" @click="closeModal()"></span>
        </div>
        <!-- MODAL_TYPE.CONFIRMATION -->
        <div class="modal__body" v-if="params.type === MODAL_TYPE.CONFIRMATION">
          <p class="modal__body__text" v-html="params.text" />
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <!--<button class="button button--cta" @click="cancelAppointment()">
                <span class="button__label">{{ $t('global.confirm')}}</span>
              </button>-->
              <Button
                :label="$t('global.confirm')"
                :classes="'button--cta'"
                :isLoading="isLoading"
                :disabledButton="isLoading"
                @click.native="cancelAppointment()"
              />
              <!--<button class="button" @click="closeModal()">
                <span class="button__label">{{ $t('global.cancel')}}</span>
              </button>-->
              <Button
                :label="$t('global.cancel')"
                :classes="'button'"
                :disabledButton="isLoading"
                @click.native="closeModal()"
              />
            </div>
          </div>
        </div>
        <!-- MODAL_TYPE.DOWNLOAD -->
        <div class="modal__body" v-if="params.type === MODAL_TYPE.DOWNLOAD">
          <p class="modal__body__text" v-html="params.text" />
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <Button
                  :label="$t('global.download')"
                  :classes="'button--cta'"
                  :isLoading="isLoading"
                  :icon = "'icon-download'"
                  :disabledButton="isLoading"
                  @click.native="confirmDownload()"
              />
            </div>
          </div>
        </div>
        <!-- MODAL_TYPE.SHARE -->
        <div class="modal__body" v-if="params.type === MODAL_TYPE.SHARE">
          <!-- <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.document')}}</div>
            <div class="form-group__data form-group__data--vertical-align">
              <div class="tag">
                <span class="tag__icon icon icon-attachment"></span>
                <span class="tag__label">{{ recipient.docName }}</span>
              </div>
            </div>
          </div> -->
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.name')}}</div>
            <div class="form-group__data">
              <input type="text" class="input" v-model="recipient.name">
            </div>
          </div>
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.email')}}</div>
            <div class="form-group__data">
              <!--<v-combobox
                multiple
                append-icon
                chips
                deletable-chips
                @keyup.enter="updateTags"
                @paste="updateTags">
              </v-combobox>-->
              <input class="input" chips v-model="search" @keyup.enter="updateTags">
            </div>
          </div>
          <div class="card__chips-container">
            <span append-icon class="card__chip" v-for="(email, index) in emailsTo" :key="index">
              {{email}}
              <button class="button__icon icon icon-close" v-on:click="deleteTags(email, index)"></button>
            </span>
          </div>
          <div class="form-group__message" v-if="emailErrorMessage && emailErrorTimeOut">
            <span class="form-group__message__icon icon icon-info-fill"></span>
            <span class="form-group__message__text">{{ this.emailErrorMessage }}</span>
          </div>
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <Button :label="$t('global.send')"
                :classes="'button--cta button--icon-right'"
                :isLoading="isLoading"
                :disabledButton="isLoading"
                @click.native="sendFile()" />
              <button class="button" @click="closeModal()">
                <span class="button__label">{{ $t('global.cancel')}}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- MODAL_TYPE.SHARE_RESIGNATIONS -->
        <div class="modal__body" v-if="params.type === MODAL_TYPE.SHARE_RESIGNATIONS">
          <!-- <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.document')}}</div>
            <div class="form-group__data form-group__data--vertical-align">
              <div class="tag">
                <span class="tag__icon icon icon-attachment"></span>
                <span class="tag__label">{{ recipient.docName }}</span>
              </div>
            </div>
          </div> -->
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.name')}}</div>
            <div class="form-group__data">
              <input type="text" class="input" v-model="recipient.name">
            </div>
          </div>
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.email')}}</div>
            <div class="form-group__data">
              <!--<v-combobox
                multiple
                append-icon
                chips
                deletable-chips
                @keyup.enter="updateTags"
                @paste="updateTags">
              </v-combobox>-->
              <input class="input" chips v-model="search" @keyup.enter="updateTags">
            </div>
          </div>
          <div class="card__chips-container">
            <span append-icon class="card__chip" v-for="(email, index) in emailsTo" :key="index">
              {{email}}
              <button class="button__icon icon icon-close" v-on:click="deleteTags(email, index)"></button>
            </span>
          </div>
          <div class="form-group__message" v-if="emailErrorMessage && emailErrorTimeOut">
            <span class="form-group__message__icon icon icon-info-fill"></span>
            <span class="form-group__message__text">{{ this.emailErrorMessage }}</span>
          </div>
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <Button :label="$t('global.send')"
                :classes="'button--cta button--icon-right'"
                :isLoading="isShareLoading"
                :disabledButton="isShareLoading"
                @click.native="sendResignation(params.resignationId)" />
              <button class="button" @click="closeModal()">
                <span class="button__label">{{ $t('global.cancel')}}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- MODAL_TYPE.SHARE_ICPT -->
        <div class="modal__body" v-if="params.type === MODAL_TYPE.SHARE_ICPT">
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.name')}}</div>
            <div class="form-group__data">
              <input type="text" class="input" v-model="recipient.name">
            </div>
          </div>
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.email')}}</div>
            <div class="form-group__data">
              <input class="input" chips v-model="search" @keyup.enter="updateTags">
            </div>
          </div>
          <div class="card__chips-container">
            <span append-icon class="card__chip" v-for="(email, index) in emailsTo" :key="index">
              {{email}}
              <button class="button__icon icon icon-close" v-on:click="deleteTags(email, index)"></button>
            </span>
          </div>
          <div class="form-group__message" v-if="emailErrorMessage && emailErrorTimeOut">
            <span class="form-group__message__icon icon icon-info-fill"></span>
            <span class="form-group__message__text">{{ this.emailErrorMessage }}</span>
          </div>
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <Button :label="$t('global.send')"
                :classes="'button--cta button--icon-right'"
                :isLoading="isShareLoading"
                :disabledButton="isShareLoading"
                @click.native="sendIcpt(params.file.laborRelationId)" />
              <button class="button" @click="closeModal()">
                <span class="button__label">{{ $t('global.cancel')}}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- MODAL_TYPE.SHARE_DOC_UPLOAD -->
        <div class="modal__body" v-if="params.type === MODAL_TYPE.SHARE_DOC_UPLOAD">
          <!-- <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.document')}}</div>
            <div class="form-group__data form-group__data--vertical-align">
              <div class="tag">
                <span class="tag__icon icon icon-attachment"></span>
                <span class="tag__label">{{ recipient.docName }}</span>
              </div>
            </div>
          </div> -->
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.name')}}</div>
            <div class="form-group__data">
              <input type="text" class="input" v-model="recipient.name">
            </div>
          </div>
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.email')}}</div>
            <div class="form-group__data">
              <!--<v-combobox
                multiple
                append-icon
                chips
                deletable-chips
                @keyup.enter="updateTags"
                @paste="updateTags">
              </v-combobox>-->
              <input class="input" chips v-model="search" @keyup.enter="updateTags">
            </div>
          </div>
          <div class="card__chips-container">
            <span append-icon class="card__chip" v-for="(email, index) in emailsTo" :key="index">
              {{email}}
              <button class="button__icon icon icon-close" v-on:click="deleteTags(email, index)"></button>
            </span>
          </div>
          <div class="form-group__message" v-if="emailErrorMessage && emailErrorTimeOut">
            <span class="form-group__message__icon icon icon-info-fill"></span>
            <span class="form-group__message__text">{{ this.emailErrorMessage }}</span>
          </div>
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <Button :label="$t('global.send')"
                :classes="'button--cta button--icon-right'"
                :isLoading="isShareLoading"
                :disabledButton="isShareLoading"
                @click.native="sendDocumentUpload(params.documentId)" />
              <button class="button" @click="closeModal()">
                <span class="button__label">{{ $t('global.cancel')}}</span>
              </button>
            </div>
          </div>
        </div>
        <!-- MODAL_TYPE.UPLOAD_DOC -->
        <div
          v-if="params.type === MODAL_TYPE.UPLOAD_DOC"
          class="modal__body"
        >
          <p class="modal__body__text" v-html="params.text" />
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">* {{ $t('shareModal.document')}}</div>
            <div class="form-group__data">
              <input style="padding:0" type="file" class="input" @change="attachFiles">
            </div>
          </div>
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">* {{ $t('global.name')}}</div>
            <div class="form-group__data">
              <input type="text" class="input" v-model="attachedFile.aliasName">
            </div>
          </div>
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">* {{ $t('shareModal.type')}}</div>
            <div class="form-group__data">
              <!-- <input type="select" class="input" v-model="attachedFile.type"> -->
              <Select
                :itemLabel="'label'"
                :data="typeOfDocument"
                :itemSelected="getItemSelected"
                :class="'form-group__data'"
                :placeholder="'Selecciona...'"
                @onSelect="setTypeOfDocument($event)"
              />
            </div>
          </div>
          <div class="modal__form-group form-group form-group--label-200">
            <div class="form-group__label">{{ $t('shareModal.comments')}}</div>
            <div class="form-group__data">
              <textarea rows="4" class="input" v-model="attachedFile.comment"></textarea>
            </div>
          </div>
          <div class="form-group__message" v-if="maxBytesError">
            <span class="form-group__message__icon icon icon-info-fill"></span>
            <span class="form-group__message__text">{{ $t('documentsUpload.maxBytes') }}</span>
          </div>
          <div class="form-group__message" v-if="wrongFileType">
            <span class="form-group__message__icon icon icon-info-fill"></span>
            <span class="form-group__message__text">{{ $t('documentsUpload.wrongFileType') }}</span>
          </div>
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <Button
                :label="$t('global.accept')"
                :classes="'button--cta'"
                :isLoading="isLoading"
                :disabledButton="isLoading || buttonDocumentDisabled"
                @click.native="uploadDocument()"
              />
              <Button
                :label="$t('global.cancel')"
                :classes="'button'"
                :disabledButton="isLoading"
                @click.native="closeModal()"
              />
            </div>
          </div>
        </div>
        <!-- MODAL_TYPE.RESIGNATIONS -->
        <div
          v-if="params.type === MODAL_TYPE.MEDICAL_RESIGNATION"
          class="modal__body"
          style="padding-bottom: 0; padding-top: 0"
        >
          <p v-if="params.text" class="modal__body__text" v-html="params.text" />
          <div
            class="card__item"
            style="box-shadow: none; margin: 0; padding-top: 0"
          >
            <div
              class="card__icon-container"
              style="height: 30px;width:30px;min-height: 30px;min-width:30px"
            >
              <span
                class="card__icon icon"
                style="font-size: 1rem;font-family: 'medium'; color: var(--white-color);"
              >1</span>
            </div>
            <div class="card__content">
              <div class="card__header" @click="openNextGroup(0)">
                <div
                  class="card__title-container"
                  style="border: 0; cursor: pointer;"
                >
                  <span v-if="medicalResignation.user.company.length > 1" class="card__title__md">{{ $t('requestAppoinment.selectCompany') }}</span>
                  <span v-else class="card__title__md">{{ $t('medicalResignation.resignationPeriod') }}</span>
                </div>
              </div>
              <div
                v-if="companies || selectedStep === 0"
                class="card__columns-container display-block"
                style="border: none;">
                <div
                  class="request-appoinment__content"
                  style="display: block !important">
                  <div
                    v-for="(company, index) in companies"
                    :key="index">
                    <div
                      class="request-appoinment__company margin-top-20"
                      :class="{'request-appoinment__company--selected' : company.selected, 'request-appoinment__company--disabled' : company.disabled}"
                      @click="setCompany(company)">
                      <span class="request-appoinment__company__icon icon" :class="{'icon-tick' : !company.disabled, 'icon-close' : company.disabled}"></span>
                      <span class="request-appoinment__company__label">{{ company.laborRelation.clientName }}</span>
                      <!-- <span class="request-appoinment__company__label">{{ params.data.find(x => x.laborRelation.clientId === company).clientName }}</span> -->
                    </div>
                    <SimpleAdvice v-if="(!company.laborRelation.resignationEnabled || company.periodicity === 0) && company.disabled" :text="$t('medicalResignation.resignationAvoid')" :bgColor="'yellow'" :showCloseIcon="false"/>
                  </div>
                </div>
              </div>
              <!-- <div class="card__columns-container display-block" style="border: none;">
                <p v-if="medicalResignation.user.company.length > 1" class="card__title__md">{{ $t('medicalResignation.resignationPeriod') }}</p>
                <p class="card__data card__data--xl">
                  {{ medicalResignation.resignationDate | moment }}
                </p>
              </div> -->
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div
            class="card__item"
            style="box-shadow: none; margin: 0; padding-top: 0"
          >
            <div
              class="card__icon-container"
              style="height: 30px;width:30px;min-height: 30px;min-width:30px"
            >
              <span
                class="card__icon icon"
                style="font-size: 1rem;font-family: 'medium'; color: var(--white-color);"
              >2</span>
            </div>
            <div class="card__content">
              <div class="card__header" @click="openNextGroup(1)">
                <div
                  class="card__title-container"
                  style="border: 0; cursor: pointer;"
                >
                  <span class="card__title__md">{{ $t('medicalResignation.job') }}</span>
                </div>
              </div>
              <div
                v-if="clientSelected"
                class="card__columns-container display-block"
                style="border: none;">
                <div
                  class="request-appoinment__content"
                  style="display: block !important">
                  <div
                    v-for="(company, index) in getJobs"
                    :key="index">
                    <div
                      class="request-appoinment__company margin-top-20"
                      :class="{'request-appoinment__company--selected' : company.laborRelation.selected, 'request-appoinment__company--disabled' : company.laborRelation.disabled, 'advice-resignation' : !company.laborRelation.resignationEnabled}"
                      @click="setJob(company.laborRelation)">
                      <span class="request-appoinment__company__icon icon" :class="{'icon-tick' : !company.laborRelation.disabled, 'icon-close' : company.laborRelation.disabled}"></span>
                      <span class="request-appoinment__company__label">{{ company.laborRelation.jobName }}</span>
                    </div>
                    <SimpleAdvice v-if="!company.laborRelation.resignationEnabled || company.periodicity === 0" :text="$t('medicalResignation.resignationAvoid')" :bgColor="'yellow'" :showCloseIcon="false"/>
                    <!-- <span class="request-appoinment__company__label">{{ params.data.find(x => x.laborRelation.clientId === company).clientName }}</span> -->
                  </div>
                </div>
              </div>
              <!-- <div class="card__columns-container display-block" style="border: none;">
                <p v-if="medicalResignation.user.company.length > 1" class="card__title__md">{{ $t('medicalResignation.resignationPeriod') }}</p>
                <p class="card__data card__data--xl">
                  {{ medicalResignation.resignationDate | moment }}
                </p>
              </div> -->
            </div>
          </div>
          <!--  -->
          <!--  -->
          <div
            class="card__item"
            style="box-shadow: none; margin: 0; padding-top: 0"
          >
            <div
              class="card__icon-container"
              style="height: 30px;width:30px;min-height: 30px;min-width:30px"
            >
              <span
                class="card__icon icon"
                style="font-size: 1rem;font-family: 'medium'; color: var(--white-color);"
              >3</span>
            </div>
            <div class="card__content">
              <div class="card__header">
                <div
                  class="card__title-container"
                  style="border: 0;"
                >
                  <span class="card__title__md" style="margin-bottom: 10px">{{ $t('medicalResignation.checkYourInfo') }}</span>
                  <span class="card__data">
                    {{ $t('medicalResignation.resignationLegalAdvice', { name: params.user.name, surname: params.user.surname, nif: params.user.dni, userJob: jobNameSelected } ) }}
                  </span>
                </div>
              </div>
              <div class="card__columns-container legal-advice" style="border: none;">
                <div class="little-advise yellow">
                  <input
                    id="finalDeclaration"
                    v-model="medicalResignation.legalCheck"
                    type="checkbox"
                    class="final-checkbox"
                    name="finalDeclaration"
                    value="true"
                  >
                  <label
                    for="finalDeclaration"
                    v-html="$t('consentForm.declarationData')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal__footer">
            <div class="modal__footer__buttons-container">
              <Button
                :label="$t('global.accept')"
                :classes="'button--cta'"
                :isLoading="isResignationLoading"
                :disabledButton="!medicalResignation.legalCheck || !clientSelected || !laborRelationId"
                @click.native="sendMedicalResignation()"
              />
              <Button
                :label="$t('global.cancel')"
                :classes="'button'"
                :disabledButton="isResignationLoading"
                @click.native="closeModal()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import documentService from '@/services/documentService.js'
import Button from '@/components/ui/Button.vue'
import Select from '@/components/ui/Select.vue'
import SimpleAdvice from '@/components/ui/SimpleAdvice.vue'
import * as _functions from '@/_helpers/functions.helper'
export default {
  name: 'Modal',
  components: { Button, Select, SimpleAdvice },
  props: ['params', 'isResignationLoading', 'isShareLoading'],
  emits: [
    'on-accept',
    'on-cancel',
    'on-close'
  ],
  data () {
    return {
      MAX_DOCS: window.UP_DOC_MAX_FILES,
      MAX_BYTES: window.UP_DOC_MAX_BYTES,
      FILE_TYPES: _functions.getUploadDocumentFileTypes(),
      maxBytesError: false,
      wrongFileType: false,
      MODAL_TYPE: Vue.CONSTANTS.MODAL_TYPE,
      recipient: {
        name: '',
        email: '',
        docName: ''
      },
      clientSelected: null,
      laborRelationId: null,
      medicalResignation: {
        id: 13458,
        type: 12,
        legalCheck: false,
        user: {
          name: 'Isabel',
          surname: 'Gómez Medina',
          dni: '44586315D',
          company: [
            { id: 1, name: 'Sngular', disabled: false, selected: false },
            { id: 2, name: 'Ferrovial', disabled: false, selected: false }
          ]
        },
        resignationDate: 'Fri Jun 10 2022 12:27:22 GMT+0200 (hora de verano de Europa central)',
        fromDate: 'Fri Jun 15 2022 12:27:22 GMT+0200 (hora de verano de Europa central)',
        untilDate: 'Fri Jun 20 2022 12:27:22 GMT+0200 (hora de verano de Europa central)',
        examinationJob: 'Técnico de procesos',
        jobCompany: 'Sngular'
      },
      selectedStep: 0,
      jobNameSelected: ' - ',
      companies: null,
      data: null,
      selectedCompany: false,
      attachedFile: {
        file: undefined,
        name: '',
        docType: '',
        comment: '',
        initialDate: '',
        fileName: ''
      },
      // typeOfDocument: ['Dosimetría', 'Ecografía abdominal', 'Ecografía mamaria'],
      typeOfDocument: [
        { id: 1, name: 'Dosimetría' },
        { id: 2, name: 'Ecografía abdominal' },
        { id: 3, name: 'Ecografía mamaria' },
        { id: 4, name: 'Informe de especialista' },
        { id: 5, name: 'Mamografía' },
        { id: 6, name: 'Pruebas cutáneas alergológicas' },
        { id: 7, name: 'Prueba de esfuerzo' },
        { id: 8, name: 'Radriografía de manos' },
        { id: 9, name: 'Radiología Tórax PA y L' },
        { id: 10, name: 'Radiología Tórax PA y L, y oblícuas' },
        { id: 11, name: 'Senos paranasales' },
        { id: 12, name: 'TACAR tórax' },
        { id: 13, name: 'Otros' }
      ],
      isLoading: false,
      emailErrorMessage: null,
      emailErrorTimeOut: null,
      emailsTo: [],
      items: [],
      search: '',
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value)
        }
      }
    }
  },
  computed: {
    buttonDocumentDisabled: function () {
      return !this.attachedFile.file || !this.attachedFile.docType
    },
    modalSize: function () {
      // SIZE OPTIONS: sm, md, lg, full. Default 600px
      return 'modal--' + this.params.size
    },
    getItemSelected: function () {
      console.log('asdf')
      console.log(this.attachedFile.docType)
      return this.attachedFile.docType
    },
    getCompanySelected: function () {
      return true
    },
    getJobs: function () {
      console.log(this.data)
      console.log(this.clientSelected)
      return this.data.filter(x => x.laborRelation.clientId === this.clientSelected)
    }
  },
  methods: {
    cancelAppointment: function () {
      this.isLoading = true
      this.$emit('onCancelAppointment', this.params)
      // this.closeModal()
    },
    confirmDownload: function () {
      this.isLoading = true
      this.$emit('onDownload', this.params)
      // this.closeModal()
    },
    sendResignation: function (resignationId) {
      this.isLoading = true
      if (this.search !== '') {
        if (this.formHasErrors() === false) {
          this.emailsTo.push(...this.search.split(','))
          // console.log()
          // console.log()
        }
      }

      const data = {
        resignationId: resignationId,
        toName: this.recipient.name,
        toEmail: this.emailsTo.toString(),
        filename: null
      }
      this.$emit('onSendFile', data)
    },
    sendIcpt: function (laborRelationId) {
      this.isLoading = true
      if (this.search !== '') {
        if (this.formHasErrors() === false) {
          this.emailsTo.push(...this.search.split(','))
          // console.log()
          // console.log()
        }
      }

      const data = {
        laborRelationId: laborRelationId,
        toName: this.recipient.name,
        toEmail: this.emailsTo.toString(),
        filename: null
      }
      this.$emit('onSendFile', data)
    },
    sendDocumentUpload: function (documentId) {
      this.isLoading = true
      if (this.search !== '') {
        if (this.formHasErrors() === false) {
          this.emailsTo.push(...this.search.split(','))
        }
      }
      const data = {
        documentId: documentId,
        toName: this.recipient.name,
        toEmail: this.emailsTo.toString(),
        filename: null
      }
      this.$emit('onSendFile', data)
    },
    sendFile: function () {
      this.shareDocument()
      // if (this.recipient.name && this.recipient.email) {
      //   this.$emit('onSendFile', this.recipient)
      //   this.closeModal()
      // }
    },
    cancel: function (event) {
      this.closeModal()
      this.$emit('on-cancel', true)
    },
    closeModal: function (event) {
      // this.$store.commit('closeModal')
      this.$emit('onClose')
    },
    sendData: function () {
      this.closeModal()
      this.$emit('on-accept', this.params)
    },
    shareDocument () {
      this.isLoading = true
      if (this.search !== '') {
        if (this.formHasErrors() === false) {
          this.emailsTo.push(...this.search.split(','))
          // console.log()
          // console.log()
        }
      }

      const data = {
        toName: this.recipient.name,
        toEmail: this.emailsTo.toString(),
        filename: null
      }
      documentService.shareDocument(this.params.file.uuid, this.params.file.documentType, data)
        .then((response) => {
          // console.log()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          this.$emit('onSendFile', this.recipient)
          this.isLoading = false
          this.closeModal()
        })
    },
    updateTags () {
      if (this.formHasErrors() === true) {
        setTimeout(() => {
          this.emailErrorTimeOut = false
        }, 2000)
        return
      }
      // console.log()
      this.$nextTick(() => {
        this.emailsTo.push(...this.search.split(','))
        this.$nextTick(() => {
          this.search = ''
        })
      })
    },
    deleteTags (email, index) {
      // console.log()
      this.$delete(this.emailsTo, index)
    },
    formHasErrors: function () {
      this.emailErrorMessage = null
      let hasErrors = false

      if (this.rules.email(this.search) !== true) {
        hasErrors = true
        this.emailErrorMessage = this.$i18n.t('form.validation.email')
        this.emailErrorTimeOut = true
      }

      return hasErrors
    },
    attachFiles (event) {
      console.log(event.target.files[0])
      this.maxBytesError = false
      this.wrongFileType = false
      const x = this.FILE_TYPES.find(x => x === event.target.files[0].type)
      if (!x) {
        this.wrongFileType = true
        event.target.value = ''
        return
      }
      console.log(event.target.files[0].name)
      console.log(event.target.files[0])
      console.log(event.target.files[0].size)
      if (event.target.files[0].size > this.MAX_BYTES) {
        this.maxBytesError = true
        event.target.value = ''
        return
      }
      console.log('entrra despues de max bytes')
      this.attachedFile.fileName = event.target.files[0].name.split('.').slice(0, -1).join('.')
      this.attachedFile.fileExtension = event.target.files[0].name.split('.').pop()
      this.getBase64(event.target.files[0]).then(
        data => {
          console.log(data)
          this.attachedFile.file = data
        }
      )
      console.log(this.attachedFile)
    },
    setTypeOfDocument (event) {
      // console.log('Evento:', event.name)
      this.attachedFile.docType = event
    },
    uploadDocument () {
      if (!this.attachedFile.file || !this.attachedFile.docType) {
        this.$emit('onClose')
        return
      }
      this.isLoading = true
      this.attachedFile.initialDate = moment().toDate()
      console.log(this.attachedFile)
      this.$emit('onSendDocument', this.attachedFile)
    },
    sendMedicalResignation () {
      console.log(this.medicalResignation)
      const data = {
        clientId: this.clientSelected,
        laborRelationId: this.laborRelationId
      }
      this.$emit('onSendResignation', data)
    },
    setCompany (company) {
      const laborRelation = company.laborRelation
      console.log('asdf')
      if (company.disabled) return null
      const clientId = laborRelation.clientId
      this.selectedCompany = true
      this.clientSelected = clientId
      this.companies.forEach(element => {
        if (clientId === element.laborRelation.clientId) {
          console.log('da a true ', element.laborRelation.clientName)
          // this.$set(element, 'selected', true)
          element.selected = true
        } else {
          element.selected = false
        }
      })
      this.laborRelationId = null
      this.openNextGroup(1)
      // const x = this.companies.find(x => x.laborRelation.clientId === clientId)
      // console.log(x)
      // x.selected = !x.selected
    },
    setJob (laborRelation) {
      console.log('asdf')
      if (laborRelation.disabled) return null
      const laborRelationId = laborRelation.id
      this.laborRelationId = laborRelationId
      this.data.forEach(element => {
        if (laborRelationId === element.laborRelation.id) {
          // this.$set(element, 'selected', true)
          element.laborRelation.selected = true
          this.jobNameSelected = element.laborRelation.jobName
        } else {
          element.laborRelation.selected = false
        }
        console.log(element.laborRelation.selected, element.laborRelation.jobName, element.laborRelation.id)
      })
      console.log(this.data)
      this.openNextGroup(2)
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',').pop())
        reader.onerror = error => reject(error)
      })
    },
    openNextGroup: function (index) {
      this.selectedStep = index
      switch (index) {
        case 0:
          return null
        case 1:
          console.log('entra en case 1')
          return null
        case 2:
          console.log('entra en case 2')
          return null
        default:
          return 0
      }
    }
  },
  mounted () {
    console.log(this.FILE_TYPES)
    console.log(this.params)
    if (this.params.type === 4) {
      this.companies = JSON.parse(JSON.stringify(this.params.companies))
      if (this.companies.length > 0) {
        let x = null
        for (let index = 0; index < this.companies.length; index++) {
          if (!this.companies[index].disabled) {
            this.companies[index].selected = true
            x = index
            break
          }
        }
        // this.companies.forEach(element => {
        //   if (!element.disabled) element.selected = true
        //   break
        // })
        // this.companies[0].selected = true
        console.log(this.companies, 'this.companies')
        console.log(this.companies[x], 'this.companies[x]')
        this.clientSelected = this.companies[x].laborRelation.clientId
        console.log(this.clientSelected)
        this.selectedCompany = true
      }
      this.data = JSON.parse(JSON.stringify(this.params.data))
      // this.companies = this.params.companies
    }
  }
}
</script>

<style lang="scss" scoped>

.little-advise {
  padding: 16px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.yellow {
    background-color: #FAECD2 !important;
  }
  &.blue {
    background-color: #ebf2fa !important;
  }
  & label {
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
   input[type="checkbox"].final-checkbox {
    transform: scale(1.5);
    min-width: 16px;
    min-height: 16px;
    margin: 6px 15px 0 0;
    cursor: pointer;
  }
}

// TO DO: CHANGE TO SCSS COMPONENTS
@media screen and (max-width: 769px) {
  .card__icon-container {
    position: relative;
  }
  .card__header {
    padding-left: 0px;
  }
  .card__columns-container {
    margin-left: -80px;
  }
  .card__columns-container.legal-advice{
    margin-left: -35px;
  }
}

</style>
