<template>
    <div>
      <ModalSpinner :data="modalData" v-if="isLookingAppointments"/>
        <Toast :params="toastParams" v-if="isToastVisible"/>
        <h1 class="subsection__title" v-if="!bookAppointmentOk">{{ $t('menu.myAppointments.requestAppointments') }}</h1>
        <div :class="showAbsenteeismBanner ? 'advice__absenteeism advice__absenteeism--open' : 'advice__absenteeism'">
          <SimpleAdvice :text="$t('absenteeismBannerText')" :iconClass="'icon-info-toast'" :showCloseIcon="false" v-if="showAbsenteeismBanner"/>
        </div>
        <div class="request-appoinment__container" v-if="!bookAppointmentOk">
          <!-- COMPANY -->
          <div class="request-appoinment__item" :class="{'request-appoinment__item--opened' : selectedGroup === NEW_APPOINMENT_GROUP.COMPANY, 'request-appoinment__item--disabled' : (selectedGroup !== NEW_APPOINMENT_GROUP.COMPANY && !isCompanySelected) || isFinish}">
            <div class="request-appoinment__header" @click="setSelectedGroup(NEW_APPOINMENT_GROUP.COMPANY)">
              <span class="request-appoinment__header__number">1</span>
              <span class="request-appoinment__header__title">{{ $t('requestAppoinment.selectCompany') }}</span>
            </div>
            <div class="request-appoinment__content">
              <!-- <SkeletonCard v-if="skeletonCompanyIsVisible"/> -->
              <div class="request-appoinment__company margin-top-20"
                v-for="(company, index) in appoinment.companies" :key="index"
                :class="{'request-appoinment__company--selected' : company.selected, 'request-appoinment__company--disabled' : company.disabled}"
                @click="setCompany(company)">
                <!--<span class="request-appoinment__company__icon icon icon-tick"></span>-->
                <span class="request-appoinment__company__icon icon" :class="{'icon-tick' : !company.disabled, 'icon-close' : company.disabled}"></span>
                <span class="request-appoinment__company__label">{{ company.name }}</span>
              </div>
              <div class="request-appoinment__content__button-container margin-top-20">
                <Button :label="$t('global.next')"
                  :classes="'button--cta-light button--icon-right'"
                  :isLoading="isLoading"
                  :icon="'icon-next'"
                  :disabledButton="!isCompanySelected"
                  @click.native="openNextGroup()" />
              </div>
            </div>
          </div>
          <!-- WORKPLACE -->
          <div class="request-appoinment__item" :class="{'request-appoinment__item--opened' : selectedGroup === NEW_APPOINMENT_GROUP.WORKCENTER, 'request-appoinment__item--disabled' : (selectedGroup !== NEW_APPOINMENT_GROUP.WORKCENTER && !isWorkPlaceSelected) || isFinish}">
            <div class="request-appoinment__header" @click="setSelectedGroup(NEW_APPOINMENT_GROUP.WORKCENTER)">
              <span class="request-appoinment__header__number">2</span>
              <span class="request-appoinment__header__title">{{ $t('requestAppoinment.selectCenter') }}</span>
            </div>
            <div class="request-appoinment__content">
              <!-- <SkeletonCard v-if="skeletonCompanyIsVisible"/> -->
              <div class="request-appoinment__company margin-top-20"
                v-for="(center, index) in availableWorkCenters" :key="index"
                :class="{'request-appoinment__company--selected' : center.selected, 'request-appoinment__company--disabled' : center.disabled}"
                @click="setWorkplaceCenter(center)">
                <!--<span class="request-appoinment__company__icon icon icon-tick"></span>-->
                <span class="request-appoinment__company__icon icon" :class="{'icon-tick' : !center.disabled, 'icon-close' : center.disabled}"></span>
                <span class="request-appoinment__company__label">{{ center.name }}</span>
              </div>
              <div class="request-appoinment__content__button-container margin-top-20">
                <Button :label="$t('global.next')"
                  :classes="'button--cta-light button--icon-right'"
                  :isLoading="isLoading"
                  :icon="'icon-next'"
                  :disabledButton="!isWorkPlaceSelected"
                  @click.native="openNextGroup()" />
              </div>
            </div>
          </div>
          <!-- MOTIVE -->
          <div class="request-appoinment__item" :class="{'request-appoinment__item--opened' : selectedGroup === NEW_APPOINMENT_GROUP.MOTIVE, 'request-appoinment__item--disabled' : (selectedGroup !== NEW_APPOINMENT_GROUP.MOTIVE && !isMotiveSelected) || isFinish}">
            <div class="request-appoinment__header" @click="setSelectedGroup(NEW_APPOINMENT_GROUP.MOTIVE)">
              <span class="request-appoinment__header__number">3</span>
              <span class="request-appoinment__header__title">{{ $t('requestAppoinment.appoinmentMotive') }}</span>
            </div>
            <div class="request-appoinment__content">
              <div class="request-appoinment__motive margin-top-20"
                v-for="(motive, index) in appoinment.motives" :key="index"
                :class="{'request-appoinment__motive--selected' : motive.selected}"
                @click="setMotive(motive)">
                <span class="request-appoinment__motive__icon icon icon-tick"></span>
                <div class="request-appoinment__motive__text-container">
                  <span class="request-appoinment__motive__title">{{ motive.name }}</span>
                  <span class="request-appoinment__motive__subtitle">{{ motive.subtitle }}</span>
                </div>
              </div>
              <div class="request-appoinment__content__button-container">
                <!--<div class="button button--cta-light button--icon-right" :class="{'button--disabled' : !isMotiveSelected}" @click="openNextGroup()">
                  <span class="button__label">{{ $t('global.next') }}</span>
                  <span class="button__icon icon icon-next"></span>
                </div>-->
                <Button :label="$t('global.next')"
                  :classes="'button--cta-light button--icon-right'"
                  :isLoading="isLoading"
                  :icon="'icon-next'"
                  :disabledButton="!isMotiveSelected"
                  @click.native="openNextGroup()" />
              </div>
            </div>
          </div>
          <!-- MEDICAL CENTER -->
          <div class="request-appoinment__item" :class="{'request-appoinment__item--opened' : selectedGroup === NEW_APPOINMENT_GROUP.MEDICAL_CENTER, 'request-appoinment__item--disabled' : (selectedGroup !== NEW_APPOINMENT_GROUP.MEDICAL_CENTER  && !isMedicalCenterSelected) || isFinish}">
            <div class="request-appoinment__header" @click="setSelectedGroup(NEW_APPOINMENT_GROUP.MEDICAL_CENTER)">
              <span class="request-appoinment__header__number">4</span>
              <span class="request-appoinment__header__title">{{ $t('requestAppoinment.chooseMedicalCenter') }}</span>
            </div>
            <div class="request-appoinment__content">
              <div class="request-appoinment__medical-center__filter">
                <div class="request-appoinment__medical-center__filter__group" v-if="getCurrentDevice > DEVICES.MOBILE">
                  <span class="request-appoinment__medical-center__filter__label">{{ $t('requestAppoinment.province') }}</span>
                  <SelectSearch
                  :data="provinces"
                  :itemLabel="'name'"
                  :itemSelected="getProvince"
                  :placeholder="'Selecciona una provincia'"
                  class="request-appoinment__medical-center__filter__input"
                  v-if="getCurrentUser"
                  @onSelect="setProvince($event)" />
                </div>
                <div class="request-appoinment__medical-center__filter__group" v-if="getCurrentDevice === DEVICES.MOBILE">
                  <span class="request-appoinment__medical-center__filter__label">{{ $t('requestAppoinment.province') }}</span>
                  <SelectSearch
                  :data="provinces"
                  :itemLabel="'label'"
                  :itemSelected="getProvince"
                  :placeholder="'Selecciona una provincia'"
                  class="request-appoinment__medical-center__filter__input"
                  v-if="getCurrentUser"
                  @onSelect="setProvince($event)" />
                </div>
                <!-- <div class="request-appoinment__medical-center__filter__group" v-if="getCurrentDevice > DEVICES.MOBILE"> -->
                <div class="request-appoinment__medical-center__filter__group">
                  <div class="input__container width-100-percent">
                    <span
                      class="input__icon icon"
                      @click="cleanInputFilter()"
                      :class="{'icon-clear' : inputFilter.length > 0}"
                    ></span>
                    <input type="text" class="input" v-model="searchInput" :placeholder="$t('requestAppoinment.medicalCenterPlaceholder')">
                  </div>
                </div>
              </div>
              <SkeletonCard v-if="skeletonIsVisible"/>
              <div class="request-appoinment__medical-center__no-matches margin-top-40" v-if="medicalCentersFiltered.length === 0 && !skeletonIsVisible">
                <img :src="noMedicalCenters" class="request-appoinment__medical-center__no-matches__image">
                <p class="request-appoinment__medical-center__no-matches__title" v-html="$t('requestAppoinment.noMatches', { province: selectedProvince.name})"></p>
                <p class="request-appoinment__medical-center__no-matches__text">{{ $t('requestAppoinment.trySearch') }} <span class="request-appoinment__medical-center__icon-group__label request-appoinment__medical-center__icon-group__label--link">{{ $t('requestAppoinment.completedList') }}</span></p>
              </div>
              <div class="request-appoinment__medical-center__container margin-top-20 scroll scroll2" v-if="medicalCentersFiltered.length > 0">
                <p class="margin-top-20" v-html="$t('requestAppoinment.availableCenters', { numAvailableCenters: medicalCentersFiltered.length })"></p>
                <div class="request-appoinment__medical-center"
                  v-for="(medicalCenter, index) in medicalCentersFiltered" :key="index"
                  :class="{'request-appoinment__medical-center--selected' : medicalCenter.selected}"
                  @click="setMedicalCenter(medicalCenter)">
                  <span class="request-appoinment__medical-center__icon icon" :class="medicalCenter.selected ? 'icon-tick' : 'icon-workplace'"></span>
                  <div class="request-appoinment__medical-center__content">
                    <span class="request-appoinment__medical-center__name">{{ medicalCenter.name }}</span>
                    <span class="request-appoinment__medical-center__location">{{ getLocation(medicalCenter) }}</span>
                    <div class="request-appoinment__medical-center__icon-group__container">
                      <!--<div class="request-appoinment__medical-center__icon-group request-appoinment__medical-center__icon-group--mobile-button">
                        <span class="request-appoinment__medical-center__icon-group__icon icon icon-phone-line"></span>
                        <a :href="`tel:${medicalCenter.phone}`" class="request-appoinment__medical-center__icon-group__label" v-if="getCurrentDevice === DEVICES.MOBILE">{{ $t('global.call')}}</a>
                        <span class="request-appoinment__medical-center__icon-group__label" v-if="getCurrentDevice > DEVICES.MOBILE">{{medicalCenter.phone}}</span>
                      </div>-->
                      <div class="request-appoinment__medical-center__icon-group request-appoinment__medical-center__icon-group--mobile-button" v-if="getLocationLink(medicalCenter)">
                        <span class="request-appoinment__medical-center__icon-group__icon icon icon-location"></span>
                        <a :href="getLocationLink(medicalCenter)" target="_blank" class="request-appoinment__medical-center__icon-group__label request-appoinment__medical-center__icon-group__label--link">{{ $t('myAppoinments.howToGetThere') }}</a>
                      </div>
                      <div class="request-appoinment__medical-center__icon-group request-appoinment__medical-center__icon-group--mobile-button request-appoinment__medical-center__icon-group--mobile-only-icon">
                        <span class="request-appoinment__medical-center__icon-group__icon icon icon-browse"></span>
                        <a :href="medicalCenter.website" target="_blank" class="request-appoinment__medical-center__icon-group__label request-appoinment__medical-center__icon-group__label--link"  v-if="getCurrentDevice !== DEVICES.MOBILE">{{ $t('myAppoinments.website') }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="request-appoinment__content__button-container margin-top-20">
                <!-- <div class="button button--cta-light button--icon-right" :class="{'button--disabled' : !isMedicalCenterSelected}" @click="openNextGroup()">
                  <span class="button__label">{{ $t('global.next') }}</span>
                  <span class="button__icon icon icon-next"></span>
                </div> -->
                <Button :label="$t('global.next')"
                  :classes="'button--cta-light button--icon-right'"
                  :isLoading="isLoading"
                  :icon="'icon-next'"
                  :disabledButton="!isMedicalCenterSelected"
                  @click.native="openNextGroup()" />
              </div>
            </div>
          </div>
          <!-- DATE -->
          <div class="request-appoinment__item" :class="{'request-appoinment__item--opened' : selectedGroup === NEW_APPOINMENT_GROUP.DATE, 'request-appoinment__item--disabled' : (selectedGroup !== NEW_APPOINMENT_GROUP.DATE && !isDateSelected) || isFinish}">
            <div class="request-appoinment__header" @click="setSelectedGroup(NEW_APPOINMENT_GROUP.DATE)">
              <span class="request-appoinment__header__number">5</span>
              <span class="request-appoinment__header__title">{{ $t('requestAppoinment.selectDate') }}</span>
            </div>
            <div class="request-appoinment__content">
              <div class="request-appoinment__date__filter">
                <Select
                  :data="availableMonths"
                  :itemLabel="'label'"
                  :itemSelected="getMonth"
                  :placeholder="'Selecciona una provincia'"
                  class="request-appoinment__medical-center__filter__input"
                  v-if="getCurrentUser"
                  @onSelect="setMonth($event)" />
                  <div class="request-appoinment__date__slider-container" v-if="getCurrentDevice > DEVICES.MOBILE ">
                    <span class="request-appoinment__date__slider-container__hour">08:00</span>
                    <vue-slider
                    :min="sliderMinHourValue"
                    :max="sliderMaxHourValue"
                    :interval="1"
                    :tooltip-formatter="hourFormatter"
                    :data-value="'id'"
                    :data-label="'name'"
                    v-model="hourFilter"
                    :tooltip="'active'"></vue-slider>
                    <span class="request-appoinment__date__slider-container__hour">19:00</span>
                    <span class="request-appoinment__date__slider-container__icon icon icon-reset" @click="resetHourFilter()"></span>
                  </div>
              </div>
              <div class="request-appoinment__date__calendar-hour-container">
                <div class="request-appoinment__date__calendar__container">
                <div class="request-appoinment__date__calendar">
                  <div class=" request-appoinment__date__calendar__weekday" v-for="(weekDay, index) in weekDays" :key="index">
                    <span class="request-appoinment__date__calendar__weekday__label">{{ weekDay }}</span>
                  </div>
                </div>
                <div class="request-appoinment__date__calendar">
                  <!--WHITE SPACES-->
                  <div class="request-appoinment__date__calendar__day--empty" v-for="(item, index) in monthWhiteSpaces" :key="index+50"></div>
                  <div class="request-appoinment__date__calendar__day" v-for="(day, index) in selectedMonthNumDays" :key="index"
                  :class="{'request-appoinment__date__calendar__day--selected' : day.selected, 'request-appoinment__date__calendar__day--disabled' : day.disabled}"
                  @click="setDay(day)">
                    <span class="request-appoinment__date__calendar__day__number">{{ day.number }}</span>
                  </div>
                </div>
              </div>
              <div class="request-appoinment__date__hour__container">
                <span class="request-appoinment__date__hour__title">{{ getCountAvailableHours }} plazas disponibles</span>
                <div class="request-appoinment__date__slider-container margin-bottom-20" v-if="getCurrentDevice === DEVICES.MOBILE ">
                    <span class="request-appoinment__date__slider-container__hour">08:00</span>
                    <vue-slider
                    :min="sliderMinHourValue"
                    :max="sliderMaxHourValue"
                    :interval="1"
                    :tooltip-formatter="hourFormatter"
                    :data-value="'id'"
                    :data-label="'name'"
                    v-model="hourFilter"
                    :tooltip="'active'"></vue-slider>
                    <span class="request-appoinment__date__slider-container__hour">19:00</span>
                    <span class="request-appoinment__date__slider-container__icon icon icon-reset" @click="resetHourFilter()"></span>
                  </div>
                <div class="request-appoinment__date__hour__list scroll scroll2">
                  <div class="request-appoinment__date__hour__group">
                    <div class="request-appoinment__date__hour" v-for="(hour, index) in getAvailableHours" :key="index"
                      :class="{'request-appoinment__date__hour--selected' : hour.selected}"
                      @click="setHour(hour)">
                      <span class="request-appoinment__date__hour__label">{{ hour.hour }}</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="request-appoinment__content__button-container">
                <div class="button button--cta-light button--icon-right" :class="{'button--disabled' : !isDateSelected}" @click="openNextGroup()">
                  <span class="button__label">{{ $t('global.next') }}</span>
                  <span class="button__icon icon icon-next"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- CONFIRM -->
          <div class="request-appoinment__item" :class="{'request-appoinment__item--opened' : selectedGroup === NEW_APPOINMENT_GROUP.CONFIRM, 'request-appoinment__item--disabled' : selectedGroup !== NEW_APPOINMENT_GROUP.CONFIRM}">
            <div class="request-appoinment__header" @click="setSelectedGroup(NEW_APPOINMENT_GROUP.CONFIRM)">
              <span class="request-appoinment__header__number">6</span>
              <span class="request-appoinment__header__title">{{ $t('requestAppoinment.confirmAppoinment') }}</span>
            </div>
            <div class="request-appoinment__content">
                <p class="request-appoinment__text">{{ $t('requestAppoinment.readyText') }}</p>
                <div class="request-appoinment__resume" v-if="resumeAppoinment.motive">
                  <div class="form-group request-appoinment__form-group">
                    <span class="form-group__label">{{ $t('requestAppoinment.motive') }}</span>
                    <div class="form-group__data bold">{{ resumeAppoinment.motive.name }}</div>
                  </div>
                  <div class="form-group request-appoinment__form-group" v-if="resumeAppoinment.medicalCenter">
                    <span class="form-group__label">{{ $t('requestAppoinment.center') }}</span>
                    <div class="form-group__data">
                      <div class="request-appoinment__medical-center__content">
                        <span class="request-appoinment__medical-center__name line-height-inherit">{{ resumeAppoinment.medicalCenter.name }}</span>
                        <span class="request-appoinment__medical-center__location">{{ getLocation(resumeAppoinment.medicalCenter) }}</span>
                        <div class="request-appoinment__medical-center__icon-group__container">
                          <!--<div class="request-appoinment__medical-center__icon-group" v-if="resumeAppoinment.medicalCenter.phone">
                            <span class="request-appoinment__medical-center__icon-group__icon icon icon-phone-line"></span>
                            <a :href="`tel:${resumeAppoinment.medicalCenter.phone}`" class="request-appoinment__medical-center__icon-group__label" v-if="getCurrentDevice === DEVICES.MOBILE">{{resumeAppoinment.medicalCenter.phone}}</a>
                            <span class="request-appoinment__medical-center__icon-group__label" v-if="getCurrentDevice > DEVICES.MOBILE">{{resumeAppoinment.medicalCenter.phone}}</span>
                          </div>-->
                          <div class="request-appoinment__medical-center__icon-group" v-if="getLocationLink(resumeAppoinment.medicalCenter)">
                            <span class="request-appoinment__medical-center__icon-group__icon icon icon-location"></span>
                            <a :href="getLocationLink(resumeAppoinment.medicalCenter)" target="_blank" class="request-appoinment__medical-center__icon-group__label request-appoinment__medical-center__icon-group__label--link">{{ $t('myAppoinments.howToGetThere') }}</a>
                          </div>
                          <div class="request-appoinment__medical-center__icon-group">
                            <span class="request-appoinment__medical-center__icon-group__icon icon icon-phone-line"></span>
                            <a :href="resumeAppoinment.medicalCenter.website" target="_blank" class="request-appoinment__medical-center__icon-group__label request-appoinment__medical-center__icon-group__label--link">{{ $t('myAppoinments.website') }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group request-appoinment__form-group">
                    <span class="form-group__label">{{ $t('requestAppoinment.day') }}</span>
                    <div class="form-group__data bold">{{ getAppointmentDate }}</div>
                  </div>
                  <div class="form-group request-appoinment__form-group margin-bottom-0">
                    <span class="form-group__label">{{ $t('requestAppoinment.hour') }}</span>
                    <div class="form-group__data bold" v-if="selectedHour">{{ selectedHour.hour }}</div>
                  </div>
                </div>
                <p class="request-appoinment__text margin-top-20" v-html="$t('requestAppoinment.reciveEmail', {username: username})"></p>
                <div class="request-appoinment__content__button-container">
                <Button :label="$t('global.end')"
                  :classes="'button--cta-light button--icon-right'"
                  :isLoading="isLoading"
                  :icon="'icon-next'"
                  :disabledButton="isLoading"
                  @click.native="bookAppointment()" />
              </div>
            </div>
          </div>
        </div>
        <!-- RESUME APPOINTMENT -->
        <div class="request-appoinment__item" :class="{'request-appoinment__item--opened' : bookAppointmentOk, 'request-appoinment__item--disabled' : !bookAppointmentOk}">
          <div class="request-appoinment__container" v-if="bookAppointmentOk">
            <div class="request-appoinment__content">
              <p class="request-appoinment__text">{{ $t('requestAppoinment.resumeTextTitle') }}</p>
              <div class="request-appoinment__resume" v-if="resumeAppoinment.motive">
                <div class="form-group request-appoinment__form-group">
                  <span class="form-group__label">{{ $t('requestAppoinment.motive') }}</span>
                  <div class="form-group__data bold">{{ resumeAppoinment.motive.name }}</div>
                </div>
                <div class="form-group request-appoinment__form-group" v-if="resumeAppoinment.medicalCenter">
                  <span class="form-group__label">{{ $t('requestAppoinment.center') }}</span>
                  <div class="form-group__data">
                    <div class="request-appoinment__medical-center__content">
                      <span class="request-appoinment__medical-center__name line-height-inherit">{{ resumeAppoinment.medicalCenter.name }}</span>
                      <span class="request-appoinment__medical-center__location">{{ getLocation(resumeAppoinment.medicalCenter) }}</span>
                      <div class="request-appoinment__medical-center__icon-group__container">
                        <!--<div class="request-appoinment__medical-center__icon-group" v-if="resumeAppoinment.medicalCenter.phone">
                          <span class="request-appoinment__medical-center__icon-group__icon icon icon-phone-line"></span>
                          <a :href="`tel:${resumeAppoinment.medicalCenter.phone}`" class="request-appoinment__medical-center__icon-group__label" v-if="getCurrentDevice === DEVICES.MOBILE">{{resumeAppoinment.medicalCenter.phone}}</a>
                          <span class="request-appoinment__medical-center__icon-group__label" v-if="getCurrentDevice > DEVICES.MOBILE">{{resumeAppoinment.medicalCenter.phone}}</span>
                        </div>-->
                        <div class="request-appoinment__medical-center__icon-group" v-if="getLocationLink(resumeAppoinment.medicalCenter)">
                          <span class="request-appoinment__medical-center__icon-group__icon icon icon-location"></span>
                          <a :href="getLocationLink(resumeAppoinment.medicalCenter)" target="_blank" class="request-appoinment__medical-center__icon-group__label request-appoinment__medical-center__icon-group__label--link">{{ $t('myAppoinments.howToGetThere') }}</a>
                        </div>
                        <div class="request-appoinment__medical-center__icon-group">
                          <span class="request-appoinment__medical-center__icon-group__icon icon icon-phone-line"></span>
                          <a :href="resumeAppoinment.medicalCenter.website" class="request-appoinment__medical-center__icon-group__label request-appoinment__medical-center__icon-group__label--link">{{ $t('myAppoinments.website') }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group request-appoinment__form-group">
                  <span class="form-group__label">{{ $t('requestAppoinment.day') }}</span>
                  <div class="form-group__data bold">{{ getAppointmentDate }}</div>
                </div>
                <div class="form-group request-appoinment__form-group margin-bottom-0">
                  <span class="form-group__label">{{ $t('requestAppoinment.hour') }}</span>
                  <div class="form-group__data bold" v-if="selectedHour">{{ selectedHour.hour }}</div>
                </div>
              </div>
              <p class="request-appoinment__text margin-top-20" v-html="$t('requestAppoinment.reciveEmail', {username: username})"></p>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import NoMatches from '@/assets/images/no-matches.png'
import Select from '@/components/ui/Select.vue'
import SelectSearch from '@/components/ui/SelectSearch.vue'
import appointmentService from '@/services/appointmentService.js'
import ModalSpinner from '@/components/commons/ModalSpinner.vue'
import Button from '@/components/ui/Button.vue'
import Toast from '@/components/toasts/Toast.vue'
import * as _modules from '../../_helpers/modules.helper.js'
import SkeletonCard from '@/components/commons/SkeletonCard.vue'
import SimpleAdvice from '@/components/ui/SimpleAdvice.vue'

export default {
  name: 'RequestAppoinments',
  components: { Select, Button, ModalSpinner, Toast, SelectSearch, SkeletonCard, SimpleAdvice },
  data () {
    return {
      modalData: {
        title: this.$t('myAppoinments.searchingAvailableAppointments'),
        text: this.$t('myAppoinments.pleaseWait')
      },
      availableWorkCenters: null,
      skeletonCompanyIsVisible: false,
      skeletonIsVisible: false,
      bookAppointmentOk: false,
      isFinish: false,
      isToastVisible: false,
      toastParams: null,
      isLookingAppointments: false,
      searchInput: '',
      inputFilter: '',
      sliderMinHourValue: 8,
      sliderMaxHourValue: 19,
      monthWhiteSpaces: null,
      isLoading: false,
      isFinishLoading: false,
      today: moment(),
      currentYear: moment().year(),
      availableMonths: [],
      weekDays: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
      selectedMonth: { number: moment().add(1, 'd').month(), label: moment().format('MMMM'), year: moment().format('YYYY') },
      selectedMonthNumDays: [],
      selectedMonthFirstDay: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      selectedMonthWeekFirstDay: moment().weekday(this.selectedMonthFirstDay),
      selectedMonthWeekDayName: moment(this.selectedMonthFirstDay).format('dddd'),
      NEW_APPOINMENT_GROUP: Vue.CONSTANTS.NEW_APPOINMENT_GROUP,
      DEVICES: Vue.CONSTANTS.DEVICES,
      selectedGroup: null,
      isCompanySelected: false,
      isWorkPlaceSelected: false,
      isMotiveSelected: false,
      isMedicalCenterSelected: false,
      isHourSelected: false,
      isDateSelected: false,
      selectedProvince: null,
      selectedDay: null,
      selectedHour: null,
      hasError: false,
      clientModules: null,
      showAbsenteeismBanner: false,
      appointmentErrors: [
        { code: 8100, message: 'ERROR TRABAJADOR SIN RELACION LABORAL' },
        { code: 8101, message: 'ERROR_HUECO_NO_DISPONIBLE' },
        { code: 8102, message: 'ERROR_CALCULAR_RUTA_PROVEEDOR' },
        { code: 8103, message: 'ERROR_CITA_WEB_DESACTIVADA' },
        { code: 8104, message: 'ERROR_CITA_NO_EXISTE' },
        { code: 8105, message: 'ERROR AL ANULAR CITA' },
        { code: 8106, message: 'ERROR_CALENDARIO_PROVEEDOR_NO_EXISTE' },
        { code: 8107, message: 'ERROR_CALENDARIO_CLIENTE_GRUPO_NO_EXISTE' },
        { code: 8108, message: 'ERROR_TRABAJADOR_CITA_FUTURA_EXISTENTE' },
        { code: 8109, message: 'ERROR_TRABAJADOR_LIMITE_CITAS' }
      ],
      hourFilter: [],
      hourFormatter: '{value}:00',
      provinceList: [],
      provinces: [],
      availableDays: [],
      availableHours: [],
      hourList: [
      ],
      appoinment: {
        companies: [],
        workcenters: [],
        motives: [
          { id: 1, name: this.$t('requestAppoinment.Initial'), subtitle: this.$t('requestAppoinment.InitialDescription') },
          { id: 2, name: this.$t('requestAppoinment.Periodical'), subtitle: this.$t('requestAppoinment.PeriodicalDescription') }
        ],
        medicalCenters: [
          /*
          { name: 'Centro médico 1', location: 'C/ Antracita, 2', city: 'Madrid', province: 'Madrid', phone: '666 66 66 66', lat: '40.3896461', lng: '-3.6916954', website: 'https://www.google.es' },
          { name: 'Centro médico 2', location: 'C/ Diego de León, 12', city: 'Madrid', province: 'Madrid', phone: '666 66 66 66', lat: '49.46800006494457', lng: '17.11514008755796', website: 'https://www.google.es' },
          { name: 'Centro médico 3', location: 'C/ Maestro Arbós, 13', city: 'Madrid', province: 'Madrid', phone: '666 66 66 66', lat: '49.46800006494457', lng: '17.11514008755796', website: 'https://www.google.es' },
          { name: 'Centro médico 4', location: 'C/ Mayor, 50', city: 'BADAJOZ', province: 'BADAJOZ', phone: '666 66 66 66', lat: '49.46800006494457', lng: '17.11514008755796', website: 'https://www.google.es' },
          { name: 'Centro médico 5', location: 'C/ Príncipe de Vergara, 25', city: 'Madrid', province: 'Madrid', phone: '666 66 66 66', lat: '49.46800006494457', lng: '17.11514008755796', website: 'https://www.google.es' },
          { name: 'Centro médico 6', location: 'C/ La Rambla, 25', city: 'Barcelona', province: 'Barcelona', phone: '666 66 66 66', lat: '49.46800006494457', lng: '17.11514008755796', website: 'https://www.google.es' },
          { name: 'Centro médico 7', location: 'C/ Mayor, 25', city: 'Segovia', province: 'Segovia', phone: '666 66 66 66', lat: '49.46800006494457', lng: '17.11514008755796', website: 'https://www.google.es' },
          { name: 'Centro médico 8', location: 'C/ Lucena, 1', city: 'segovia', province: 'Segovia', phone: '666 66 66 66', lat: '49.46800006494457', lng: '17.11514008755796', website: 'https://www.google.es' }
        */
        ],
        medicalCentersLast: [],
        availableDates: [],
        availableProvinces: [
          { label: 'Álava' },
          { label: 'Asturias' },
          { label: 'BADAJOZ' },
          { label: 'Barcelona' },
          { label: 'Bizkaia' },
          { label: 'Burgos' },
          { label: 'Cuenca' },
          { label: 'Granada' },
          { label: 'Madrid' },
          { label: 'Segovia' },
          { label: 'Sevilla' },
          { label: 'Tarragona' }
        ]
      },
      resumeAppoinment: {
        company: null,
        workcenter: null,
        motive: null,
        medicalCenter: null,
        date: null
      }
    }
  },
  mounted () {
    // console.log()
    this.initial()
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getCurrentDevice', 'getProvincesStatus', 'getProvinces', 'getLocale']),
    getCountAvailableHours () {
      const arr = this.availableHours.filter(element => {
        const h = parseInt(moment(element.hour, 'hh:mm').format('H'))
        // console.log()
        // console.log()
        // console.log()
        if (h < (this.hourFilter[1]) && h > (this.hourFilter[0] - 1)) {
          return element
        }
      })
      return arr.length
    },
    getAvailableHours () {
      return this.availableHours.filter(element => {
        const h = parseInt(moment(element.hour, 'hh:mm').format('H'))
        // console.log()
        // console.log()
        // console.log()
        if (h < (this.hourFilter[1]) && h > (this.hourFilter[0] - 1)) {
          return element
        }
      })
    },
    username () {
      return this.getCurrentUser.email
    },
    getAppointmentDate () {
      if (!this.selectedMonth || !this.selectedDay || !this.selectedHour) {
        return '-'
      }
      const date = moment(this.selectedDay.number + '/' + (this.selectedMonth.number + 1) + '/' + this.selectedMonth.year, 'D/MM/YYYY').format('DD MMMM YYYY')
      return moment(date, 'DD MMMM YYYY').format('LL') + ', ' + moment(date, 'DD MMMM YYYY').format('dddd')
    },
    getProvince () {
      // console.log()
      let selectedProvince = {}
      if (this.provinces.length === 0) {
        this.$set(selectedProvince, 'name', null)
        this.setProvince(selectedProvince)
      } else if (this.selectedProvince && this.selectedProvince.name) {
        selectedProvince = this.selectedProvince
      } else {
        if (this.getCurrentUser.province) {
          this.provinces.forEach(province => {
            if (province.name.toLowerCase() === this.getCurrentUser.province.toLowerCase()) {
              selectedProvince = province
            }
          })
        } else {
          selectedProvince = this.provinces[0]
        }
      }
      return selectedProvince
    },
    getMonth () {
      let selectedMonth = {}
      if (this.selectedMonth) {
        selectedMonth = this.selectedMonth
        // this.getAppointmentAvailability()
      }
      return selectedMonth
    },
    noMedicalCenters () {
      return NoMatches
    },
    medicalCentersFiltered () {
      return this.appoinment.medicalCenters.filter(medicalCenter => {
        return medicalCenter.city.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1
      })
    }
  },
  filters: {
    moment: function (selectedMonth, selectedDay, selectedHour) {
      if (!selectedMonth || !selectedDay || !selectedHour) {
        return '-'
      }
      return moment(selectedMonth).format('DD MMMM YYYY')
    }
  },
  methods: {
    initial: function () {
      // console.log()
      this.getCompanies()
      this.hourFilter = [this.sliderMinHourValue, this.sliderMaxHourValue]
      this.selectedGroup = this.NEW_APPOINMENT_GROUP.COMPANY
      this.appoinment.companies.forEach(element => {
        this.$set(element, 'selected', false)
      })
      this.appoinment.motives.forEach(element => {
        this.$set(element, 'selected', false)
      })
      this.appoinment.medicalCenters.forEach(element => {
        this.$set(element, 'selected', false)
      })
      this.setNumDays()
      this.setAvailableMonths()
      // this.getAvailableCenter()
      this.initialProvinces()
    },
    initialProvinces: function () {
      if (this.getProvinces !== null) {
        this.provinces = this.getProvinces
        // this.setInitialProvince()
      }
      if (this.getProvincesStatus === 'stopped') this.$store.dispatch('fetchApi')
    },
    appointmentResult: function (message, type) {
      this.isToastVisible = true
      this.toastParams = {
        type: type,
        text: `${message}`,
        time: 5000
      }
      setTimeout(() => {
        this.isToastVisible = false
      }, 4000)
    },
    cleanInputFilter () {
      this.inputFilter = ''
    },
    resetHourFilter () {
      this.hourFilter = [this.sliderMinHourValue, this.sliderMaxHourValue]
    },
    getLocation (medicalCenter) {
      if (medicalCenter.location) {
        return medicalCenter.location + ', ' + medicalCenter.city
      } else {
        return medicalCenter.city
      }
    },
    getLocationLink (medicalCenter) {
      if (medicalCenter.lat === '0.0' && medicalCenter.lng === '0.0') {
        return null
      } else {
        return `http://www.google.com/maps/place/${medicalCenter.lat},${medicalCenter.lng}`
      }
    },
    setNumDays () {
      if (this.selectedMonthNumDays.length !== 0) {
        this.selectedMonthNumDays = []
      }
      const n = moment().month(this.selectedMonth.number).daysInMonth()
      // console.log()
      for (let i = 1; i <= n; i++) {
        const l = {
          number: i
        }
        this.$set(l, 'selected', false)
        this.$set(l, 'disabled', true)
        this.selectedMonthNumDays.push(l)
      }
      this.monthWhiteSpaces = moment().month(this.selectedMonth.number).startOf('month').isoWeekday() - 1
      // console.log()
      // console.log()
    },
    setDay (day) {
      this.selectedMonthNumDays.forEach(element => {
        if (day.number === element.number) {
          element.selected = true
          this.selectedDay = element
          this.getAvailablesHours()
        } else {
          element.selected = false
        }
      })
    },
    setHour (hour) {
      this.isDateSelected = true
      this.availableHours.forEach(element => {
        if (hour.hour === element.hour) {
          element.selected = true
          this.selectedHour = hour
        } else {
          element.selected = false
        }
      })
    },
    setCompany (company) {
      // console.log()
      if (!company.disabled) {
        this.appoinment.companies.forEach(element => {
          if (company === element) {
            element.selected = true
            this.resumeAppoinment.company = element
            this.isCompanySelected = true
          } else {
            element.selected = false
          }
        })
        this.isWorkPlaceSelected = false
        this.resumeAppoinment.workcenter = null
        this.availableWorkCenters = this.appoinment.workcenters.filter(x => x.clientId === this.resumeAppoinment.company.id)
        // console.log()
        const cModule = this.clientModules.find(x => x.clientId === company.id)
        // console.log()
        if (cModule) {
          if (cModule.absenteeism) {
            this.showAbsenteeismBanner = true
          } else {
            this.showAbsenteeismBanner = false
          }
        }
      }
    },
    setWorkplaceCenter (center) {
      // console.log()
      if (!center.disabled) {
        this.appoinment.workcenters.forEach(element => {
          if (center === element) {
            element.selected = true
            this.resumeAppoinment.workcenter = element
            this.isWorkPlaceSelected = true
          } else {
            element.selected = false
          }
        })
        // console.log()
      }
    },
    setMotive (motive) {
      this.appoinment.motives.forEach(element => {
        if (motive === element) {
          element.selected = true
          this.resumeAppoinment.motive = element
          this.isMotiveSelected = true
        } else {
          element.selected = false
        }
      })
    },
    setProvince (province) {
      // console.log()
      this.selectedProvince = province
      if (!this.selectedProvince.name) {
        return
      }
      this.appoinment.medicalCenters = []
      this.getMedicalCenters().finally(() => {
        this.isLoading = false
        this.skeletonIsVisible = false
      })
    },
    setMonth (month) {
      // console.log()
      if (this.selectedMonth !== month) {
        this.selectedMonth = month
        this.availableHours = []
        this.setNumDays()
      }
      this.isLoading = true
      const l = this.getDates()
      this.isLookingAppointments = true
      this.getAppointmentAvailability(l).finally(() => {
        this.isLoading = false
        this.isLookingAppointments = false
      })
    },
    setMedicalCenter (medicalCenter) {
      this.appoinment.medicalCenters.forEach(element => {
        if (medicalCenter === element) {
          element.selected = true
          this.resumeAppoinment.medicalCenter = element
          this.isMedicalCenterSelected = true
        } else {
          element.selected = false
        }
      })
    },
    setDate (date) {
      this.appoinment.medicalCenters.forEach(element => {
        if (date === element) {
          element.selected = true
          this.resumeAppoinment.date = element
        } else {
          element.selected = false
        }
      })
    },
    setAvailableMonths () {
      // const currentMonth = moment().add(1, 'd').month()
      // const finMonth = moment().add(1, 'd').add(3, 'M').month()
      const iMonth = moment().add(1, 'd').month()
      const fMonth = iMonth + 2
      this.availableMonths = []
      for (let i = iMonth; (i <= fMonth); i++) {
        let m
        let y = null
        if (i >= 12) {
          m = i - 12
          y = moment().add(1, 'y').year()
        } else {
          m = i
        }
        if (moment().month(m) >= moment().add(1, 'd').month()) {
          if (y) {
            this.availableMonths.push({ number: moment().month(m).month(), name: moment().locale(this.getLocale).month(m).format('MMMM'), year: moment().year(y).month(m).format('YYYY') })
          } else {
            this.availableMonths.push({ number: moment().month(m).month(), name: moment().locale(this.getLocale).month(m).format('MMMM'), year: moment().month(m).format('YYYY') })
          }
        }
      }
      this.availableMonths.forEach(element => {
        this.$set(element, 'selected', false)
      })
      for (let i = 8; i < 24; i++) {
        let h = 0
        if (i < 10) {
          h = '0' + i
        }
        const item = { id: i, name: h }
        this.hourList.push(item)
      }
    },
    getAllowAppointment () {
      const data = {
        clientId: this.resumeAppoinment.company.id,
        workcenterId: this.resumeAppoinment.workcenter.id
      }
      return appointmentService.getAllowAppointment(data)
    },
    openNextGroup () {
      if (this.selectedGroup === this.NEW_APPOINMENT_GROUP.WORKCENTER) {
        this.isWorkPlaceSelected = true
        this.isLoading = true
        this.getAllowAppointment()
          .then((response) => {
            console.log(response)
            switch (response.data) {
              case true:
                this.isLoading = false
                this.selectedGroup += 1
                break
              case false:
                this.isLoading = false
                this.appointmentResult(this.$t('requestAppoinment.apiErrors.8103'), 'danger')
                this.hasError = true
                this.initial()
                break
              default:
                this.isLoading = false
                this.appointmentResult(this.$t('errorMessage.generic'), 'danger')
                this.hasError = true
                this.initial()
                break
            }
          })
          .catch(() => {
            this.isLoading = false
            const type = 'danger'
            const a = 'errorMessage.generic'
            // console.log()
            const message = this.$t(a)
            // console.log()
            this.appointmentResult(message, type)
            this.hasError = true
            this.initial()
          })
        return
      }
      if (this.selectedGroup === this.NEW_APPOINMENT_GROUP.DATE) {
        this.selectedGroup += 1
      }
      if (this.selectedGroup === this.NEW_APPOINMENT_GROUP.MEDICAL_CENTER) {
        this.isLoading = true
        this.selectedGroup += 1
        this.isLookingAppointments = true
        this.selectedMonth = { number: moment().add(1, 'd').month(), label: moment().locale(this.getLocale).add(1, 'd').format('MMMM'), year: moment().format('YYYY') }
        this.setNumDays()
        const l = this.getDates()
        this.getAppointmentAvailability(l).finally(() => {
          this.isLoading = false
          this.isLookingAppointments = false
          if (this.hasError) {
            this.hasError = false
            this.selectedGroup -= 1
          }
        })
      }
      if (this.selectedGroup === this.NEW_APPOINMENT_GROUP.COMPANY) {
        this.selectedGroup += 1
      } else if (this.selectedGroup === this.NEW_APPOINMENT_GROUP.MOTIVE) {
        this.isLoading = true
        if (this.getProvincesStatus === 'pending') {
          // console.log()
        }

        const a = this.provinces.find(x => x.id === this.getCurrentUser.provinceId)
        if (a) {
          this.selectedProvince = a
        } else {
          this.selectedProvince = this.provinces[0]
        }

        this.getMedicalCenters().finally(() => {
          this.isLoading = false
          this.skeletonIsVisible = false
          this.selectedGroup += 1
        })
      }
    },
    setSelectedGroup (group) {
      this.selectedGroup = group
    },
    /*
    getProvinces () {
      return userService.getProvinces()
        .then((response) => {
          // console.log()
          this.provinces = response.data
        })
        .catch((error) => {
          // console.log()
        })
    },
    */
    getAvailableCenter () {
      appointmentService.getAvailableCenter(7941, 6, 1)
        .then((response) => {
          // console.log()
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
        })
    },
    getCompanies () {
      // console.log()
      this.clientModules = _modules.getClientModules()
      this.isWorkPlaceSelected = false
      this.resumeAppoinment.workcenter = null
      this.skeletonCompanyIsVisible = true
      appointmentService.getCompanies()
        .then((response) => {
          // console.log()
          response.data.forEach(company => {
            if (!this.appoinment.companies.find(x => x.id === company.companyId)) {
              const c = { name: company.companyName, id: company.companyId, selected: false, disabled: true }
              const cModule = this.clientModules.find(x => x.clientId === c.id)
              // console.log()
              if (cModule) c.disabled = !cModule.vs
              this.appoinment.companies.push(c)
            }
            if (!this.appoinment.workcenters.find(x => x.id === company.workplaceId)) {
              // TO DO: DISABLE IF VS OR NOT
              const w = { name: company.workplaceName, id: company.workplaceId, selected: false, clientId: company.companyId }
              this.appoinment.workcenters.push(w)
            }
          })
        })
        .catch(() => {
          // console.log()
        })
        .finally(() => {
          // console.log()
          this.skeletonCompanyIsVisible = false
        })
    },
    getCompaniesTest () {
      this.getCurrentUser.clientModules.forEach(module => {
        appointmentService.getCompany(module.clientId)
          .then((response) => {
            if (response.data !== null && response.data !== '') {
              const c = { name: response.data, id: module.clientId }
              this.appoinment.companies.push(c)
            }
          })
          .catch(() => {
            // console.log()
          })
          .finally(() => {
            // console.log()
          })
      })
    },
    getMedicalCenters () {
      this.checkMedicalCenterIfExist()
      this.skeletonIsVisible = true
      // return appointmentService.getMedicalCenters(this.selectedProvince.name)
      return appointmentService.getAvailableCenter(this.resumeAppoinment.company.id, this.resumeAppoinment.workcenter.id, this.selectedProvince.id, this.resumeAppoinment.motive.id)
      // return appointmentService.getAvailableCenter(7941, 6, 1)
        .then((response) => {
          // console.log()
          this.appoinment.medicalCenters = response.data
          this.$set(this.appoinment.medicalCentersLast, 'provinceId', this.selectedProvince.id)
          this.$set(this.appoinment.medicalCentersLast, 'companyId', this.resumeAppoinment.company.id)
          this.$set(this.appoinment.medicalCentersLast, 'motiveId', this.resumeAppoinment.motive.id)
        })
        .catch((error) => {
          // console.log()
          const type = 'danger'
          const a = 'requestAppoinment.apiErrors.' + error.response.data.message
          // console.log()
          const message = this.$t(a)
          // console.log()
          this.appointmentResult(message, type)
          this.hasError = true
        })
    },
    checkMedicalCenterIfExist () {
      if (this.appoinment.medicalCentersLast) {
        if (this.appoinment.medicalCentersLast.provinceId === this.selectedProvince.id && this.appoinment.medicalCentersLast.companyId === this.resumeAppoinment.company.id && this.appoinment.medicalCentersLast.motiveId === this.resumeAppoinment.motive.id) return true
      }
      return false
    },
    getAppointmentAvailability (l) {
      return appointmentService.getAppointmentAvailability(l.start, l.end, this.resumeAppoinment.company.id, this.resumeAppoinment.medicalCenter.id)
        .then((response) => {
          // console.log()
          this.getAvailablesMonths(response.data)
        })
        .catch((error) => {
          // console.log()
          const type = 'danger'
          const a = 'requestAppoinment.apiErrors.' + error.response.data.message
          // console.log()
          const message = this.$t(a)
          // console.log()
          this.appointmentResult(message, type)
          this.hasError = true
        })
    },
    getDates () {
      const l = {
        start: null,
        end: null
      }
      if (this.selectedMonth.number === moment().month()) {
        l.start = moment().add(1, 'd').format('DD-MM-YYYY')
        l.end = moment(l.start, 'DD-MM-YYYY').endOf('month').format('DD-MM-YYYY')
      } else {
        l.start = moment().month(this.selectedMonth.number).year(this.selectedMonth.year).startOf('month').format('DD-MM-YYYY')
        l.end = moment().month(this.selectedMonth.number).year(this.selectedMonth.year).endOf('month').format('DD-MM-YYYY')
      }
      return l
    },
    getAvailablesMonths (data) {
      // console.log()
      this.availableDays = []
      data.forEach(day => {
        this.selectedMonthNumDays.forEach(element => {
          if (element.number === moment(day.fechaDisponible, 'DD/MM/YYYY').date()) {
            element.disabled = false
          }
        })
        const l = {
          day: moment(day.fechaDisponible, 'DD/MM/YYYY').date(),
          availableHours: []
        }
        day.citasDisponibles.forEach(hour => {
          l.availableHours.push({ hour: moment(hour.horaDisponible, 'DD/MM/YYYY HH:mm').format('HH:mm'), selected: false })
        })
        // console.log()
        this.availableDays.push(l)
      })
      this.setFirstDateSelected(data)
      const x = this.availableDays.sort(function (a, b) {
        console.log(a)
        return new Date(b.day) - new Date(a.day)
      })
      console.log(x)
    },
    setFirstDateSelected (data) {
      let daySelected = 0
      let hourSelected = moment('23:59', 'HH:mm')
      data.forEach(day => {
        if (daySelected === 0) {
          daySelected = moment(day.fechaDisponible, 'DD/MM/YYYY').date()
        } else {
          if (moment(day.fechaDisponible, 'DD/MM/YYYY').date() < daySelected) daySelected = moment(day.fechaDisponible, 'DD/MM/YYYY').date()
        }
      })
      const x = data.find(d => moment(d.fechaDisponible, 'DD/MM/YYYY').date() === daySelected)
      if (x) {
        x.citasDisponibles.forEach(hour => {
          const a = moment(moment(hour.horaDisponible, 'DD/MM/YYYY HH:mm').format('HH:mm'), 'HH:mm')
          if (a.isBefore(hourSelected)) hourSelected = a
        })
        if (daySelected !== 0 && hourSelected !== moment('23:59', 'HH:mm')) {
          console.log(this.availableDays.find(p => p.day === daySelected))
          this.setDay({ number: daySelected })
          console.log(this.availableHours[0].hour, moment(hourSelected, 'HH:mm').format('HH:mm'))
          this.setHour(this.availableHours.find(p => p.hour === moment(hourSelected, 'HH:mm').format('HH:mm')))
        }
      }
    },
    getAvailablesHours () {
      if (this.availableDays) {
        this.isDateSelected = false
        const i = this.availableDays.find(x => x.day === this.selectedDay.number)
        // console.log()
        if (i) {
          this.availableHours = i.availableHours
        } else {
          this.availableHours = []
        }
      }
    },
    bookAppointment () {
      this.isFinish = true
      this.isLoading = true
      const initDate = moment(this.selectedDay.number, 'D').format('DD') + '-' + moment().month(this.selectedMonth.number).format('MM') + '-' + this.selectedMonth.year + ' ' + this.selectedHour.hour
      appointmentService.bookAppointment(initDate, this.getCurrentUser.workerId, this.resumeAppoinment.motive.id, this.resumeAppoinment.company.id, this.resumeAppoinment.medicalCenter.id)
        .then((response) => {
          // console.log()
          if (response.data[0].citasConErrores === null) {
            const message = 'Cita creada correctamente'
            const type = 'success'
            this.appointmentResult(message, type)
            // add appointment to the list in the session storage
            appointmentService.getReasons(1, this.resumeAppoinment.motive.id)
              .then((response) => {
                // console.log()
                this.addAppointment(response.data)
                this.bookAppointmentOk = true
              })
              .catch(() => {
                // console.log()
              })
              .finally(() => {
                // console.log()
                window.location.reload()
              })
          } else {
            let r
            response.data.forEach(element => {
              r = this.appointmentErrors.find(x => x.code === element.citasConErrores[0].codigoErrorCita)
            })
            const type = 'danger'
            let message = ''
            if (r) {
              message = r.message
            } else {
              message = 'ERROR'
            }
            this.appointmentResult(message, type)
          }
        })
        .catch((error) => {
          // console.log()
          const type = 'danger'
          let a = ''
          try {
            a = 'requestAppoinment.apiErrors.' + error.response.data.message
          } catch (err) {
            // console.log()
            a = 'error'
          }
          const message = this.$t(a)
          this.appointmentResult(message, type)
        })
        .finally(() => {
          this.isLoading = false
          // console.log()
        })
    },
    addAppointment (appointment) {
      const initDate = this.selectedDay.number + '-' + moment().month(this.selectedMonth.number).format('MM') + '-' + this.selectedMonth.year + ' ' + this.selectedHour.hour
      // console.log()
      const newAppointment = {
        anulada: 0,
        asistencia: 0,
        clientId: this.resumeAppoinment.company.id,
        date: moment(initDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm'),
        medicalCenterAddress: this.resumeAppoinment.medicalCenter.location,
        medicalCenterId: this.resumeAppoinment.medicalCenter.id,
        medicalCenterName: this.resumeAppoinment.medicalCenter.name,
        medicalCenterInfo: {
          longitude: this.resumeAppoinment.medicalCenter.lng,
          latitude: this.resumeAppoinment.medicalCenter.lat
        },
        phone: this.resumeAppoinment.medicalCenter.phone,
        reasons: appointment.reasons,
        types: appointment.types
      }
      _modules.addNewAppointment(newAppointment)
      // window.location.reload()
    }
  },
  watch: {
    getProvincesStatus: {
      handler (newVal) {
        if (newVal === 'success') {
          this.provinces = this.getProvinces
          this.initialProvinces()
        }
      },
      deep: true
    },
    getLocale: {
      handler (newVal, oldVal) {
        console.log(newVal, oldVal)
        this.setAvailableMonths()
        // this.selectedMonth.label = moment().locale(this.getLocale).month(this.selectedMonth.number).format('MMMM')
        this.selectedMonth = { number: moment().month(this.selectedMonth.number).month(), label: moment().locale(this.getLocale).month(this.selectedMonth.number).format('MMMM'), year: moment().month(this.selectedMonth.number).format('YYYY') }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">

.advice__absenteeism {
  max-height: 0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  &--open {
    min-height: 0px;
    max-height: 500px;
  }
}

</style>
