<template>
  <div class="dark-overlay tps-modal__container">
    <!-- <div :class="{'dark-overlay' : getIsTPSModalVisible}" @click="closeTPSModal"></div> -->
    <div class="tps-modal">
      <div class="tps-modal__header" style="display: flex; justify-content: space-between;">
        <span class="tps-modal__header__tag">{{ $t('TPSModal.tag')}}</span>
        <span class="tps-modal__header__close icon icon-24 icon-close" @click="closeModal()"></span>
      </div>
      <div class="tps-modal__body">
        <div class="tps-modal__content">
          <div class="tps-modal__content__logo-title">
            <img class="tps-modal__content__logo" src="@/assets/images/logo-vitaly.png" alt="logo">
            <span class="tps-modal__content__title" v-html="$t('TPSModal.title')"></span>
          </div>
          <p class="tps-modal__content__description">{{ $t('TPSModal.description')}}</p>
          <div class="tps-modal__content__features">
            <TPSFeature :title="$t('TPSModal.feature.title1')" :subtitle="$t('TPSModal.feature.subtitle1')" />
            <TPSFeature :title="$t('TPSModal.feature.title2')" :subtitle="$t('TPSModal.feature.subtitle2')" />
            <TPSFeature :title="$t('TPSModal.feature.title3')" :subtitle="$t('TPSModal.feature.subtitle3')"/>
            <TPSFeature :title="$t('TPSModal.feature.title4')" :subtitle="$t('TPSModal.feature.subtitle4')"/>
          </div>
          <div class="tps-modal__content__indicator"><span class="tps-modal__content__indicator__text" v-html="$t('TPSModal.indicator')"></span></div>
        </div>
        <div :class="{'tps-modal__mobile-image-container' : this.isMobile, 'tps-modal__image-container' : !this.isMobile}">
          <img v-if="getCurrentDevice > DEVICES.MOBILE" class="tps-modal__image-container__ecosystem" src="@/assets/images/tps-ecosystem.png">
          <img v-if="getCurrentDevice > DEVICES.MOBILE" class="tps-modal__image-container__app" src="@/assets/images/tps-app.png">
          <img v-if="getCurrentDevice === DEVICES.MOBILE" class="tps-modal__image-container__ecosystem" src="@/assets/images/tps-ecosystem-sm.png">
          <img v-if="getCurrentDevice === DEVICES.MOBILE" class="tps-modal__image-container__app" src="@/assets/images/tps-app-sm.png">
        </div>
      </div>
      <QRBanner @isMobile="checkIfMobile" class="tps-modal__qr-banner"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import TPSFeature from './TPSFeature.vue'
import QRBanner from './QRBanner.vue'
export default {
  components: {
    TPSFeature, QRBanner
  },
  name: 'TPSModal',
  props: [],
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      isMobile: false
    }
  },
  methods: {
    closeModal () {
      this.$store.commit('setTPSModalVisible', false)
    },
    checkIfMobile (data) {
      console.log('data', data)
      this.isMobile = data
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['getCurrentDevice'])
  }
}
</script>
