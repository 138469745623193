<template>
  <div
    v-if="showSimpleAdvice"
    :class="['simple-advice', bgColor]"
  >
      <span :class="['simple-advice__icon', 'icon', iconClass]"/>
      <span v-html="text" class="advice__text"/>
      <span v-if="showCloseIcon" class="simple-advice__close icon icon-close" @click="closeSimpleAdvice()"/>
  </div>
</template>
<script>
export default {
  name: 'Advice',
  components: {},
  props: {
    text: {
      type: String,
      required: true,
      default: 'hfh'
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    bgColor: {
      type: String,
      required: false,
      default: 'grey'
    },
    showCloseIcon: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      showSimpleAdvice: true
    }
  },
  methods: {
    closeSimpleAdvice () {
      this.showSimpleAdvice = false
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/scss/settings/variables';
@import 'src/assets/scss/settings/colors';

.simple-advice {
  display: flex;
  align-items: center;
  padding: 16px 16px;
  margin-bottom: 15px;
  &.grey { background-color: var(--black-5-color) !important; }
  &.yellow { background-color: var(--light-orange) !important; }
  &__icon {
    font-size: 55px;
    margin-right: 10px;
    color: var(--black-60-color);
  }
  &__close {
    align-self: flex-start;
    font-size: $font-size-icon-md;
    color: var(--black-60-color);
    cursor: pointer;
    &:hover {
      color: var(--primary-color);
    }
  }
}

</style>
