
export default {
  // WARNING:
  //   Direct access to state properties from the root state must use the module name and dot notation. IE: state.forecasts.detailedForecast
  //   Access through getters is preferred
  state: {
    isModalOpened: false,
    modalParams: {},
    modalComponent: ''
  },
  mutations: {
    openModal (state, params) {
      state.isModalOpened = true
      state.modalParams = params
      state.modalComponent = params.component
    },
    closeModal (state) {
      state.isModalOpened = false
      state.modalComponent = ''
    }
  },
  // Must start with 'get'
  getters: {
    getModalComponent (state) {
      return state.modalComponent
    },
    getModalParams (state) {
      return state.modalParams
    }
  },
  actions: {
  }
}
