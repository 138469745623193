<template>
  <div
    class="mobile-menu"
    :class="{'mobile-menu--visible' : isVisible}"
  >
    <div class="mobile-menu__list">
      <span
        class="mobile-menu__icon-close icon icon-close"
        @click="hideMobileMenu()"
      />
      <div
        v-for="(menuItem, index) in menu"
        :key="index"
        class="mobile-menu__item"
      >
        <div
          class="mobile-menu__item__button mobile-menu__item__button--section"
          :class="{'mobile-menu__item__button--selected' : menuItem.selected, 'mobile-menu__item__button--opened' : menuItem.opened && menuItem.selected}"
          @click="setMenuItem(menuItem)"
        >
          <div class="flex align-items-center justify-space-between width-100-percent">
            <div class="flex align-items-center">
              <span class="mobile-menu__item__button__label">{{ getItemLabel(menuItem) }}</span>
              <span
                v-if="menuItem.iconMobile"
                class="mobile-menu__item__button__icon icon margin-left-10"
                :class=" menuItem.iconMobile"
              />
            </div>
            <button
              v-if="menuItem.buttonMobile"
              class="button mobile-menu__button"
            >
              <span
                class="button__icon icon icon-phone-line"
                style="color: #fff"
              />
              <span class="button__label">{{ menuItem.buttonMobile }}</span>
            </button>
          </div>
          <div
            v-if="menuItem.items && menuItem.items.length > 1"
            class="mobile-menu__item__arrow icon"
            :class="menuItem.opened ? 'icon-arrow-top' : 'icon-arrow-down'"
          />
        </div>
        <div
          v-if="menuItem.opened"
          class="mobile-menu__submenu"
          :class="{'mobile-menu__submenu--hidden' : !menuItem.opened}"
        >
          <div
            v-for="(submenu, index) in menuItem.items"
            :key="index"
            class="mobile-menu__item__button"
            :class="{'mobile-menu__item__button--selected' : submenu.selected, 'mobile-menu__item__button--disabled' : submenu.disabled}"
            @click="selectMenu(menuItem, submenu)"
          >
            <div class="mobile-menu__item__button__label">
              {{ submenu.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-menu__item">
        <div class="mobile-menu__item__button mobile-menu__item__button--section">
          <span>{{ $t('welcomeLanguageSelector.language') }}</span>
          <WelcomeLanguageSelector
            :inAvatarMenu="false"
          />
        </div>
      </div>
      <div class="mobile-menu__item">
        <div
          class="mobile-menu__item__button mobile-menu__item__button--section"
          @click="logOut()"
        >
          <span class="mobile-menu__item__button__label">{{ $t('welcomeLanguageSelector.closeSession') }}</span>
        </div>
      </div>
    </div>
    <div
      class="mobile-menu__backdrop"
      @click="hideMobileMenu()"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import WelcomeLanguageSelector from '@/components/welcome/WelcomeLanguageSelector'
export default {
  name: 'MobileMenu',
  components: { WelcomeLanguageSelector },
  props: ['isVisible'],
  data () {
    return {
      DEVICES: Vue.CONSTANTS.DEVICES,
      menu: []
    }
  },
  computed: {
    ...mapGetters(['getMenu', 'getCurrentDevice', 'getParamSection'])
  },
  mounted () {
    this.menu = [...this.getMenu]
    // console.log()
  },
  methods: {
    logOut: function () {
      this.$store.commit('setCurrentUser', null)
      this.$router.push('/login')
    },
    getItemLabel: function (menuItem) {
      if (this.getCurrentDevice === this.DEVICES.MOBILE && menuItem.labelMobile) {
        return menuItem.labelMobile
      } else {
        return menuItem.label
      }
    },
    hideMobileMenu: function () {
      this.$store.commit('setIsMobileMenuVisible', false)
    },
    setMenuItem: function (menuItem) {
      if (menuItem.phoneNumber) {
        window.location = 'tel:' + menuItem.phoneNumber
      } else {
        if (menuItem.items.length > 0) {
          menuItem.opened = !menuItem.opened
          this.menu.forEach(element => {
            if (element !== menuItem) {
              element.opened = false
            }
          })
        } else {
          if (menuItem.path === '/faq') {
            this.$store.commit('setFAQSModalVisible', true)
            this.$store.commit('setIsMobileMenuVisible', false)
          } else if (menuItem.path === '/blog') {
            window.open('https://www.preving.com/blog/', '_blank')
          } else {
            this.$router.push({ path: menuItem.path }).catch((error) => { console.log(error) })
            this.$store.commit('setIsMobileMenuVisible', false)
          }
        }
      }
      // if (menuItem.path !== '/blog') this.$store.commit('setMenu', this.menu)
    },
    selectMenu: function (menuItem, submenu) {
      // // console.log()
      // // console.log()
      if (submenu.disabled) return
      console.log(menuItem)
      console.log(submenu)
      this.$store.commit('setParamSection', submenu)
      console.log(this.getParamSection)
      this.menu = [...this.getMenu]
      if (submenu.disabled) return
      this.menu.forEach(e => {
        if (e.path === menuItem.path) {
          e.selected = true
        } else {
          e.selected = false
        }
        e.items.forEach(element => {
          console.log(element.component)
          console.log(submenu.component)
          if (element.component === submenu.component) {
            element.selected = true
          } else {
            element.selected = false
          }
        })
      })
      console.log(menuItem)
      menuItem.items.forEach(item => {
        if (item.component === submenu.component) item.selected = true
      })
      this.$store.commit('setMenu', this.menu)
      // test
      this.$store.commit('setIsMobileMenuVisible', false)
      this.$router.push({ path: menuItem.path }).catch(() => {
        // console.log(error)
        // console.log(menuItem)
        // console.log(this.menu)
      })
    }
  }
}
</script>
