import moment from 'moment'
// import kpiService from '@/services/kpiService.js'

function initialState () {
  return {
    urineGlucose: [],
    isLoading: false
  }
}

export default {
  state: {
    fakeUrineGlucose:
      [
        {
          rmId: 123,
          workerId: 456,
          date: '2020-03-09T10:54:30.393190',
          year: '2020',
          value: 1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2021-05-09T10:54:30.393190',
          year: '2021',
          value: 0
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2022-03-09T10:54:30.393190',
          year: '2022',
          value: 1
        },
        {
          rmId: 123,
          workerId: 456,
          date: '2023-05-09T10:54:30.393190',
          year: '2023',
          value: 0
        }
      ],
    urineGlucose: [],
    isLoading: false
  },
  mutations: {
    setKpi (state, kpi, dataset) { // arreglar para que coja el kpi que se le pide
      state.kpi.data = dataset
    },
    // loadData: (state, payload) => (state.urineGlucose = payload)
    loadDataUrineGlucose (state, payload) {
      // console.log('loadData', payload)
      state.urineGlucose = payload
    },
    resetState (state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  getters: {
    lastDateUrineGlucose (state) { // fecha del último dato con valor
      const itemsWithValue = state.urineGlucose.filter(el => el.value != null)
      // console.log('itemsWithValue', itemsWithValue)
      const datesArray = itemsWithValue.map(function (el) {
        return el.date
      })
      // console.log('datesArrayFiltered', datesArray)
      const lastItem = datesArray.pop()
      return moment(lastItem).format('LL')
    },
    outstandingDataUrineGlucose (state) { // valor del último dato recogido
      const itemsWithValue = state.urineGlucose.filter(el => el.value != null)
      const valuesArray = itemsWithValue.map(function (el) {
        return el.value
      })
      return valuesArray.pop()
    },
    filteredDataUrineGlucose (state) { // solo datos
      const objectValues = state.urineGlucose.map(function (el) {
        return el.value
      })
      // console.log('filteredDataUrineGlucose', objectValues)
      return objectValues
    },
    filteredXlabelsUrineGlucose (state) { // solo label del eje Y
      const objectKeys = state.urineGlucose.map(function (el) {
        return el.year
      })
      return objectKeys
    }
  },
  actions: {
    fetchUrineGlucose ({ commit, state }) { // (commit, state, kpi)
      // console.log('FETCH DESDE STORE urineGlucose')
      // commit('loadDataUrineGlucose', state.fakeUrineGlucose) // dato de prueba
      if (state.urineGlucose.length === 0) {
        state.isLoading = true
        commit('loadDataUrineGlucose', state.fakeUrineGlucose) // datos de prueba
        state.isLoading = false
        /*
        kpiService.getUrineGlucose()
          .then((response) => {
            // console.log('DATA urineGlucose', response.data)
            // commit('loadDataUrineGlucose', response.data)
          })
          .catch((error) => {
            console.log('ERROR', error)
          })
          .finally(() => {
            // console.log('finally 2')
            state.isLoading = false
          })
          */
      }
    }
  }
}
