<template>
    <div class="skeleton-card">
      <div :class="['skeleton-card__block', size === 'small' ? 'small' : '']"></div>
      <div class="skeleton-card__block"></div>
    </div>
</template>
<script>
export default {
  name: 'SkeletonCard',
  props: {
    size: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
