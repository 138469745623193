<template>
  <div class="tps-feature">
    <span class="tps-feature__rotated-icon icon icon-subtract"></span>
    <div class="tps-feature__content">
      <span class="tps-feature__title" v-html="title"></span>
      <span class="tps-feature__subtitle" v-html="subtitle"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TPSFeature',
  props: ['title', 'subtitle']
}
</script>
