<template>
    <div class="card-filter__list">
      <div class="card-filter__item" :class="{'card-filter__item--selected': item.selected}" v-for="(item, index) in data" :key="index" @click="click(item)">
          <span class="card-filter__text card-filter__text--bold">{{ item.clientName }}</span>
      </div>
    </div>
</template>
<script>
export default {
  name: 'CardFilter',
  components: {},
  props: ['data'],
  data () {
    return {
      items: [
        { id: 1, selected: true },
        { id: 2, selected: false }
      ]
    }
  },
  mounted () {
    this.menu = [...this.getMenu]
  },
  computed: {
  },
  methods: {
    click (item) {
      const index = this.data.indexOf(item)
      if (index > -1) {
        if (this.data[index].selected === true) return
        this.data.forEach(item => {
          item.selected = false
        })
        this.data[index].selected = true
      }
    }
  }
}
</script>
<style scoped>
</style>
